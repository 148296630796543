class galleryHelper{
	constructor() {
		
	}
	
	scrollHorizontal(el, right) {
		$(el).animate({
			'scrollLeft': right ? '-100px' : '100px',
		},100);
	}
	
	scroll(el) {
		var $el = $(el);
		var left = $( $el.find('[data-scroll="left"]')[0] );
		var right = $( $el.find('[data-scroll="right"]')[0] );
		var gallery = $( $el.find('.gallery_images')[0] );

		// DEVNOTE: add default single click functionality if no click and hold event occurs

		var galleryTimeout = 0;
		// Left and Right click binding
		left.click(function(){
			leftScroll();
		});
		right.click(function(){
			rightScroll();
		});

		// Left and Right Scroll Functionality
		function leftScroll(e){
			pos = gallery.scrollLeft();
			gallery.animate({scrollLeft: (pos + 500) + 'px'}, 350, 'swing');
		}
		function rightScroll(e){
			pos = gallery.scrollLeft();
			gallery.animate({scrollLeft: (pos - 500) + 'px'}, 350, 'swing');
		}
	}

	toggle(el) {
		var tar = $(el).data('target'), nav_items = $(el).find('a');
		nav_items.each(function(){
			$(this).click(function(e){
				e.preventDefault();
				var src = $(this).data('src');
				nav_items.removeClass('active');
				$(this).addClass('active');
				$(tar).attr('src', src);
			});
		});
		// init tooltips for use in nav
		$('[data-toggle="tooltip"]').tooltip();
	}
}
window.app.add('gallery', new galleryHelper());