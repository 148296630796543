class registerHelper {

    constructor() {
    }

    addDisplayDeliveryMsgEvent() {
        const selectInput = document.getElementById('state');
        const statesWithDelivery = ['KY', 'OH', 'IN'];
        const alert = document.getElementById('stateDeliveryMsg');
        selectInput.addEventListener('change', (event) => {
            if (!statesWithDelivery.includes(event.target.value)) {
                alert.classList.remove('d-none');
                alert.classList.add('d-block');
            } else {
                alert.classList.add('d-none');
                alert.classList.remove('d-block');
            }
        })

    }

}

window.app.add('register', new registerHelper());