<template>
    <div class="filter-wrapper">
        <div class="filter-section">
            <div class="filter-item"
                v-for="filter in filters"
                v-bind:key="filter.title"
            >
                <button class="btn btn-filter align-self-stretch" 
                    v-bind:class="{ 'selected': filter.selected }"
                    v-on:click="filter.selected=!filter.selected;clearOtherFilters(filter.title);"
                >
                    <div class="icon">
                        <img v-bind:src="'/assets/images/filters/' + filter.title + '-icon.svg'" :alt="filter.title" />
                    </div>
                    <span>{{ filter.title }}</span>
                </button>
            </div>
            <div class="filter-item">
                <button class="btn btn-filter align-self-stretch"
                    v-bind:class="{ 'selected': !selectedFilters.length }"
                    v-on:click="clearFilters()"
                >
                    <div class="icon">
                        <img src="/assets/images/filters/all-icon.svg" alt="all" />
                    </div>
                    <span>All</span>
                </button>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .filter-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;

        @media screen and (max-width: 600px) {
            display: block;
        }

        .filter-item {
            flex: 0 0 0;
            align-self: stretch;

            display: flex;
            align-items: center;
            justify-content: center;

            &+.filter-item {
                margin-left: 1rem;
            }

            @media screen and (max-width: 600px) {
                width: 100%;
                max-width: unset;
                display: block;
                background: white;

                button.btn{
                    width: 100%;
                    max-width: unset;
                    display: block;
                    background: white;

                    .icon{
                        display: none;
                    }

                    span{
                        font-size: 16px;
                    }
                }
                &+.filter-item {
                    margin-left: 0;
                }
            }
        }
    }
    button.btn {
        &.btn-filter {
            align-self: stretch;

            width: 100%;
            max-width: 6rem;

            color: #000;
            font-weight: normal;
            font-size: 14px;

            padding: .5rem .5rem;

            border-radius: .5rem;

            // border: 2px solid #fff;

            &.selected {
                box-shadow: none;
                font-weight: 600;
                
                .icon{
                    border-color: #128851;
                }
            }

            .icon {
                border: 2px solid #fff;
                background: #F5F5F5;
                padding: .5rem;
                border-radius: .5rem;


                width: 4rem;
                height: 4rem;

                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    max-width: 80%;
                    max-height: 80%;
                    width: auto;
                    height: auto;
                }
            }
            span {
                text-align: center;
                text-transform: capitalize;
                display: block;
                line-height: 1;
                margin-top: .25rem; 
            }
        }
    }
</style>
<script>
const FILTERS = ['tree', 'shrub', 'rose', 'perennial', 'vine', 'grass', 'annual', 'edible', 'supplies', 'favorites'];
export default {
    data() {
        return {
            filters: [
                /**@example*/
                /*{ 
                    title: '' 
                    selected: false
                }*/
            ]
        }
    },
    computed: {
        selectedFilters() {
            return this.filters
                .filter(item => item.selected === true)
                .map(item => item.title);
        }
    },
    methods: {
        /**
         * Deselect all filters and emit change event
         * 
         * @author LWK<lkrehnbrink@usdigitalpartners.com>
         */
        clearFilters() {
            this.filters.forEach(filter => filter.selected = false);
            this.onFilterChange();
        },
        /**
         * Emit our selected filters every time a 
         * filter is clicked.
         * 
         * @author LWK<lkrehnbrink@usdigitalpartners.com>
         */
        onFilterChange() {
            this.$root.$emit('clearSearchInput');
            this.$emit('filtersChanged', this.selectedFilters);
        },
        /**
         * Clear all the other filters except one passed to function
         */
        clearOtherFilters(clickedFilter){
            this.filters.forEach(function(filter){
                if(filter.title != clickedFilter){
                    filter.selected = false;
                }
            });
            this.onFilterChange();
        },
        /**
         * Need to simply clear filters without showing all products
         */
        clearAllFilters() {
            // console.log('clearFilters');
            this.filters.forEach(filter => filter.selected = false);
        },
        /**
         * Initiate a filter
         */
        initFilter(newfilter) {
            this.filters.forEach(function(filter){
                if(filter.title == newfilter){
                    filter.selected = true;
                }
            });
            this.onFilterChange();
        },
    },
    created() {
        /**
         * Setup our filters
         * 
         * @devnote option to pass in cofigurable filter set via js
         * settings file.
         * @see `/resources/views/layouts/partials/js-settings.blade.php`
         */
        const useFilters = window._NATORPS.wholesale.filters || FILTERS;
        useFilters.forEach(
            key =>
            this.filters.push({
                title: key,
                selected: false
            })
        )
    },
    mounted() {
        this.$root.$on('clearAllFilters', () => {
            this.clearAllFilters()
        });
        this.$root.$on('initFilter', (newfilter) => {
            this.initFilter(newfilter);
        });
    }
}
</script>