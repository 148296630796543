<template>
    <div class="border-bottom mb-1">
        <div class="form-wrapper">
            <p class="lead mb-0" style="font-weight:bold">Pay with a Gift Card</p>
            <div class="form-group">
                <label style="font-weight:normal" for="gift_card_entered">Have a gift card? Enter the number below to apply it to your order.</label>
                <div class="input-group">
                    <input name="gift_card_entered" class="form-control" placeholder="Enter your gift card number here" v-model="enteredGiftCard">
                    <div class="input-group-append">
                        <button class="btn btn-success btn-sm" type="button" :disabled="disableButton" @click.prevent="validateGiftCard">Apply</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="alert alert-success mb-1 text-left" v-if="validating">
            <p class="alert-text mb-0">Validating your gift card...</p>
        </div>
        <div class="alert alert-success mb-1 text-left" v-if="didApplyGiftCard">
            <p class="alert-text mb-0"><strong>Success!</strong> Gift card has been applied to your order.</p>
        </div>
        <div class="alert alert-danger mb-1 text-left" v-if="invalid">
            <p class="alert-text mb-0">
                <strong>Ooops!</strong> 
                <span v-if="numberNotValid">Looks like this is not a valid card number.</span>
                <span v-if="noBalanceAvaialable">Looks like this card no longer has any balance remaining.</span>
            </p>
        </div>
        <input name="gift_cards" type="hidden" :value="appliedGiftCardNumbers">
    </div>
</template>

<script>
import * as axios from 'axios';
export default {
    props: ['cart', 'baseTotal'],
    data() {
        return {
            mounted: false,
            enteredGiftCard: '',
            appliedGiftCards: [],
            appliedGiftCardNumbers: '',
            validating: false,
            invalid: false,
            didApplyGiftCard: null,
            numberNotValid: null,
            noBalanceAvaialable: null
        };
    },
    computed: {
        disableButton() {
            if (!this.enteredGiftCard.length || this.validating) {
                return true;
            }
            return null;
        },
    },
    watch: {
        /**
         * VALIDATE gift cards when cart total changes (multiple cards may not be necessary)
         * 
         */
        baseTotal(nv, ov) {
            if (this.appliedGiftCards) {
                // recalculate card amount values
                this._applyGiftCards();
            }
        },
        /**
         * EMIT appliedGiftCards when local changes
         * 
         */
        appliedGiftCards(nv, ov) {
            this.$emit('applied-gift-cards', this.appliedGiftCards);
        }
    },
    methods: {
        // Example giftCard object
        /*{
            "Number": "1574679863",
            "InvoiceID": "amount",
            "DateSold": null,
            "Name": "John Smith",
            "OriginalAmount": 50,
            "Remaining": 10,
            "LastEditDate": null,
        }*/
        // Handle Code submission from input, prevent entry during
        async validateGiftCard() {
            this.$set(this, 'validating', true);
            const response = await this._checkGiftCardExists(this.enteredGiftCard);
            const giftCard = response.data;
            if (giftCard) {
                // Validate Gift Card and against Cart// FIX string values
                const numericProps = ['Remaining'];
                numericProps.forEach(prop => {
                    if (giftCard[prop]) {
                        giftCard[prop] = parseFloat(giftCard[prop]);
                    }
                });

                // CHECK card balance
                if(giftCard.Remaining <= 0) {
                    this.$set(this, 'noBalanceAvaialable', true);
                    this._handleInvalidGiftCard();
                }

                // add card to applied set
                if(!this.appliedGiftCards.some(gc => gc.Number == giftCard.Number)) {
                    this.appliedGiftCards.push(giftCard);
                }

                // SET Valid State
                this.$set(this, 'invalid', false);
                this.$set(this, 'didApplyGiftCard', true);
                this.$set(this, 'numberNotValid', null);
                this.$set(this, 'noBalanceAvaialable', null);

                // apply gift cards to order and calculate amount values
                this._applyGiftCards();
            } else {
                // Unset everything on failure
                this.$set(this, 'numberNotValid', true);
                this._handleInvalidGiftCard();
            }
            this.$set(this, 'validating', false);
        },

        // Call API and check for existence
        _checkGiftCardExists(number) {
            return axios.post('/api/v1/retail/validate-gift-card', { Number: number });
        },

        // Apply cards and calculate amounts used
        _applyGiftCards() {
            // sort cards in ascenting order of amount remaining (smaller cards will get used first)
            this.appliedGiftCards.sort((a,b) => a.Remaining - b.Remaining);

            // Calculate amount used on each card
            var postCardTotal = parseFloat(this.baseTotal);
            //get applied value for each card
            for(var i = 0; i < this.appliedGiftCards.length; i++) {
                var appliedValue = this.appliedGiftCards[i].Remaining > postCardTotal ? postCardTotal : this.appliedGiftCards[i].Remaining;
                if(appliedValue <= 0) {
                    // card no longer needed, remove from applied cards
                    this.appliedGiftCards.splice(i, 1);
                    i--;
                } else {
                    this.appliedGiftCards[i].appliedValue = appliedValue.toFixed(2);
                    this.$set(this.appliedGiftCards, i, this.appliedGiftCards[i]);
                    postCardTotal -= appliedValue;
                }
            }
            
            // update hidden input that gets posted to checkout controller
            this.appliedGiftCardNumbers = this.appliedGiftCards.map(gc => gc.Number).join(',');
        },

        // Set invalid state
        _handleInvalidGiftCard() {
            this.$set(this, 'invalid', true);
            this.$set(this, 'didApplyGiftCard', false);
        }
    }
}
</script>

<style lang="scss">
.input-group {
    .btn {
        padding: .375rem 1.25rem;
        line-height: inherit;
        font-size: inherit;
    }
}
</style>