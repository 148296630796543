class styleHelper{
	constructor() {
	}

	fullHeight(el) {
		//Note: Fixed elements can affect the perceived window.innerHeight
		var nh = window.innerHeight;
		var nav = $('#main-nav').outerHeight();
		var elh = nh - nav;
		$(el).css('height', elh+'px');
	}
	
	equalHeight(el) {
		var b_tar = $(el).attr('data-target');
		var limit = $(el).attr('data-limit');
		if( limit !== null && window.innerWidth < limit) {
			return;
		}
		var tar = [];
		if(b_tar.indexOf(', ') > -1) {
			tar = b_tar.split(', ');
		} else {
			tar.push(b_tar);
		}
		tar.forEach(function(item){
			var kids = $(el).find(item);
			var minH = 0;
			kids.each(function(){ 
				$(this).css({ 
					'min-height': 0,
					'transition': 'all 0s'
				}); 
			});
			kids.each(function(){
				minH = ( minH < $(this).outerHeight() ) ? $(this).outerHeight() : minH;
			});
			kids.each(function(){
				$(this).css('min-height', minH+'px');
			});
		});
	}
}
window.app.add('style', new styleHelper());