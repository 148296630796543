<template>
    <div>
        <a @click.stop.prevent="showDetail()" class="title text-dark">{{ productName }}</a>
        <a @click.stop.prevent="showDetail()" class="fa fa-external-link green"></a>
        <a class="fa red" 
            :class="{ 'fa-heart-o': !favorited, 'fa-heart': favorited }"
            @click.stop.prevent="toggleFavorite()">
        </a>
        <img v-if="isOnSale" src="/assets/images/sale_icon.svg" alt="On Sale" title="On Sale">
        <!-- <span v-if="isOnSale" class="text-danger"> <i class="fa fa-exclamation"></i> On Sale</span> -->
    </div>
</template>
<style lang="scss" scoped>
    span, a {
        display: inline-block;
    }
    a {
        margin-left: .5rem;
        cursor: pointer;
    }
    span{
        margin-left: .5rem;
    }
    img{
        margin-left: .5rem;
        vertical-align: sub;
    }
    .title {
        text-decoration: underline;
        margin-left: 0;
    }
    .green {
        color: #128851 !important;
    }
    .red {
        color: #FF3434 !important;
    }
</style>
<script>
import { WholesaleApiService, API_BASE_URL } from '../services/wholesale-api.service';
import { STORE_ACTIONS } from '../store';
Vue.prototype.api = WholesaleApiService;
export default {
    data() {
        return {
            favorited: false,
        }
    },
    methods: {
        showDetail() {
            this.$store
                .dispatch(STORE_ACTIONS.SHOW_PLANT_DETAIL_MODAL, this.plantId);
        },
        toggleFavorite() {
            this.favorited = !this.favorited;
            if (this.favorited) {
                // add
                this.api
                    .addFavoriteForAuthenticatedUser(this.plantId)
                    .then(
                        (favorite) =>
                        this.$store
                            .dispatch(STORE_ACTIONS.FAVORITES_ADD, favorite)
                    );
            } else {
                // delete
                this.api
                    .removeFavoriteForAuthenticatedUser(this.plantId)
                    .then(
                        (removed) =>
                        this.$store
                            .dispatch(STORE_ACTIONS.FAVORITES_REMOVE, this.plantId)
                    );
            }
        }
    },
    props: ['productName', 'productId', 'plantId', 'isOnSale'],
    mounted() {
        // Set Favorite State on Load and then watch for change from modal update
        this.favorited = this.$store.getters.favoriteItemsIds.indexOf(this.plantId) > -1;
        this.$store
            .watch(
                (state, getters) => state.favorites,
                (favorites) => {
                    this.favorited = this.$store.getters.favoriteItemsIds.indexOf(this.plantId) > -1;
                }
            );
    }
}
</script>