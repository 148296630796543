<template>
    <div class="cart-contents" v-if="!user_is_verified">
        <div class="cart-items-wrapper p-1">
            <p>You need to verify your email address before shopping. Follow the link below to request a new verification link.</p>
            <a href="/email/verify" class="btn btn-primary btn-block">Verify your Email</a>
        </div>
    </div>
    <div class="cart-contents" v-else>
        <div class="cart-items-wrapper">
            <cart-item-component v-for="(item, index) in cart" :item="item" :index="index" :key="item.id" v-on="$listeners"></cart-item-component>
            <div class="cart-empty" v-on="$listeners">
                <cart-empty-component :empty_text="empty_text" :categories_text="categories_text" v-if="cart_count == 0" v-on="$listeners"></cart-empty-component>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
</style>
<script>
export default {
    props: {
        cart: {
            type: Array,
            default: []
        },
        user_is_verified: false,
        empty_text: '',
        categories_text: '',
        cart_count: 0,
    },
    data() {
        return {}
    },
    computed: {},
    methods: {},
}
</script>
