import { store } from "../store";

class shoppingListHelper {
	constructor() {
    }
    
    init(config) {
        var token = document.head.querySelector('meta[name="csrf-token"]');
        window.app.shoppingListInstance = window.app.shoppingList.bindShoppingListVue();
        // console.log('Shopping List Bound');
        // console.log('Cart Instance', window.app.cartInstance);
    }

    showStatus(message) {
        var statusEl = document.getElementById('cart-status'),
            statusText = document.getElementById('cart-status-text');
        statusText.innerHTML = message;
        statusEl.classList.add('in','animated','fadeIn');
        var timeOut = setTimeout(function() {
            statusEl.classList.remove('animated','fadeIn');
            statusEl.classList.add('animated', 'fadeOut');
            var timeEnd = setTimeout(function() {
                statusEl.classList.remove('in','animated','fadeOut');
                clearTimeout(timeEnd);
            }, 1000);
            clearTimeout(timeOut);
        }, 3500);
    }

    bindShoppingListVue() {
        return new Vue({
            store: store,
            delimiters: ['${', '}'],
            el: '#shopping-list',
            data: {
                shown: false,
                ready: false,
                updating: false,
                shoppingList: [],
                confirmRemove: false,
                events: {
                    cartUpdateEvent: new Event('cartUpdated')
                }
            },
            computed: { },
            methods: {    
                finishUpdate() {
                    var self = this,
                    timeOut = setTimeout(function(){
                        self.updating = false;
                        clearTimeout(timeOut);
                    }, 500);
                },
            },
            created() {
                // console.log('Shopping List Vue created');
                var self = this;
                $.ajax({
                    method: 'GET',
                    url: '/shoppingList',
                    success(data) {
                        self.shoppingList = data.shoppingList;
                        self.ready = true;
                        // console.log(data);
                    },
                    error(err) {
                        // console.log(err);
                    }
                });
            },
            mounted() {
                // console.log('Shopping List Vue mounted');
            }
        });
    }
}
window.app.add('shoppingList', new shoppingListHelper());