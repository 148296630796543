<template>
    <section>
        <div id="cart-overlay" 
            v-if="showCart" 
            v-on:click="showCart=false">
        </div>
        <div class="cart-wrapper">
            <div class="cart-contents container pt-2" v-if="showCart">
                <order-table-cart-component :cartItems="cart"></order-table-cart-component>
            </div>
            <div class="cart-overview py-1">
                <div class="container">
                    <div class="toggle-wrapper d-block d-md-none text-center">
                        <a href="#" class="toggle-link text-dark" v-on:click.stop.prevent="showCart=!showCart">
                            View Order Details <span class="fa ml-1" :class="{ 'fa-plus': !showCart, 'fa-minus': showCart }"></span>
                        </a>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="toggle-wrapper d-none d-md-block">
                            <a href="#" class="toggle-link text-dark" v-on:click.stop.prevent="showCart=!showCart">
                                View Order Details <span class="fa ml-1" :class="{ 'fa-plus': !showCart, 'fa-minus': showCart }"></span>
                            </a>
                        </div>
                        <div class="disclaimer d-none d-md-block">
                            <!-- <a href="/wholesale/disclaimer" target="_blank" class="disclaimer-link text-success text-decoration-underline">*Plant Availability Disclaimer</a> -->
                        </div>
                        <div class="actions d-block d-md-flex align-items-center">
                            <div class="order-items mr-1  d-none d-md-block">
                                <span class="item-count">{{ cartItemsCount }} Items</span> / <strong class="item-total">{{ cartItemsTotal|currency }}</strong>
                            </div>
                            <div class="d-flex d-md-none w-100 align-items-center justify-content-between">
                                <span class="item-count font-weight-bold">{{ cartItemsCount }} Items</span>
                                <span class="item-total font-weight-bold">{{ cartItemsTotal|currency }}</span>
                            </div>
                            <a href="/checkout" 
                                class="btn btn-success px-3 d-block d-md-inline-block" 
                                :disabled="cart.length ? null : true">Place Order</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style lang="scss" scoped>
    #cart-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100%;
        background: rgba(#000, .2);
        z-index: 3;

        animation: fadeIn .3s ease-in-out;

        @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
        }
    }
    .cart-wrapper {
        background: #fff;
        position: relative;
        z-index: 4;
    }
    .cart-contents {
        max-height: 50vh;
        // overflow: scroll;

        th {
            position: relative !important;
            top: auto !important;
        }
    }
    .cart-overview {
        font-size: 16px;
        background: #fff;
        z-index: 2;

        .actions{
            width: auto; 
            
            @media screen and (max-width: 600px) {
                width: 100%;
            }
        }
    }
    .btn.btn-success {
        background: #128851;
        &:hover {
            background: darken(#128851, 5%);
        }
    }
</style>
<script>
export default {
    data() {
        return {
            showCart: false,
        }
    },
    computed: {
        cart() {
            return this.$store.state.cart;
        },
        cartItemsCount() {
            return this.$store.getters.cartItemsCount;
        },
        cartItemsTotal() {
            return this.$store.getters.cartItemsTotal;
        }
    },
}
</script>