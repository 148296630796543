<template>
    <div class="form-group mb-0">
        <label for="search-input" class="sr-only">Search Something</label>
        <span class="input-addon fa fa-search"></span>
        <input class="form-control" 
            type="text" 
            name="search-input"
            placeholder="Search inventory and check availability..." 
            v-model="searchTerm"/>
    </div>
</template>
<style lang="scss" scoped>
    .form-group {
        font-size: 16px !important;
        position: relative;
    }
    .form-control {
        padding-left: 2em;
        font-size: 16px;
        height: calc(2.25em + 2px);
    }
    .input-addon {
        position: absolute;
        left: .75em;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        color: #128851;
    }
</style>
<script>
import * as _ from 'lodash';
export default {
    data() {
        return {
            searchTerm: ''
        }
    },
    watch: {
        searchTerm: function(newTerm, oldTerm) {
            this.onSearchInputChange();
        }
    },
    methods: {
        /**
         * Emit Input Value to Parent when user
         * enters a search term.
         * 
         */
        onSearchInputChange: _.debounce(function() {
            // Always emit value...
            // if (this.searchTerm.length === 0) {
            //     return;
            // }
            this.$root.$emit('clearAllFilters');
            this.$emit('searchInputChange', this.searchTerm);
        }, 500),
        clearSearchInput: function(){
            this.searchTerm = '';
        }
    }, 
    mounted() {
        this.$root.$on('clearSearchInput', () => {
            // your code goes here
            this.clearSearchInput()
        });
    },
    created(){
        /**
         * Initialize a filter on page load
         */
        const urlParams = new URLSearchParams(window.location.search);
        const $q = urlParams.get('q');
        let $this = this;
        setTimeout(function(){
            $this.$root.$emit('initFilter', $q);
        }, 200);
    }
}
</script>