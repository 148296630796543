<template>
    <li class="nav-item cart-nav" id="cart-toggle">
        <a href="#" class="nav-link" @click.prevent="showCart()"><i class="fa fa-shopping-cart" data-bind="cart-count"><span class="cart-count">{{ cartCount }}</span></i> <div>Cart <span class="cart-items"></span></div></a>
        <div class="status" id="cart-status">
            <div class="status-text" role="alert"><div id="cart-status-text">1 item added to cart.</div></div>
        </div>
    </li>
</template>
<style lang="scss" scoped></style>
<script>
import { STORE_ACTIONS } from '../../store';

export default {
    props: {
    },
    data() {
        return {}
    },
    computed: {
        cartCount() {
            return this.$store.getters.cartCount;
        }
    },
    methods: {
        showCart() {
            var cart = document.getElementById('cart-slideout'),
			backdrop = document.getElementById('cart-backdrop');

            cart.classList.add('in', 'animated', 'slideInRight');
            backdrop.classList.add('in', 'animated', 'fadeIn');
            var timeOut = setTimeout(function() {
                cart.classList.remove('animated', 'slideInRight');
                backdrop.classList.remove('animated', 'fadeIn');
                window.app.cartInstance.$data.shown = true;
                clearTimeout(timeOut);
            }, 300);
        }
    },
}
</script>