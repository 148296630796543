import { render, staticRenderFns } from "./cart-contents.component.vue?vue&type=template&id=cbc84366&scoped=true&"
import script from "./cart-contents.component.vue?vue&type=script&lang=js&"
export * from "./cart-contents.component.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbc84366",
  null
  
)

export default component.exports