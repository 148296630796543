<template>
  <div class="px-1 border-bottom">
        <div class="form-wrapper">
            <p class="lead mb-0" style="font-weight:bold">Promo Code</p>
            <div class="form-group">
                <label style="font-weight:normal" for="promo-code">Have a promo code? Enter it below to apply the discount to your order.</label>
                <div class="input-group">
                    <input name="promo_code_entered" class="form-control" placeholder="Enter your promo code here" v-model="enteredPromoCode">
                    <div class="input-group-append">
                        <button class="btn btn-success btn-sm" type="button" :disabled="disableButton" @click.prevent="validatePromoCode">Apply</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="alert alert-success mb-1 text-left" v-if="validating">
            <p class="alert-text mb-0">Validating your promo code...</p>
        </div>
        <div class="alert alert-success mb-1 text-left" v-if="selectedPromoCode">
            <p class="alert-text mb-0"><strong>Success!</strong> Promo code <b>{{selectedPromoCode.code}}</b> has been applied to your order.</p>
        </div>
        <div class="alert alert-danger mb-1 text-left" v-if="invalid">
            <!-- @todo it would be nice to display a separate message when a cart condition isn't met -->
            <p class="alert-text mb-0">
                <strong>Ooops!</strong> 
                <span v-if="!missingRequiredProduct && !belowMinimumOrderPrice && !promoCodeHasBeenUsedByUser">Looks like we don't have any promo codes that match the one you entered.</span>
                <span v-if="belowMinimumOrderPrice">Looks like you need to spend <b>${{belowMinimumOrderPrice}}</b> or more to use this promo code.</span>
                <span v-if="missingRequiredProduct">Looks like you need to have <a class="text-danger" :href="missingRequiredProductLink">{{missingRequiredProduct.ProductName2}} ({{missingRequiredProduct.ProductSize}})</a> in your cart to use this promo code.</span>
                <span v-if="promoCodeHasBeenUsedByUser">Looks like you've already used this single-use promo code on a previous order.</span>
            </p>
        </div>
  </div>
</template>

<script>
import * as axios from 'axios';
import { PROMO_CODE_TYPES } from '../data/promo-code-types';
export default {
    props: ['cart'],
    data() {
        return {
            mounted: false,
            enteredPromoCode: '',
            selectedPromoCode: null,
            validating: false,
            invalid: false,
            missingRequiredProduct: null,
            belowMinimumOrderPrice: null,
            promoCodeHasBeenUsedByUser: null,
        };
    },
    computed: {
        disableButton() {
            if (!this.enteredPromoCode.length || this.validating) {
                return true;
            }
            return null;
        },
        /**
         * Link to Required Product
         * 
         */
        missingRequiredProductLink() {
            if (this.missingRequiredProduct) {
                return `/plants/${this.missingRequiredProduct.plant.PlantGroup}/${this.missingRequiredProduct.plant.PlantClassID}/`;
            }
            return '#';
        }
    },
    watch: {
        /**
         * VALIDATE selected promo code when cart changes
         * 
         */
        cart(nv, ov) {
            if (this.selectedPromoCode) {
                this._validatePromoCode(this.selectedPromoCode);
            }
        },
        /**
         * EMIT promoCode when local changes
         * 
         */
        selectedPromoCode(nv, ov) {
            this.$emit('selected-promo-code', this.selectedPromoCode);
        }
    },
    methods: {
        // Example promoCode object
        /*{
            "id": 1,
            "code": "10DollarsOffTotal",
            "type": "amount",
            "amount": 10,
            "percent": null,
            "order_minimum": null,
            "required_product_id": null,
            "required_product": {},
            "active": 1,
            "single_use": 0,
            "created_at": null,
            "updated_at": null
        }*/
        // Handle Code submission from input, prevent entry during
        async validatePromoCode() {
            this.$set(this, 'validating', true);
            const response = await this._checkPromoCodeExists(this.enteredPromoCode);
            const promoCode = response.data;
            if (promoCode) {
                // Check single use
                if (promoCode.single_use) {
                    const codeUsedPreviously = await this._authorizePromoCode(this.enteredPromoCode);
                    if (!codeUsedPreviously.data) {
                        // Check against cart
                        this._validatePromoCode(promoCode);
                    } else {
                        // Error code has been used
                        this.$set(this, 'promoCodeHasBeenUsedByUser', true);
                        this._handleInvalidPromoCode();
                    }
                } else {
                    // Check against cart
                    this._validatePromoCode(promoCode);
                }
            } else {
                // Unset everything on failure
                this._handleInvalidPromoCode();
            }
            this.$set(this, 'validating', false);
        },
        // Call API and check for existence
        _checkPromoCodeExists(code) {
            return axios.post('/api/v1/retail/validate-promo-code', { code: code });
        },
        // Call API and check if a code has been used
        _authorizePromoCode(code) {
            return axios.post('/api/v1/retail/authorize-promo-code', { code: code });
        },
        // Validate Promo Agasint Cart
        _validatePromoCode(promoCode) {
            // FIX string values
            const numericProps = ['amount', 'percent', 'order_minimum'];
            numericProps.forEach(prop => {
                if (promoCode[prop]) {
                    promoCode[prop] = parseFloat(promoCode[prop]);
                }
            });
            // CHECK against cart here
            // MINIMUM TOTAL
            if (promoCode.order_minimum) {
                const total = this.cart.reduce((total, current) => {
                    total += (current.price * current.quantity);
                    return total;
                }, 0);
                if (total < promoCode.order_minimum) {
                    // Alert here that a minimum total is required
                    this.$set(this, 'belowMinimumOrderPrice', parseFloat(promoCode.order_minimum).toFixed(2));
                    return this._handleInvalidPromoCode();
                }
            }
            // REQUIRED PRODUCT
            if (promoCode.required_product_id !== null) {
                // console.log(`Product required`, promoCode.required_product_id, this.cart);
                const product = this.cart.find(item => item.id == promoCode.required_product_id);
                if (!product) {
                    // Alert here that a product is required
                    this.$set(this, 'missingRequiredProduct', promoCode.required_product);
                    return this._handleInvalidPromoCode();
                }
            }
            // SET Valid State
            this.$set(this, 'invalid', false);
            this.$set(this, 'belowMinimumOrderPrice', null);
            this.$set(this, 'missingRequiredProduct', null);
            this.$set(this, 'promoCodeHasBeenUsedByUser', null);
            // SET Promo Code
            this.$set(this, 'selectedPromoCode', promoCode);
        },
        // Set invalid state
        _handleInvalidPromoCode() {
            this.$set(this, 'invalid', true);
            this.$set(this, 'selectedPromoCode', null);
        }
    }
}
</script>

<style lang="scss">
.input-group {
    .btn {
        padding: .375rem 1.25rem;
        line-height: inherit;
        font-size: inherit;
    }
}
</style>