// import 'es6-promise/auto';
import "@babel/polyfill";

import Vue from 'vue';
import { store, STORE_ACTIONS } from '../store';
import { API_BASE_URL, WholesaleApiService } from '../services/wholesale-api.service';

/**
 * The ID of the element we're going to bind the app to. 
 * You can find this in `resources/views/layouts/wholesale.blade.php`
 * 
 */
const WHOLESALE_SHOP_APP_ELEMENT_ID = 'wholesale-app';

/**
 * The Wholesale Vue App Instance
 * 
 * @Devnote this is the wrapper Vue instance that
 * bootstraps the store and will load any vue component
 * that shows up inside of it. The actual work is being
 * done at the component level.
 * 
 * @author LWK<lkrehnbrink@usdigitalpartners.com>
 */
export class WholesaleApp {
    
    constructor() {
        const wholesaleShopAppElement = document.getElementById(WHOLESALE_SHOP_APP_ELEMENT_ID);
        if (null === wholesaleShopAppElement) {
            // Bail if we don't have an element to mount to...
            // console.error("Can't find Wholesale Shop App Element.");
            return;
        }
        // Attach to the window namespace just in case...
        window.WholesaleApp = this.init(wholesaleShopAppElement);
    }

    init(element) { 
        return new Vue({
            store,
            el: element,
            data() {
                return {
    
                };
            },
            created() {
                // Load the cart data and update the store
                WholesaleApiService
                    .getCartForAuthenticatedUser()
                    .then(
                        cartResponse => 
                        this.$store
                            .dispatch(
                                STORE_ACTIONS.CART_LOADED,
                                cartResponse
                            )
                    );
                // Load Favorites for User
                WholesaleApiService
                    .getFavoritesForAuthenticatedUser()
                    .then(
                        favorites =>
                        this.$store
                            .dispatch(
                                STORE_ACTIONS.FAVORITES_LOADED,
                                favorites
                            )
                    );
                // Load User Information
                WholesaleApiService
                    .getAuthenticatedUserInformation()
                    .then(
                        userInformation =>
                        this.$store
                            .dispatch(
                                STORE_ACTIONS.USERINFORMATION_LOADED,
                                userInformation
                            )
                    );
            }
        });
    }
};