<template>
<div class="shopping-list" id="shopping-list">
    <div class="shopping-list-items">
        <div class="cart-items-wrapper">
            <wish-list-item-component v-for="(item, index) in wish_list" :item="item" :index="index" :key="item.id" v-on="$listeners"/>
            <cart-empty-component empty_text="Your Wish List is empty" categories_text="Find a plant" v-on="$listeners" v-if="wish_list.length == 0" />
        </div>
    </div>
    <wish-list-footer-component :wish_list="wish_list" :wish_list_count="wish_list_count" :wish_list_state="wish_list_state"
    :confirm_email_wish_list="confirm_email_wish_list" v-on="$listeners"></wish-list-footer-component>
</div>
</template>
<style lang="scss" scoped></style>
<script>
import { STORE_ACTIONS } from '../../store';

export default {
    props: {
        wish_list: [],
        wish_list_count: 0,
        wish_list_state: '',
        confirm_email_wish_list: false,
    },
    data() {
        return {}
    },
    computed: {
        
    },
    methods: {
        
    },
}
</script>
