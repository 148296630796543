<template>
  <div class="cart-checkout-item_component">
    <div class="cart-item_wrapper">
      <div class="cart-item_img_wrapper">
        <div
          class="cart-item_img"
          :style="{ backgroundImage: 'url(' + item.image_url + ')' }"
        ></div>
        <div class="cart-item_status plant-status">
          <div
            :class="
              'badge-inventory ' +
              (item.allow_preorder ? 'badge-allow-preorder' : 'badge-in-stock')
            "
          >
            <span>{{ item.allow_preorder ? "Pre-order" : "In Stock" }}</span>
          </div>
        </div>
        <div class="cart-item_price-total">
          {{ "$" + (parseFloat(item.price) * item.quantity).toFixed(2) }}
        </div>
      </div>
      <div class="cart-item_details">
        <div class="cart-item_title">
          <a :href="item.url">{{ item.title }}</a>
        </div>
        <div class="cart-item_totals">
          <div class="cart-item_size">{{ item.size }}</div>
          <div class="cart-item_inventory">
            ({{
              item.allow_preorder ? item.preorder_inventory : item.inventory
            }}
            left in stock )
          </div>
        </div>
        <div class="cart-item_subtotal">
          <div class="cart-item_quantity">
            <a
              class="remove trash"
              aria-label="Remove Item"
              v-if="item.quantity == 1"
              v-on:click="displayConfirmRemove()"
              ><i class="fa fa-trash"></i
            ></a>
            <a
              aria-label="Decrease Quantity"
              class="remove"
              v-if="item.quantity > 1"
              v-on:click="decrementQuantity(item.id)"
              v-bind:class="[item.quantity == 1 ? 'disabled' : '']"
              >-</a
            >
            <span class="current">{{ item.quantity }}</span>
            <a
              aria-label="Increase Quantity"
              class="add"
              v-on:click="incrementQuantity(item.id)"
              v-bind:class="[disableIncrementButton ? 'disabled' : '']"
              >+</a
            >
          </div>
        </div>
        <div class="cart-item_action_links">
          <!-- <a class="save" v-on:click.prevent="">Save for Later</a> -->
          <a class="delete" v-on:click.prevent="displayConfirmRemove()"
            >DELETE ITEM</a
          >
        </div>
      </div>
      <div class="cart-item_status plant-status">
        <div
          :class="
            'badge-inventory ' +
            (item.allow_preorder ? 'badge-allow-preorder' : 'badge-in-stock')
          "
        >
          <span>{{ item.allow_preorder ? "Pre-order" : "In Stock" }}</span>
        </div>
      </div>
      <div class="cart-item_receive_options">
        <div class="receive_option">
          <div class="input_wrapper">
            <input
              type="radio"
              :id="item.id + '_storePickup'"
              value="storePickup"
              :name="item.id + '_receive_option'"
              :checked="isInStoreSelected"
              @change="updateItemDeliveryOption(item.id, 'store-pickup')"
            />
            <label :for="item.id + '_storePickup'">In Store</label>
          </div>
          <span
            v-show="inStoreInputMsg"
            class="receive_option_disclaimer green"
            >{{ inStoreInputMsg }}</span
          >
        </div>
        <div class="receive_option">
          <div class="input_wrapper">
            <input
              type="radio"
              :id="item.id + '_delivery'"
              value="delivery"
              :name="item.id + '_receive_option'"
              :checked="isDeliverySelected"
              :disabled="!isDeliveryAvailable"
              @change="updateItemDeliveryOption(item.id, 'delivery')"
            />
            <label :for="item.id + '_delivery'">Delivery</label>
          </div>
          <span v-show="deliveryInputMsg" class="receive_option_disclaimer">{{
            deliveryInputMsg
          }}</span>
        </div>
        <div class="receive_option">
          <div class="input_wrapper">
            <input
              type="radio"
              :id="item.id + '_deliveryInstallation'"
              value="deliveryInstallation"
              :name="item.id + '_receive_option'"
              :checked="isDeliveryInstallationSelected"
              :disabled="!isDeliveryInstallationAvailable"
              @change="
                updateItemDeliveryOption(item.id, 'delivery-installation')
              "
            />
            <label :for="item.id + '_deliveryInstallation'"
              >Delivery & Installation</label
            >
          </div>
          <span
            v-show="deliveryInstallationInputMsg"
            class="receive_option_disclaimer"
            >{{ deliveryInstallationInputMsg }}</span
          >
        </div>
      </div>
      <div class="cart-item_price-total">
        {{ "$" + (parseFloat(item.price) * item.quantity).toFixed(2) }}
      </div>
    </div>
    <div class="remove-confirm" v-bind:class="[confirmRemove ? 'in' : '']">
      <p>Would you like to remove this item from your cart?</p>
      <div>
        <a
          href="#"
          class="btn btn-primary confirm"
          v-on:click.prevent="removeItem(item.id)"
          >Remove</a
        >
        <a
          href="#"
          class="btn btn-darker cancel"
          v-on:click.prevent="displayConfirmRemove()"
          >Cancel</a
        >
      </div>
    </div>
  </div>
</template>

<style scope lang="scss">
.cart-checkout-item_component {
  position: relative;
  .cart-item_wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-items: flex-start;
    margin: 30px 30px 0;
    padding: 0 0 50px;
    border-bottom: 1px solid #e5e5e4;
    .cart-item {
      &_img_wrapper {
        .cart-item_img {
          flex: 0 0 auto;
          width: 85px;
          height: 85px;
          background-size: cover;
          background-position: center center;
          margin-right: 1rem;
          flex-shrink: 0;
          border-radius: 4px;
        }
        .cart-item_status {
          display: none;
          margin-top: 16px;
          @media screen and (max-width: 900px) {
            display: block;
          }
        }
        .cart-item_price-total {
          display: none;
          margin-top: 16px;
          @media screen and (max-width: 700px) {
            display: block;
            text-align: left;
          }
        }
      }
      &_details {
        flex: 0 0 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #111111;
        .cart-item_title {
          font-weight: 600;
          font-size: 20px;
          color: #111111;
          letter-spacing: 0;
          @media screen and (max-width: 768px) {
            margin-bottom: 2px;
          }
          a {
            color: #111111;
          }
        }
        .cart-item_totals {
          display: flex;
          .cart-item_size {
            margin-right: 5px;
          }
          .cart-item_size,
          .cart-item_inventory {
            font-weight: 100;
            font-size: 14px;
            color: #111111;
            letter-spacing: 0;
            margin-bottom: 20px;
            @media screen and (max-width: 768px) {
              margin-bottom: 3px;
            }
          }
        }
        .cart-item_subtotal {
          margin-bottom: 30px;
          .cart-item_quantity {
            border: none;
            margin-top: 4px;
            display: flex;
            a {
              display: block;
              width: 30px;
              height: 23px;
              border: 1px solid #009f5e;
              border-radius: 4px;
              font-size: 18px;
              text-align: center;
              line-height: 1;
              text-decoration: none;
              cursor: pointer;
              &.disabled {
                cursor: not-allowed;
              }
              &.remove {
                background: white;
                color: #009f5e;
              }
              &.add {
                background: #009f5e;
                color: white;
              }
            }
            span {
              width: auto;
              padding: 0 30px;
            }
          }
        }
        .cart-item_action_links {
          display: flex;
          flex-direction: column;
          a {
            cursor: pointer;
          }
          a.save {
            font-size: 14px;
            color: #757575;
            letter-spacing: 0;
            margin-bottom: 15px;
            text-decoration: underline;
          }
          a.delete {
            font-size: 14px;
            color: #c70000;
            letter-spacing: 0;
            text-decoration: underline;
          }
        }
      }
      &_status {
        flex: 0 0 15%;
        .badge-inventory {
          span {
            font-size: 14px;
          }
        }
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
      &_receive_options {
        flex: 0 1 auto;
        .receive_option {
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;
          .input_wrapper {
            label {
              font-size: 16px;
              color: #000000;
              letter-spacing: 0;
              margin-bottom: 0;
            }
            input {
              &:disabled {
                appearance: none;
                background-color: var(--bg-color);
                background-image: url("/assets/images/disabled-radio-option.png");
                width: 12px;
                height: 12px;
                background-size: cover;
              }
            }
          }
          span {
            font-size: 14px;
            color: #31bc4d;
            letter-spacing: 0;
            line-height: 15px;
          }
        }
      }
      &_price-total {
        flex: 1 0 auto;
        font-weight: 700;
        font-size: 20px;
        color: #111111;
        letter-spacing: 0;
        text-align: right;
        @media screen and (max-width: 700px) {
          display: none;
        }
      }

      @media screen and (max-width: 700px) {
        &_details {
          flex: 1;
        }
        &_receive_options {
          flex: 1;
        }
      }
    }
    @media screen and (max-width: 568px) {
      flex-direction: column;
      margin: 30px 15px 0;
      .cart-item_img_wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
        .cart-item_status,
        .cart-item_price-total {
          margin-top: 0;
          align-self: center !important;
        }
      }
      .cart-item_details {
        margin-bottom: 16px;
      }
    }
  }
  .remove-confirm {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: transparentize(#fff, 0.15);
    transition: all 0.3s;
    opacity: 0;
    display: none;

    &.in {
      display: flex;
      opacity: 1;
    }

    .confirm,
    .cancel {
      padding: 10px 15px !important;

      & + button,
      & + a {
        margin-left: 1rem;
      }
    }

    p {
      margin-bottom: 0.5rem;
      padding: 0 15px;
      // color: #fff;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
}
</style>

<script>
//Notes: remove selected props, instead when selected these option should call updateItemFulfillmentType in cart.js so that all fufil types are set to the selected option.
export default {
  name: "cart-checkout-item-component",
  props: [
    "item",
    "index",
    "delivery-selected",
    "in-store-selected",
    "delivery-installation-selected",
    "delivery-available",
    "zip-code-confirmed",
    "cart-total-is-above-minimum-delivery-amount",
  ],
  data() {
    return {
      confirmRemove: false,
    };
  },
  computed: {
    disableIncrementButton() {
      if (this.item.allow_preorder) {
        return this.item.preorder_inventory == this.item.quantity
          ? true
          : false;
      } else {
        return this.item.inventory == this.item.quantity ? true : false;
      }
    },
    isInStoreSelected() {
      if (this.item.fulfillment_type === "store-pickup") {
        return true;
      }
      return false;
    },
    isDeliverySelected() {
      if (this.item.fulfillment_type === "delivery") {
        return true;
      }
      return false;
    },

    isDeliveryInstallationSelected() {
      if (this.item.fulfillment_type === "delivery-installation") {
        return true;
      }
      return false;
    },

    isDeliveryAvailable() {
      return this.deliveryAvailable;
    },

    isDeliveryInstallationAvailable() {
      if (this.isSupplies) {
        return false;
      }
      return this.deliveryAvailable;
    },

    isSupplies() {
      return this.item.category === "Supplies" ? true : false;
    },

    deliveryInputMsg: function () {
      if (!this.cartTotalIsAboveMinimumDeliveryAmount) {
        return "Delivery and installation options are not available";
      }

      if (!this.zipCodeConfirmed) {
        return "Enter ZIP code to determine availability";
      }

      if (!this.deliveryAvailable) {
        return "Not available in your area";
      }
      return "";
    },

    deliveryInstallationInputMsg: function () {
      if (this.isSupplies) {
        return "Delivery & Installation costs include supplies. Please remove if it is not necessary.";
      }

      if (!this.cartTotalIsAboveMinimumDeliveryAmount) {
        return "Delivery and installation options are not available";
      }

      if (!this.zipCodeConfirmed) {
        return "Enter ZIP code to determine availability";
      }

      if (!this.deliveryAvailable) {
        return "Not available in your area";
      }
      return "";
    },

    inStoreInputMsg: function () {
      if (this.item.allow_preorder) {
        return "Estimated pickup date: " + this.item.preorder_availability_date;
      } else {
        return "";
      }
    },
  },
  methods: {
    displayConfirmRemove() {
      this.confirmRemove = !this.confirmRemove;
    },
    decrementQuantity(id) {
      this.$emit("decrement-quantity", id);
    },
    incrementQuantity(id) {
      this.$emit("increment-quantity", id);
    },
    removeItem(id) {
      this.$emit("remove-item", id);
    },
    updateItemDeliveryOption(id, fulfillment_type) {
      this.$emit("update-item-delivery-option", id, fulfillment_type);
    },
  },
};
</script>