class shareHelper {
	constructor() {
		this.initializeSocialSharing();
	}

    initializeSocialSharing() {
        $('a.popup').click(function(e) {
            e.preventDefault();
            var social = $(this).data('social');
            var width = 575,
                height = 400,
                left = ($(window).width() - width) / 2,
                top = ($(window).height() - height) / 2,
                url = this.href,
                opts = 'status=1' +
                ',width=' + width +
                ',height=' + height +
                ',top=' + top +
                ',left=' + left;

            window.open(url, social, opts);

            return false;
        });
    }
}
window.app.add('share', new shareHelper());