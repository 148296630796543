<template>
    <div v-if="user_is_verified" class="vueContainer">
        <cart-header-component 
            :user_is_verified="user_is_verified" 
            :cart_count="cart_count"
            :updating="updating" 
            @hide-cart="hideCart">
        </cart-header-component>
        <cart-pane-toggle-component 
            :wishlist_count="wishlist_count">
        </cart-pane-toggle-component>
        <cart-contents-component 
            v-if="$store.state.cartActivePane == 'cart'" 
            :user_is_verified="user_is_verified" 
            :cart="cart" 
            :cart_count="cart_count" 
            empty_text="Your cart is empty" 
            categories_text="View Categories" 
            @increment-quantity="incrementItemQuantity" 
            @decrement-quantity="decrementItemQuantity" 
            @remove-item="removeItem">
        </cart-contents-component>
        <cart-footer-component 
            v-if="$store.state.cartActivePane == 'cart'"
            :cart_subtotal="cart_subtotal" 
            :cart_tax="cart_tax" 
            :cart_total="cart_total" 
            :cart_total_exceeds_minimum="cart_total_exceeds_minimum"
            :cart_count="cart_count" 
            :min_order_amount="min_order_amount">
        </cart-footer-component>
        <wish-list-component 
            :wish_list="wish_list" 
            :wish_list_count="wishlist_count" 
            :wish_list_state="wish_list_state" 
            v-if="$store.state.cartActivePane == 'shoppingList'" 
            @remove-wish-list-item="removeWishListItem"
            @empty-wish-list="emptyWishList" 
            @email-wish-list="emailWishList" 
            :confirm_email_wish_list="confirm_email_wish_list">
        </wish-list-component>
    </div>
    <div v-else class="vueContainer">
        <cart-header-component 
            :user_is_verified="user_is_verified" 
            @hide-cart="hideCart">
        </cart-header-component>
        <cart-contents-component 
            :user_is_verified="user_is_verified">
        </cart-contents-component>
    </div>
</template>
<style lang="scss" scoped>
.vueContainer {
    display: contents;
}
</style>
<script>
export default {
    props: {
        user_is_verified: false,
        cart_subtotal: 0,
        cart_tax: 0,
        cart_total: 0,
        cart_total_exceeds_minimum: false,
    },
    data() {
        return {
            updating: false,
            min_order_amount: window._NATORPS.general.minimum_order_price || 0,
            wish_list_state: 'action',
            confirm_email_wish_list: false,
        }
    },
    computed: {
        cart() {
            return this.$store.state.slideoutCart;
        },
        wish_list() {
            return this.$store.state.wishlistItems;
        },
        cart_count() {
            return this.$store.getters.cartCount;
        },
        wishlist_count() {
            return this.$store.getters.wishlistCount;
        },
    },
    methods: {
        /* The following are used to handle events */
        hideCart() { // Hides the cart slide out menu
            let cart = document.getElementById('cart-slideout'),
                backdrop = document.getElementById('cart-backdrop');

            cart.classList.add('animated', 'slideOutRight');
            backdrop.classList.add('animated', 'fadeOut');
            let timeOut = setTimeout(function() {
                cart.classList.remove('in', 'animated', 'slideOutRight');
                backdrop.classList.remove('in', 'animated', 'fadeOut');
                window.app.cartInstance.$data.shown = false;
                clearTimeout(timeOut);
            }, 300);
        },
        incrementItemQuantity(id) { // Increases the amount of an item by 1 (at spot {id} in the items array)
            
            let product = this.$store.state.slideoutCart.find(item => item.id === id);
            if (!product) return;
            if (product.quantity == product.inventory && !product.allow_preorder) return;
            if (product.quantity === product.preorder_inventory && product.allow_preorder) return;
            
            let quantity = parseInt(product.quantity) + 1;

            console.log(`Increasing the amount of item at ${id} by 1!`);
            this.updateItem(id, quantity);
        },
        decrementItemQuantity(id) {  // Decreases the amount of an item by 1 (at spot {id} in the items array)
            console.log(`Decreasing the amount of item at ${id} by 1!`);
            let product = this.$store.state.slideoutCart.find(item => item.id === id);
            if (!product) return;
            if (product.quantity == 1) return;
            let quantity = parseInt(product.quantity) - 1;
            this.updateItem(
                id,
                quantity
            );
        },
        removeItem(id) { // Removes the item at spot {id} of the cart items array
            console.log(`Removing the item at ${id}!`);
            this.updating = true;
            this.confirmRemove = false;
            let self = this;

            $.ajax({
                url: '/cart/remove',
                method: 'POST',
                data: {
                    id: id,
                },
                success: function(data) {
                    // console.log(data);
                    self.$store.dispatch('slideoutCartReplace', data.cart);
                },
                error: function(error) {
                    // console.log(error);
                },
            }).then(function() {
                //self.finishUpdate();
                self.updating = false;
            });
        },
        updateItem: function(id, quantity) {
            let self = this;
            this.updating = true;
            return $.ajax({
                url: '/cart/updateItemQuantity',
                method: 'POST',
                data: {
                    id: id,
                    quantity: quantity,
                },
                success: function(data) {
                    self.$store.dispatch('slideoutCartReplace', data.cart);
                },
                error: function(error) {
                    // console.log(error);
                },
            }).then(function() {
                //self.finishUpdate();
                self.updating = false;
            });
        },
        removeWishListItem(index) { // Removes the wish list item at spot {index} of the wishlist items array
            // console.log('Will remove shopping list item with id', this.shoppingList[index].id);
            this.updating = true;
            //this.confirmShoppingListRemove = false;
            let self = this,
                id = this.$store.state.wishlistItems[index].id;
            $.ajax({
                url: '/shoppingList/remove',
                method: 'POST',
                data: {
                    id: id,
                },
                success: function(data) {
                    // console.log(data);
                    //self.$store.state.wishlistItems = data.shoppingList;
                    self.$store.dispatch('wishlistReplace', data.shoppingList);
                },
                error: function(error) {
                    // console.log(error);
                },
            }).then(function() {
                self.updating = false;
            });
        },
        emptyWishList(index) { // Removes all items in the wishlist array
            // console.log('Will empty shopping list');
            this.updating = true;
            // TODO: this.confirmEmptyShoppingList = false;
            var self = this;
            $.ajax({
                method: 'POST',
                url: '/shoppingList/empty',
                success: function(data) {
                    // console.log(data);
                    self.$store.dispatch('wishlistReplace', data.shoppingList);
                },
                error: function(error) {
                    // console.log(error);
                },
            }).then(function() {
                self.updating = false;
            });
        },
        emailWishList(index) { // Emails the user a copy of their wishlist
            // console.log('Will email shopping list');
            this.updating = true;
            this.confirm_email_wish_list = true;
            let self = this;
            $.ajax({
                method: 'POST',
                url: '/shoppingList/email',
                success: function(data) {
                    // console.log('Success:', data);
                },
                error: function(error) {
                    // console.log('Error:', error);
                },
            }).then(function() {
                self.updating = false;
                self.wish_list_state = 'notify';
                setTimeout(function() {
                    self.wish_list_state = 'action';
                    self.confirm_email_wish_list = false;
                }, 2000);
            });
        }
        /* Normal methods */
    }
}
</script>
