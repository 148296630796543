<template>
<div class="cart-header" v-if="!user_is_verified">
    <div class="close-wrap"><button aria-label="Close Cart" class="close" v-on:click="hideCart"></button></div>
    <div class="count">Your Cart</div>
    <div class="total"></div>
</div>
<div class="cart-header" v-else>
    <div class="close-wrap"><button aria-label="Close Cart" class="close" v-on:click="hideCart"></button></div>
    <div class="count" v-if="!updating">Your Cart ({{ cart_count }})</div>
    <div class="count" v-if="updating">
        <i class="fa fa-spin fa-spinner"></i>
        <span>Updating cart...</span>
    </div>
    <div class="total"></div>
</div>
</template>
<style lang="scss" scoped>
</style>
<script>
export default {
    props: {
        user_is_verified: false,
        cart_count: 0,
        updating: false,
    },
    data() {
        return {}
    },
    computed: {},
    methods: {
        hideCart(e) {
            this.$emit('hide-cart');
        },
    }
}
</script>