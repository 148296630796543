import '@babel/polyfill';
// import 'es6-promise/auto';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
Vue.config.devtools = true

export const store = new Vuex.Store({
	state: {
		favorites: [], // Non cart-slideout stuff (mostly wholesale things)
		cart: [],
		isTaxExempt: false,
		tax: 0,
		taxRate: 0,
		subTotal: 0,
		shippingHandling: 0,
		grandTotal: 0,
		wholesalePremier: false,
		plantDetailId: null,
		updating: false,
		userInformation: [],
		cartActivePane: 'cart', // This is where the cart slideout stuff starts
		slideoutCart: [],
		wishlistItems: [],
	},
	getters: {
		cartItemsQuantityMap: state => {
			// hashMap productID => selectedQuantity
			const hashMap = {};
			state.cart.forEach(
				cartItem => (hashMap[`${cartItem.id}`] = cartItem.quantity)
			);
			return hashMap;
		},
		cartItemsIds: state => {
			return state.cart.map(item => item.id);
		},
		cartItemsCount: state => {
			return state.cart.reduce(
				(total, current) => (total += current.quantity),
				0
			);
		},
		cartItemsTotal: state => {
			return state.cart
				.reduce((total, item) => {
					return total + item.price * item.quantity;
				}, 0)
				.toFixed(2);
		},
		favoriteItemsIds: state => {
			return state.favorites.map(item => item.plant_id);
		},
		showPlantDetail: state => {
			// @Devnote add more verbose check here
			return state.plantDetailId !== null;
		},
		cartCount: state => {
			let count = 0;
			for (let item in state.slideoutCart) {
				count += parseInt(state.slideoutCart[item].quantity);
			}
			return count;
		},
		wishlistCount: state => {
			let count = 0;
			for (let item in state.wishlistItems) {
				count++;
			}
			return count;
		}
	},
	mutations: {
		CART_LOADED(state, cartDetails) {
			// console.log('[CART_LOADED]', cartDetails);
			state.cart = cartDetails.cart;
			state.subTotal = this.getters.cartItemsTotal;
			state.isTaxExempt = cartDetails.isTaxExempt;
			if(!state.isTaxExempt) {
				state.taxRate = cartDetails.taxRate;
				state.tax = state.subTotal * state.taxRate;
			}
		},
		/**
		 * @Devnote - the following three methods are directly
		 * mutating state which is a pattern no-no... FYI
		 *
		 * @author LWK<lkrehnbrink@usdigitalpartners.com>
		 */
		CART_ITEM_ADD(state, cartDetails) {
			// console.log('[CART_ITEM_ADD', cartDetails);
			state.cart = [...cartDetails.cart];
			state.subTotal = this.getters.cartItemsTotal;
			state.tax = state.subTotal * state.taxRate;
		},
		CART_ITEM_UPDATE(state, cartDetails) {
			// console.log('[CART_ITEM_UPDATE', cartDetails);
			state.cart = [...cartDetails.cart];
			state.subTotal = this.getters.cartItemsTotal;
			state.tax = state.subTotal * state.taxRate;
		},
		CART_ITEM_REMOVE(state, cartDetails) {
			// console.log('[CART_ITEM_REMOVE', cartDetails);
			state.cart = [...cartDetails.cart];
			state.subTotal = this.getters.cartItemsTotal;
			state.tax = state.subTotal * state.taxRate;
		},
		FAVORITES_LOADED(state, favorites) {
			// console.log('[FAVORITES_LOADED]', favorites);
			state.favorites = favorites;
		},
		FAVORITES_ADD(state, item) {
			// console.log('[FAVORITES_ADD', item);
			state.favorites = [...state.favorites, item];
		},
		FAVORITES_REMOVE(state, plantId) {
			// console.log('[FAVORITES_REMOVE]', plantId);
			state.favorites = [
				...state.favorites.filter(item => item.plant_id !== plantId)
			];
		},
		USERINFORMATION_LOADED(state, userInformation) {
			// console.log('[USERINFORMATION_LOADED]', userInformation);
			state.userInformation = userInformation;
		},
		// PRODUCTS_LOAD(state, { page, filters }) {
		//     console.log('Load products with filters:', page, filters);
		//     axios.get(`${API_BASE_URL}/products`)
		//         .then(
		//             ({data}) => {
		//                 console.log('PRODUCTS', data);
		//                 state.productGroups = data;
		//             }
		//         );
		// },
		HIDE_PLANT_DETAIL_MODAL(state) {
			// console.log('[HIDE_PLANT_DETAIL_MODAL]');
			state.plantDetailId = null;
		},
		SHOW_PLANT_DETAIL_MODAL(state, plantDetailId) {
			// console.log('[SHOW_PLANT_DETAIL_MODAL]', plantDetailId);
			state.plantDetailId = plantDetailId;
		},
		SET_UPDATING(state, updating) {
			state.updating = updating;
		},
		CART_ACTIVE_PANEL_UPDATE(state, pane) {
			// console.log('[CART_ACTIVE_PANEL_UPDATE', pane);
			state.cartActivePane = pane;
		},
		SLIDEOUT_CART_REPLACE(state, cart) {
			state.slideoutCart = cart;
		},
		WISHLIST_REPLACE(state, list) {
			state.wishlistItems = list;
		}
	},
	actions: {
		cartLoaded: {
			root: true,
			handler(context, cartObject) {
				context.commit('CART_LOADED', cartObject);
			}
		},
		cartItemAdd: {
			root: true,
			handler(context, cartObject) {
				context.commit('CART_ITEM_ADD', cartObject);
			}
		},
		cartItemUpdate: {
			root: true,
			handler(context, cartObject) {
				context.commit('CART_ITEM_UPDATE', cartObject);
			}
		},
		cartItemRemove: {
			root: true,
			handler(context, cartObject) {
				context.commit('CART_ITEM_REMOVE', cartObject);
			}
		},
		favoritesLoaded: {
			root: true,
			handler(context, favorites) {
				context.commit('FAVORITES_LOADED', favorites);
			}
		},
		favoritesAdd: {
			root: true,
			handler(context, item) {
				context.commit('FAVORITES_ADD', item);
			}
		},
		favoritesRemove: {
			root: true,
			handler(context, id) {
				context.commit('FAVORITES_REMOVE', id);
			}
		},
		userInformationLoaded: {
			root: true,
			handler(context, userInformation) {
				context.commit('USERINFORMATION_LOADED', userInformation);
			}
		},
		showPlantDetailModal(context, plantDetailId) {
			context.commit('SHOW_PLANT_DETAIL_MODAL', plantDetailId);
		},
		hidePlantDetailModal(context) {
			context.commit('HIDE_PLANT_DETAIL_MODAL');
		},
		// productsLoad: {
		//     root: true,
		//     handler(context, payload) {
		//         context.commit('PRODUCTS_LOAD', payload)
		//     }
		// },
		setUpdating: {
			root: true,
			handler(context, updating) {
				context.commit('SET_UPDATING', updating);
			}
		},
		cartActivePaneUpdate: {
			root: true,
			handler(context, pane) {
				context.commit('CART_ACTIVE_PANEL_UPDATE', pane);
			}
		},
		slideoutCartReplace: {
			root: true,
			handler(context, cart) {
				context.commit('SLIDEOUT_CART_REPLACE', cart);
			}
		},
		wishlistReplace: {
			root: true,
			handler(context, list) {
				context.commit('WISHLIST_REPLACE', list);
			}
		},
	}
});

export const STORE_ACTIONS = {
	CART_LOADED: 'cartLoaded',
	CART_ITEM_ADD: 'cartItemAdd',
	CART_ITEM_UPDATE: 'cartItemUpdate',
	CART_ITEM_REMOVE: 'cartItemRemove',
	FAVORITES_LOADED: 'favoritesLoaded',
	FAVORITES_ADD: 'favoritesAdd',
	FAVORITES_REMOVE: 'favoritesRemove',
	USERINFORMATION_LOADED: 'userInformationLoaded',
	SET_UPDATING: 'setUpdating',
	SHOW_PLANT_DETAIL_MODAL: 'showPlantDetailModal',
	HIDE_PLANT_DETAIL_MODAL: 'hidePlantDetailModal',
	CART_ACTIVE_PANE_UPDATE: 'cartActivePaneUpdate',
	SLIDEOUT_CART_REPLACE: 'slideoutCartReplace',
	WISHLIST_REPLACE: 'wishlistReplace',
};
