<template>
    <div class="cart-footer">
        <cart-sub-footer-component :cart_subtotal="cart_subtotal" :cart_tax="cart_tax" :cart_total="cart_total" v-on="$listeners"></cart-sub-footer-component>
        <cart-footer-checkout-component :cart_total_exceeds_minimum="cart_total_exceeds_minimum" :cart_count="cart_count" :min_order_amount="min_order_amount" v-on="$listeners"></cart-footer-checkout-component>
    </div>
</template>
<style lang="scss" scoped>
</style>
<script>
export default {
    props: {
        cart_subtotal: '',
        cart_tax: '',
        cart_total: '',
        cart_total_exceeds_minimum: false,
        cart_count: 0,
        min_order_amount: 0,
    },
    data() {
        return {}
    },
    computed: {},
    methods: {},
}
</script>