<template>
    
    <div class="container-fluid container-max-width py-2" id="checkout-wrapper" v-cloak app-call="app.common.initDatePicker">

          <!-- @Cart Contents  -->
        <div class="cart-content" >
            <div class="cart-contents-wrapper w-100">
                <form method="POST" id="wholesaleOrderForm" name="wholesaleOrderForm" action="/checkout/place">
                    <input type="hidden" name="_token" :value="csrf">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="checkout-intro mb-2 w-100 justify-content-between align-items-center d-block d-md-flex">
                                <h1 class="d-inline-block mb-1 mb-md-0">Submit Order</h1>
                                <a href="/order" class="btn btn-darker">Keep Shopping</a>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="checkout-card card">
                                <div class="card-header">
                                    <h2>Pickup or Delivery</h2>
                                    <p>Please choose an option below.</p>
                                    <div class="form-check form-check-inline mr-2">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <input class="form-check-input m-0" type="radio" name="includeDelivery" v-bind:value="true" v-model="includeShipping">
                                            <label class="form-check-label" for="inputDelivery">Delivery</label>
                                        </div>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <input class="form-check-input m-0" type="radio" name="includeDelivery" v-bind:value="false" v-model="includeShipping">
                                            <label class="form-check-label" for="inputPickup">Pickup</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="checkout-card card">
                                <div class="card-header">
                                    <h2>Preferred Day & Time</h2>
                                    <div class="input-group date mb-1">
                                        <input type="text" class="form-control" name="requested_date" id="requested_date" autocomplete="new-password">
                                        <div class="input-group-append">
                                            <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                                        </div>
                                    </div>
                                    <div class="form-check form-check-inline mr-2">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <input class="form-check-input m-0" type="radio" name="requested_time" id="deliveryMorning" value="morning">
                                            <label class="form-check-label" for="deliveryMorning">Morning</label>
                                        </div>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <input class="form-check-input m-0" type="radio" name="requested_time" id="deliveryAfternoon" value="afternoon">
                                            <label class="form-check-label" for="deliveryAfternoon">Afternoon</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="checkout-card card">
                                <div class="card-header">
                                    <h2>Contact Information</h2>
                                    <div class="form-group">
                                        <label for="full_name">Contact Person</label>
                                        <input type="text" class="form-control" id="full_name" name="full_name" aria-describedby="full_name" placeholder="Full Name" v-model="userInformation.full_name">
                                    </div>
                                    <div class="form-group">
                                        <label for="phone">Phone Number</label>
                                        <input type="text" class="form-control" id="phone" name="phone" aria-describedby="phone" placeholder="+1() -" v-model="userInformation.phone">
                                    </div>
                                    <div class="form-group">
                                        <label for="email">Email Address</label>
                                        <input type="text" class="form-control" id="email" name="email" aria-describedby="email" placeholder="jsmith@gmail.com" v-model="userInformation.email">
                                    </div>
                                </div>
                            </div>

                            <div class="checkout-card card">
                                <div class="card-header">
                                    <h2>Order Notes</h2>
                                    <div class="form-group">
                                        <textarea class="form-control" id="order_notes" name="order_notes" aria-describedby="order_notes" placeholder="Please add any order notes, special requests, or delivery instructions here." rows="6" v-model="userInformation.order_notes"></textarea>
                                    </div>
                                </div>
                            </div>

                            

                        </div>


                        <div class="col-lg-6">
                            <div class="notes-wrapper checkout-card card">
                                <div class="card-header">
                                    <div class="d-block d-md-flex justify-content-between align-items-center">
                                        <h2 class="d-inline mb-1 mb-md-0">Order Summary</h2>
                                        <!-- <a href="/wholesale/disclaimer" target="_blank" class="d-block pull-md-right text-decoration-underline">+ Plant Availability Disclaimer</a> -->
                                    </div>
                                </div>
                                <order-table-cart-component :cartItems="cart" :checkOutCart=true></order-table-cart-component>
                                <div class="card-body p-0">
                                    <div class="table-wrapper mb-3">
                                        <div id="order-container">
                                        </div>
                                        <div class=" totals">
                                            <table class="table table-totals">
                                                <tbody>
                                                    <!-- <tr class="shipping lead" v-if="includeShipping">
                                                        <th>Shipping & Handling</th>
                                                        <td class="text-right">{{cartShippingHandling|currency}}</td>
                                                    </tr> -->
                                                    <tr v-if="!isTaxExempt"  class="subtotal lead">
                                                        <th>Subtotal</th>
                                                        <td class="text-right">{{cartSubtotal|currency}}</td>
                                                    </tr>
                                                    <tr v-if="!isTaxExempt" class="tax lead">
                                                        <th>Tax:</th>
                                                        <td class="text-right">{{cartTax|currency}}</td>
                                                    </tr>
                                                    <tr class="total lead">
                                                        <th>Order Total:</th>
                                                        <td class="text-right"><strong>{{cartTotal|currency}}</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="finalize-order p-2 text-center">
                                            <p class="mb-2">Additional delivery fees may apply</p>
                                            <button class="btn btn-darker" v-on:click.stop.prevent="createOrder" :disabled="isDisabled">Submit Order</button>
                                            <p class="disclaimer mt-2">By clicking "Submit Order" you agree to our <a href="https://plants.natorp.com/terms-and-conditions">Terms & Conditions</a> and <a href="https://plants.natorp.com/privacy-policy">Privacy Policy</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <input id="amount" name="amount" type="hidden" :value="cartTotal" />
                    <input type="hidden" id="include_shipping" name="include_shipping" v-model="includeShipping" />
                </form>
            </div>
        </div><!--/.cart-contents-->
    </div><!--/.container-->
</template>
<style lang="scss" scoped>
    table.table-totals tr.total {
        font-size: 1.5rem;
        border-bottom: 1px solid #eceeef;
    }
    .cart-item {
        td {
            padding: .5rem 1rem !important;
        }
    }
    .finalize-order{

        p{
            font-size: 16px;

            &.disclaimer{
                max-width: 300px;
                margin: auto;
                font-size: 14px;
            }
        }
    }
    input[type="radio"]{
        margin-right: 6px !important;
    }
    h2{
        margin-bottom: .5rem !important;
    }
    .card.checkout-card{
        margin-bottom: 50px;

        @media screen and (max-width: 600px){
            margin-bottom: 24px;
        }
    }
    #checkout-wrapper {
        padding-left: 15px;
        padding-right: 15px;
    }
</style>
<script>
import { API_BASE_URL, WholesaleApiService } from '../services/wholesale-api.service';
import { STORE_ACTIONS } from '../store';
const DEFAULT_PRODUCTS_URL = `${API_BASE_URL}/products?page=1`;
Vue.prototype.api = WholesaleApiService;
export default {
    data() {
        return {
            loading: false,
            cartShippingHandling: "",
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            includeShipping: false,
            isDisabled: false
        }
    },
    computed: {
        cart(){ 
            return this.$store.state.cart;
        },
        updating(){
            return this.$store.state.updating;
        },
        cartSubtotal(){
            return this.$store.state.subTotal;
        },
        cartTax(){
            return this.$store.state.tax;
        },
        cartTotal(){
            return (parseFloat(this.$store.state.tax) + parseFloat(this.$store.state.subTotal) + parseFloat(this.$store.state.shippingHandling));
        },
        isTaxExempt(){
            return this.$store.state.isTaxExempt;
        },
        userInformation(){
            let data = this.$store.state.userInformation;
            const result = {
                ...data,
                ...this.old,
            };
            return result;
        }
    },
    methods: {
        createOrder(){
            // console.log('creating order');
            this.isDisabled = true;
            document.getElementById('wholesaleOrderForm').submit();
            setTimeout(() => {
                this.isDisabled = false;
            }, 2000);
        }
    },
    mounted() {
        document.getElementById("wholesale-app").className += " checkout-page";
    },
    props: ['old'],
}
</script>