class scrollHelper{
	constructor() {
		this.bindScrollHandler();
	}
	bindScrollHandler() {
		var scrollLinks = Array.from(document.querySelectorAll('[data-scroll-to]'));
		scrollLinks.forEach((link,i) => {
			$(link).on('click', function(e) {
				e.preventDefault();
				$('html, body').animate({
					scrollTop: $($(this).attr('href')).offset().top - 125
				}, 1000);
			});
		});
	}
}
window.app.add('scroll', new scrollHelper());