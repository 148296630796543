<template>
    <!--
        @Devnote this component represents a single Plant Group,
        which contains multiple Plant Products.
    -->
    <tbody class="plant-group" v-bind:class="{ 'even': index%2===0, 'odd': index%2!==0 }">
        <order-table-item-component
            v-for="(product, i) of productGroup.available_products"
            :key="product.ProductID"
            :index="i"
            :product="product"
            :plantDetails="plantDetails">
        </order-table-item-component>
        <!-- <tr v-if="!productGroup.available_products.length" class="text-muted">
            <td>{{ plantDetails.BotanicalName }}</td>
            <td :colspan="columns.length - 1" class="text-center">
                No availability
            </td>
        </tr> -->
    </tbody>
</template>
<style lang="scss">
    tbody.plant-group {
        &.even {
            background-color: white;

            @media screen and (max-width: 600px) {
                background-color: white;
            }
        }
        &.odd{
            background-color: #E5E5E4;
            @media screen and (max-width: 600px) {
                background-color: white;
            }
        }
        @media screen and (max-width: 600px) {
            td:nth-child(3),
            td:nth-child(4),
            td:nth-child(5),
            td:nth-child(6),
            td:nth-child(7)
            {
                display: none !important;
            }
        }

        tr.show-all{
            td{
                display: flex !important;
            }
        }

    }
</style>
<script>
import { ORDER_COLUMNS } from './order-table.component.vue';
export default {
    data() {
        return {
            columns: ORDER_COLUMNS
        }
    },
    computed: {
        /**
         * Plant details w/o product info
         * 
         */
        plantDetails() {
            const res = { ...this.productGroup };
            delete res.available_products;
            return res;
        }
    },
    props: ['productGroup', 'index']
}

const sample = { 
    "PlantClassID": "00300",
    "BotanicalName": "Abies balsamea (Canaan Fir)",
    "CommonName": "Canaan Fir (Abies balsamea)",
    "PlantGroup": "Tree",
    "available_products": []
};
</script>