
class equalHelper {
    constructor(){

    }

	sameHeight(el) {
		var b_tar = $(el).attr('data-target');
		var limit = $(el).attr('data-limit');
		if( limit !== null && window.innerWidth < limit) {
		return;
		}
		var tar = [];
		if(b_tar.indexOf(', ') > -1) {
		tar = b_tar.split(', ');
		} else {
		tar.push(b_tar);
		}
		tar.forEach(function(item){
		var kids = $(el).find(item);
		var minH = 0;
		kids.each(function(){
			minH = ( minH < $(this).outerHeight() ) ? $(this).outerHeight() : minH;
		});
		kids.each(function(){
			$(this).css('min-height', minH+'px');
		});
		});
	}
}
window.app.add('equal', new equalHelper());