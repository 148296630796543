<template>
  <div>
    <div class="add-to-cart" :data-product="plant_class_id">
      <div class="add-to-cart_header">
        <h2>Add to Cart</h2>
      </div>
      <div class="add-to-cart_body">
        <!-- Product Sizing -->
        <div class="form-group">
          <div class="product-option-header">
            <span id="size-label"><strong>Size</strong></span>
            <span id="price-label"><strong>Price</strong></span>
          </div>
          <div class="product-option" :key="item.id" v-for="item in filteredProducts" :value="item">
            <div class="product-info">
              <div class="product-size" aria-labelledby="size-label">
                {{ item.size }}
                <span v-if="item.allow_preorder && !in_store_only" class="quantity-available">({{ item.future_available ? item.preorder_inventory + " available on " + item.preorder_availability_date : "out of stock"}})</span>
                <span v-else-if="item.available && !in_store_only" class="quantity-available">({{ item.available ? item.inventory + " left in stock" : "out of stock"}})</span>
              </div>
              <div class="product-price" aria-labelledby="price-label">
                <span class="price" v-if="(item.available || item.allow_preorder) && !in_store_only">{{ item.price | currency }}</span>
                <span class="available-soon" v-else>Available Soon</span>
              </div>
            </div>
            <div class="product-unavailable" v-if="(!item.available || !item.future_available) && in_store_only">
              <div v-if="!item.showNotifyMessage"> This product will have an estimated {{ item.preorder_inventory }} available on approximately {{ item.available_date }}
                <template v-if="user_is_logged_in">
                  <a href="#" v-on:click="notifyMe(item)">Click here</a> to be notified when this product becomes available again.</template>
                <template v-else>
                  <a :href="login_url">Sign In</a> and check back here to to be notified when this product becomes available again.</template>
              </div>
              <div v-if="item.showNotifyMessage">
                A notification email will be sent to you when this product is in stock.
              </div>
            </div>
            <div class="mt-1 product-cart-quantity" v-if="(item.available || item.allow_preorder) && !in_store_only">
              <button
                class="remove"
                aria-label="Decrease Quantity"
                v-on:click="decrementQuantity(item.id)"
                v-bind:class="[item.quantity == 0 ? 'disabled' : '']"
              >
                -
              </button>
              <span class="current">{{ item.quantity }}</span>
              <button
                class="add"
                aria-label="Increase Quantity"
                v-on:click="incrementQuantity(item.id)"
                v-bind:class="[disableIncrementButton(item) ? 'disabled' : '']"
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="product-allow_preorder" v-if="productsAllowPreorder && !in_store_only">
        <b>Available {{ preorderAvailabilityDate }}</b>
        <br />
        <!-- {{ item.preorder_inventory }} -->
        This product is estimated to be in stock on
        {{ preorderAvailabilityDate }}. Preorder ahead of time and continue your
        spring planning! (You will be notified via email when this product is
        ready).
        <template v-if="!user_is_logged_in">
          <a :href="login_url">Sign In</a> and check back here to to be notified
          when this product becomes available again.</template
        >
      </div>
      <template v-if="user_is_logged_in">
        <!-- In-store Only -->
        <div class="alert alert-light text-left pb-0" style="padding-left: 32px; padding-right: 32px" v-if="in_store_only">
          <strong class="alert-title">In Store Only</strong>
          <p class="alert-text mb-0">
            This product is available for in-store purchase only.
          </p>
        </div>
        <template v-if="user_is_verified">
          <!-- Online Purchase -->
          <div id="add-to-cart-buttons" app-call="app.common.addVisibleClassOnScroll" class="add-to-cart_footer" :class="in_store_only ? 'in-store-only' : ''">
            <button v-if="in_store_only"
              type="button"
              class="btn btn-success btn-block btn-sm"
              disabled
            >Available In-store Only</button>

            <button v-if="any_product_available && !in_store_only"
              class="btn btn-primary btn-block btn-sm add-to-cart-button"
              :disabled="disable_add_to_cart"
              v-on:click="addToCart()"
            >
              <span v-if="showAddedQuantity"
                >{{
                  showAddedQuantity +
                  " item" +
                  (showAddedQuantity > 1 ? "s" : "") +
                  " added to Cart"
                }}
                <i class="fa fa-check"></i
              ></span>
              <span v-if="!showAddedQuantity">{{ totalItems > 0 ? "Add " + totalItems + " item" + (totalItems > 1 ? "s" : "") + " to Cart" : addToCartButtonText}}</span>
            </button>
          </div>
        </template>
        <template v-else>
          <!-- Must Verify Email -->
          <div id="add-to-cart-buttons" class="add-to-cart_footer">
            <a :href="verification_url" class="btn btn-success btn-block btn-sm"
              >Verify your Email to Buy</a
            >
          </div>
        </template>
      </template>
      <template v-else>
        <div id="add-to-cart-buttons" class="add-to-cart_footer">
          <a :href="login_url" class="btn btn-success btn-block btn-sm"
            >Sign In to Buy</a
          >
          <p class="text-xs-center mt-1">
            Don't have an account?
            <a :href="register_url">Click here to register.</a>
          </p>
        </div>
      </template>
    </div>
    <div class="add-to-shopping-list" v-if="user_is_logged_in">
      <h3 class="h4" v-if="!added_to_wish_list && !hasWishlistItem(productId)">
        Want to save this product for later?
      </h3>
      <p v-if="added_to_wish_list">
        <strong>Item has been added to Shopping List.</strong>
      </p>
      <p v-if="hasWishlistItem(productId) && !added_to_wish_list">
        <strong>Item is already added to Shopping List.</strong>
      </p>
      <a
        v-if="!added_to_wish_list && !hasWishlistItem(productId)"
        href="#"
        v-on:click.stop.prevent="addToWishList(productId)"
        class="btn btn-outline-primary"
        >Add to Shopping List</a
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  props: {
    user_is_logged_in: false,
    user_is_verified: false,
    in_store_only: false,
    plant_class_id: "",
    verify_url: "",
    login_url: "",
    register_url: "",
    products: [],
    any_product_available: false,
    disable_add_to_cart: false,
    product_image: null,
  },
  data() {
    return {
      showAddedQuantity: false,
      added_to_wish_list: false,
      totalItems: 0,
    };
  },
  computed: {
    productId() {
      return this.products[0].plant_id;
    },
    filteredProducts() {

      const preorders = this.products.filter((product) => {
        return product.allow_preorder && product.preorder_inventory > 0;
      });

      const nonPreorders = this.products.filter((product) => {
        return product.available && product.inventory > 0;
      });
      
      return [...preorders, ...nonPreorders];
    },
    productsAllowPreorder() {
      return this.products.some((product) => product.allow_preorder);
    },
    preorderAvailabilityDate() {
      if (!this.productsAllowPreorder) return false;
      return this.products.reduce((a, b) => {
        return new Date(a.preorder_availability_date) >
          new Date(b.preorder_availability_date)
          ? a
          : b;
      }).preorder_availability_date;
    },
    addToCartButtonText() {
      if (this.productsAllowPreorder) {
        return "Preorder Now";
      } else {
        return "Add to Cart";
      }
    },
  },
  methods: {
    disableIncrementButton(item) {
      if (item.allow_preorder) {
        return item.preorder_inventory == item.quantity ? true : false;
      } else {
        return item.inventory == item.quantity ? true : false;
      }
    },
    notifyMe(item) {
      let self = this;
      return $.ajax({
        url: "/cart/notify",
        method: "POST",
        data: {
          product: item,
        },
        success: function (data) {
          console.log(data);
          console.log(item);
          self.products.find((prod) => item.id == prod.id).showNotifyMessage = true;
        },
        error: function (error) {
          // console.log(error);
        },
      }).then(function () {
        // console.log('finished');
      });
    },
    resetQuantities: function () {
      for (let item in this.products) {
        this.products[item].quantity = 0;
      }
    },
    decrementQuantity(id) {
      let product = this.products.find((item) => item.id == id);
      if (product.quantity === 0) return;

      if (product) {
        product.quantity--;
        this.showAddedQuantity = false;
      }
    },
    incrementQuantity(id) {
      let product = this.products.find((item) => item.id == id);
      if (product.quantity == product.inventory && !product.allow_preorder) return;
      if (product.quantity === product.preorder_inventory && product.allow_preorder) return;
      
      if (product) {
        this.showAddedQuantity = false;
        product.quantity++;
      }
    },
    addToCart() {
      // console.log('Will add ' + this.selectedProduct.name + ' to cart with quantity ' + this.selectedProduct.quantity);
      // @DEVNOTE: add event listener to reset quantity field on cart update
      let productCount = 0;
      let updatedProducts = [];
      let self = this;
      this.products.forEach((product) => {
        if (product.quantity > 0) {
          console.log("add product to cart", product);
          // self.fireGTMaddToCart(product); // TODO: Implement this
          setTimeout(function () {
            window.app.cartInstance.addItem(
              product.id,
              product.plant_id,
              product.quantity
            );
          }, productCount * 300);
          productCount++;
        }
      });

      var listener = window.addEventListener("cartUpdated", function (e) {
        console.log("cartUpdated", e.detail.productId);
        updatedProducts.push({
          productId: e.detail.productId,
          plantId: e.detail.plantId,
          quantity: e.detail.quantity,
          status: "success",
        });
        if (updatedProducts.length == productCount) {
          self.showCartStatus(updatedProducts);
        }
      });
      var listener = window.addEventListener("cartError", function (e) {
        console.log("cartError", e);
        updatedProducts.push({
          productId: e.detail.productId,
          plantId: e.detail.plantId,
          status: "error",
        });
        if (updatedProducts.length == productCount) {
          self.showCartStatus(updatedProducts);
        }
      });
    },
    showCartStatus: function (updatedProducts) {
      var html = '<div class="products-added">';
      var actualAdded = updatedProducts.reduce((total, item) => {
        return total + item.quantity;
      }, 0);
      html +=
        '<div class="total-added">' +
        actualAdded +
        " item" +
        (actualAdded > 1 ? "s" : "") +
        " Added to Cart</div>";

      updatedProducts.forEach((updatedProduct) => {
        var product = this.products.find(
          (product) => product.id == updatedProduct.productId
        );
        html += '<div class="product">';
        html +=
          '<div class="product-image" style="background-image: url(\'/assets/plants/' +
          this.product_image +
          "');\"></div>";
        html +=
          '<div class="product-info"><div class="product-name">' +
          product.name +
          '</div><div class="product-size">' +
          product.size +
          " x" +
          updatedProduct.quantity +
          "</div></div>";
        html += "</div>";
      });
      window.app.cart.showStatus(html);
      this.showAddedQuantity = this.totalItems;
      this.resetQuantities();
    },
    hasWishlistItem(productId) {
      for (let item in this.$store.state.wishlistItems) {
        if (this.$store.state.wishlistItems[item].id == productId) {
          return true;
        }
      }
      return false;
    },
    addToWishList(productId) {
      let self = this,
        message = "";
      if (this.hasWishlistItem(productId)) {
        window.app.cart.showStatus(
          '<div class="shopping-list-item-added">Item already in Shopping List</div>',
          true
        );
        window.dispatchEvent(new window.CustomEvent("shoppingListUpdated"));
      } else {
        $.ajax({
          method: "POST",
          url: "/shoppingList/add",
          data: {
            id: productId,
          },
          success: function (data) {
            console.log(data);
            self.$store.dispatch("wishlistReplace", data.shoppingList);
            message =
              '<div class="shopping-list-item-added">Item added to Wish List</div>';
          },
          error: function (error) {
            console.log(error);
            message =
              '<div class="shopping-list-item-added">Error adding item to Wish List</div>';
          },
        }).then(function () {
          window.dispatchEvent(new window.CustomEvent("shoppingListUpdated"));
          window.app.cart.showStatus(message, true);
        });
      }
      let listener = window.addEventListener(
        "shoppingListUpdated",
        function (e) {
          self.added_to_wish_list = true;
        }
      );
    },
  },
};
</script>