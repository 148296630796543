class landscapeDesignHelper {
    constructor() {

    }
    init(config) {
        this.bindVue(config);
        // console.log('yo');
    }

    bindVue(config) {
        return new Vue({
            delimiters: ['${', '}'],
            el: '#landscape-design-form',
            data: {
                cardType: '',
                deliveryMethod: '',
                cardAmount: config.landscapeDesignFee,
                uploadedImages: [],
                imagesToDisplay: [],
                uploadedError: false,
                uploadedErrorMsg: "",
                // cardAmountOther: '5',
                // processingFee: config.processingFee,
                sameAsBilling: false
            },
            computed: {
                total: function () {
                    return this.cardAmount;
                },
                grandTotal: function () {
                    return this.total;
                },
                serializedImageIds: function () {
                    if (this.imagesToDisplay.length === 0) return null;
                    return JSON.stringify(this.imagesToDisplay.map((img) => img.id));
                }
            },
            methods: {
                bindPayment() {
                    /**
                     * Braintree Integration Details
                     *
                     * @see https://developers.braintreepayments.com/guides/drop-in/setup-and-integration/javascript/v3
                     */
                    var client_token = document.head.querySelector(
                        'meta[name="bt_client_token"]'
                    ).content,
                        dropin_config = {
                            authorization: client_token,
                            selector: '#bt-dropin',
                            // paypal: {
                            //     flow: 'vault', // Clarify difference between 'vault' and 'checkout' workflows
                            //     amount: '50.00',

                            // },
                            venmo: {
                                allowNewBrowserTab: false // review this functionality...
                            }
                        },
                        _this = this;

                    self.braintree.dropin.create(dropin_config, function (
                        createErr,
                        instance
                    ) {
                        if (createErr) {
                            console.log('Create Error', createErr);
                            return;
                        }


                        var landscapeDesignForm = $('#landscape-design-form'),
                            orderButton = $('#order-submit'),
                            brainTree = $('.bt-dropin-wrapper');


                        if (instance.isPaymentMethodRequestable()) {
                            // This will be true if you generated the client token
                            // with a customer ID and there is a saved payment method
                            // available to tokenize with that customer.
                            orderButton.removeAttr("disabled");
                        }
                        instance.on('paymentMethodRequestable', function (event) {
                            console.log(event.type); // The type of Payment Method, e.g 'CreditCard', 'PayPalAccount'.
                            console.log(event.paymentMethodIsSelected); // true if a customer has selected a payment method when paymentMethodRequestable fires

                            orderButton.removeAttr("disabled");
                        });
                        instance.on('noPaymentMethodRequestable', function () {
                            orderButton.attr('disabled', 'disabled');
                        });

                        orderButton.on('click', function (event) {
                            _this.submitting = true;
                            event.preventDefault();

                            if (landscapeDesignForm[0].reportValidity()) {
                                instance.requestPaymentMethod(function (
                                    err,
                                    payload
                                ) {
                                    if (err) {
                                        // console.log('Request Payment Method Error', err);
                                        _this.submitting = false;
                                        return;
                                    }
                                    document.querySelector('#nonce').value =
                                        payload.nonce;

                                    brainTree.hide();
                                    orderButton.hide();
                                    $('#submitting-order').show();

                                    landscapeDesignForm.submit();
                                });
                            }
                            _this.submitting = false;
                        });
                    });
                },
                isNumber: function (evt) {
                    evt = (evt) ? evt : window.event;
                    var charCode = (evt.which) ? evt.which : evt.keyCode;
                    if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                        evt.preventDefault();;
                    } else {
                        return true;
                    }
                },
                uploadImages() {
                    if (this.$refs.file.files.length === 0 ) {
                        this.imagesToDisplay = [];
                        return;
                    }
                    if (Array.from(this.$refs.file.files).length > 5) {
                        this.uploadedError = true;
                        this.uploadedErrorMsg = "Please only uploaded 5 images";
                        return;
                    } else {
                        this.uploadedError = false;
                        this.uploadedErrorMsg = "";
                    }
                    this.uploadedImages = Array.from(this.$refs.file.files);
                },
                submitImages(e) {
                    e.preventDefault();
                    _this = this;
                    const formData = new FormData();
                    const headers = { 'Content-Type': 'multipart/form-data' };
                    this.uploadedImages.forEach((file, index) => {
                        formData.append(`images[]`, file);
                    });
                    axios.post('/upload-landscape-image', formData, { headers }).then((res) => {
                        console.log(res);
                        if (res.data.success) {
                            _this.uploadedError = false;
                            _this.imagesToDisplay = res.data.image_ids;
                        } else {
                            _this.uploadedError = true;
                            _this.uploadedErrorMsg = res.data.message;
                        }
                    });
                },
                removeImage(id) {
                    this.imagesToDisplay = this.imagesToDisplay.filter((img) => {
                        return img.id != id;
                    })
                }
            },
            created: function () {
                this.bindPayment();
            },
            mounted: function () {

            }
        });
    }
}
window.app.add('landscapedesign', new landscapeDesignHelper());
