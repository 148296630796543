<template>
    <div class="totals">
        <div>
            <div class="subtotal">Subtotal: ${{ cart_subtotal }}</div>
            <div class="tax">Tax: ${{ cart_tax }}</div>
        </div>
        <div class="total">Total: ${{ cart_total }}</div>
    </div>
</template>
<style lang="scss" scoped></style>
<script>
import { STORE_ACTIONS } from '../../store';

export default {
    props: {
        cart_subtotal: '',
        cart_tax: '',
        cart_total: '',
    },
    data() {
        return {}
    },
    computed: {
        
    },
    methods: {
        
    },
}
</script>