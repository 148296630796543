class giftCardHelper{
    constructor() {
        
    }  
    init(config) {
        this.bindVue(config);
    }   
    
    bindVue(config) {
		return new Vue({
			delimiters: ['${', '}'],
			el: '#giftcard-form',
			data: {
                cardType: '',
                deliveryMethod: '',
                cardAmount: '25',
                cardAmountOther: '5',
                processingFee: config.processingFee,
                sameAsBilling: false
			},
			computed: {
                total: function() {
                    return parseFloat(this.cardAmount == 'other' ? this.cardAmountOther : this.cardAmount)
                },
                grandTotal: function() {
                    return this.total + this.processingFee;
                }
			},
			methods: {
                bindPayment() {
                    /**
                     * Braintree Integration Details
                     *
                     * @see https://developers.braintreepayments.com/guides/drop-in/setup-and-integration/javascript/v3
                     */
                    var client_token = document.head.querySelector(
                            'meta[name="bt_client_token"]'
                        ).content,
                        dropin_config = {
                            authorization: client_token,
                            selector: '#bt-dropin',
                            paypal: {
                                flow: 'vault' // Clarify difference between 'vault' and 'checkout' workflows
                            },
                            venmo: {
                                allowNewBrowserTab: false // review this functionality...
                            }
                        },
                        _this = this;
            
                    self.braintree.dropin.create(dropin_config, function(
                        createErr,
                        instance
                    ) {
                        if (createErr) {
                            // console.log('Create Error', createErr);
                            return;
                        }

            
                        var giftcardForm = $('#giftcard-form'),
                            orderButton = $('#order-submit'),
                            brainTree = $('.bt-dropin-wrapper');


                        if (instance.isPaymentMethodRequestable()) {
                            // This will be true if you generated the client token
                            // with a customer ID and there is a saved payment method
                            // available to tokenize with that customer.
                            orderButton.removeAttr("disabled");
                        }
                        instance.on('paymentMethodRequestable', function (event) {
                            console.log(event.type); // The type of Payment Method, e.g 'CreditCard', 'PayPalAccount'.
                            console.log(event.paymentMethodIsSelected); // true if a customer has selected a payment method when paymentMethodRequestable fires

                            orderButton.removeAttr("disabled");
                        });
                        instance.on('noPaymentMethodRequestable', function () {
                            orderButton.attr('disabled', 'disabled');
                        });
            
                        orderButton.on('click', function(event) {
                            _this.submitting = true;
                            event.preventDefault();

                            if (giftcardForm[0].reportValidity()) {
                                instance.requestPaymentMethod(function(
                                    err,
                                    payload
                                ) {
                                    if (err) {
                                        // console.log('Request Payment Method Error', err);
                                        _this.submitting = false;
                                        return;
                                    }
                                    document.querySelector('#nonce').value =
                                        payload.nonce;
                
                                    brainTree.hide();
                                    orderButton.hide();
                                    $('#submitting-order').show();
                
                                    giftcardForm.submit();
                                });
                            }
                            _this.submitting = false;
                        });
                    });
                },
                isNumber: function(evt) {
                    evt = (evt) ? evt : window.event;
                    var charCode = (evt.which) ? evt.which : evt.keyCode;
                    if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                      evt.preventDefault();;
                    } else {
                      return true;
                    }
                  }
			},
			created: function() {
                this.bindPayment();
			},
			mounted: function() {
				
			}
		});
	}
}
window.app.add('giftcards', new giftCardHelper());