class lightboxHelper{
	constructor() {
	}
	
	initLightbox() {
		$(document).on('click', '[data-toggle="lightbox"]', function(event) {
			event.preventDefault();
			$(this).ekkoLightbox({
				keyboard: true,
				alwaysShowClose: true,
				onNavigate: function(direction, itemIndex) {
					// console.log('Navigating '+direction+'. Current item: '+itemIndex);
				}
			});
		});
	}
}
window.app.add('lightbox', new lightboxHelper());