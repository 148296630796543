class commonHelper {
	constructor() {
		this.toggleSlideOut();
		this.detectMissingImage();
	}

	detectMissingImage() {
		var image = $('.plant-image-wrap, .card-top.file-name');

		image.each(function() {
			var $this = $(this),
				imageURL = $(this).data('imageUrl');

			$.ajax({
				url: '/assets/plants/' + imageURL,
				type: 'HEAD',
				error() {
					// console.log("Image not detected.");
					console.log('Image Coming Soon.');
					$this.css(
						'background-image',
						'url(/assets/images/natorps_missing_image@2x.jpg)'
					);
				},
			});
		});
	}

	toggleSlideOut() {
		$(document).on('vue-loaded', function () {
			$('.menu-nav a, #menu-toggle').on('click', function(e) {
				e.preventDefault();
				$('html, body').addClass('ov-hidden');
				$('.slide-out-menu')
					.addClass('slide')
					.addClass('in')
					.addClass('slide-complete');
			});
			$('.slide-out-menu .close-menu').on('click', function(e) {
				e.preventDefault();
				$('html, body').removeClass('ov-hidden');
				$('.slide-out-menu')
					.removeClass('in')
					.removeClass('slide')
					.removeClass('slide-complete');
			});
		});
		$('.menu-nav a, #menu-toggle').on('click', function(e) {
			e.preventDefault();
			$('html, body').addClass('ov-hidden');
			$('.slide-out-menu')
				.addClass('slide')
				.addClass('in')
				.addClass('slide-complete');
		});
		$('.slide-out-menu .close-menu').on('click', function(e) {
			e.preventDefault();
			$('html, body').removeClass('ov-hidden');
			$('.slide-out-menu')
				.removeClass('in')
				.removeClass('slide')
				.removeClass('slide-complete');
		});

		$(document).on('click touchmove touchstart', function(e) {
			var flyOutOverlay = $('.slide-overlay');

			if (flyOutOverlay.is(e.target)) {
				e.preventDefault();
				$('html, body').removeClass('ov-hidden');
				$('.slide-out-menu')
					.removeClass('in')
					.removeClass('slide')
					.removeClass('slide-complete');
			}
		});
	}

	goBack() {
		var backBtn = $('.plant-detail-wrap a.back-button');

		backBtn.on('click', function(e) {
			e.preventDefault();
			window.history.back();
		});
	}

	loadMorePlants() {
		var loadMore = $('.load-more-btn');
		var data = {};
		var currState = [];
		var plantIndex = $('#plantIndex');
		var plantsWrap = $('.plants-wrap');
		var pageCount = plantIndex.data('pagecount');
		var currentPage = plantIndex.data('currentpage');
		var state = plantIndex.data('state');

		loadMore.on('click', function(e) {
			e.preventDefault();

			// Append a div indicating more plants have been loaded, then focus it for screen readers. If it already exists, remove it first.
			if ($('#more-loaded-notification')[0]) {
				$('#more-loaded-notification').remove();
				plantsWrap.append(
					'<div id="more-loaded-notification" class="col-12 m-b-1-xs-up"><span>More Plants Loaded</span></div>'
				);
				$('#more-loaded-notification')
					.attr('tabindex', -1)
					.focus();
			} else {
				plantsWrap.append(
					'<div id="more-loaded-notification" class="col-12 m-b-1-xs-up"><span>More Plants Loaded</span></div>'
				);
				$('#more-loaded-notification')
					.attr('tabindex', -1)
					.focus();
			}

			btn = $(this);
			default_value = btn.text();
			app.common.toggleButtonLoading(btn, true, 'Loading...', 2);
			setTimeout(function() {
				app.common.toggleButtonLoading(btn, false, default_value);
			}, 1000);

			$.ajaxSetup({
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr(
						'content'
					),
				},
			});

			currentPage++;
			data = state;
			data.page = currentPage;

			if (currentPage > pageCount) {
				btn.hide();
			} else {
				$.post('/plants', data, function(response) {
					plantsWrap.append(response);

					var url = window.app.common.getPlantStateUrl(currentPage);
					window.history.pushState(
						{
							currentPage: currentPage,
						},
						'Plant Search',
						url
					);

					window.app.common.detectMissingImage();
					setTimeout(function() {
						window.app.equal.sameHeight('#plantIndex');
					}, 200);
				});
			}
			if (currentPage >= pageCount) {
				btn.hide();
			}
		});
	}

	getPlantStateUrl(page) {
		var url = window.location.href;
		if (url.indexOf('page=') > -1) {
			url = url.replace(/page=[0-9]*/, 'page=' + page);
		} else {
			var queryString =
				(url.indexOf('?') > -1 ? '&page=' : '&page=') + page;
			url = url + queryString;
		}
		return url;
	}

	videoKill() {
		if ($('.modal-video')[0]) {
			$('.modal-video').each(function() {
				var modal = $(this);
				var btn = $(this).find('button.close')[0];
				$(btn).data('dismiss', false);
				var tar = $(this).find('.modal-body')[0];
				$(btn).click(function(e) {
					e.preventDefault();
					var html = $(tar).html();
					$(tar).html(html);
					modal.modal('hide');
				});
			});
		}
	}

	sameHeight(el) {
		var b_tar = $(el).attr('data-target');
		var limit = $(el).attr('data-limit');
		if (limit !== null && window.innerWidth < limit) {
			return;
		}
		var tar = [];
		if (b_tar.indexOf(', ') > -1) {
			tar = b_tar.split(', ');
		} else {
			tar.push(b_tar);
		}
		tar.forEach(function(item) {
			var kids = $(el).find(item);
			var minH = 0;
			kids.each(function() {
				minH =
					minH < $(this).outerHeight() ? $(this).outerHeight() : minH;
			});
		});
	}

	showFixedHeader() {
		$(document).scroll(function() {
			if ($(window).width() > 992) {
				var y = $(this).scrollTop();
				if (y > 350) {
					$('.navbar-fixed').fadeIn();
				} else {
					$('.navbar-fixed').fadeOut();
				}
			}
		});
	}

	showFixedFooter() {
		var footerCookie;
		$('footer.home').css('padding-bottom', '70px');
		$(document).scroll(function() {
			if ($(window).width() > 992) {
				if ($('body').hasClass('home')) {
					footerCookie = Cookies.get('closed-footer');
					if (footerCookie != 'true') {
						$('footer.home').css('padding-bottom', '70px');
						var y = $(this).scrollTop();
						if (y > 1500) {
							$('.fixed-footer').fadeIn();
						} else {
							$('.fixed-footer').fadeOut();
						}
					}
				}
			}
		});
	}

	removeFixedFooter() {
		$('.fixed-footer .exit').on('click', function() {
			Cookies.set('closed-footer', 'true', { expires: 7 });
			$('.fixed-footer').fadeOut();
			// $('footer.home').css('padding-bottom', '0px');
		});
	}

	scrollBackToTop() {
		$('.back-top-anchor').on('click', function(event) {
			event.preventDefault();
			$('body,html').animate(
				{
					scrollTop: 0,
				},
				700
			);
		});
	}

	animateHamburger() {
		$('.hamburger').on('click', function() {
			$(this).toggleClass('is-active');
		});
	}

	pageScroll() {
		if (window.location.pathname.indexOf('/page/') > -1) {
			$('html, body').animate({
				scrollTop: $('.shows-intro-copy').offset().top - 75,
			});
		}
	}

	plantDetail() {
		$('.plant-detail-panel .detail:even').addClass('even');
		$('.plant-detail-panel .detail:last').addClass('last');
	}

	addVisibleClassOnScroll(element) {
		window.onscroll = window._.debounce(function(e) {
			const { y } = element.getBoundingClientRect();
			y > 0 && y < window.innerHeight
				? element.classList.add('scrolled-into-view')
				: element.classList.remove('scrolled-into-view');
		}, 200);
	}

	toggleButtonLoading($btn, state, label, spinner) {
		spinner = typeof spinner !== 'undefined' ? spinner : 2;
		label = label || 'Please wait...';
		var spinner_class = '';
		var spinner_text = '';
		if (spinner == 1) spinner_class = 'fa-spinner fa-spin';
		if (spinner == 2) spinner_class = 'fa-refresh fa-spin';
		if (spinner == 3) spinner_class = 'fa-cog fa-spin';
		if (spinner == 4) spinner_class = 'fa-circle-o-notch fa-spin';
		if (spinner == 5) spinner_class = 'fa-spinner fa-pulse';
		if (spinner == 6) spinner_class = 'fa-refresh fa-pulse';
		if (spinner == 7) spinner_class = 'fa-cog fa-pulse';
		if (spinner !== false)
			spinner_text =
				'<i class="fa ' + spinner_class + ' "></i>&nbsp;&nbsp;&nbsp;';

		if (state) {
			$btn.data('defaultStateText', $btn.text())
				.html(spinner_text + label)
				.prop('disabled', true);
		} else {
			$btn.prop('disabled', false).html(label);
		}
	}

	displayUploadFileValue() {
		$('.upload-wrapper .custom-file-input').on('change', function() {
			$(this)
				.siblings('.form-control-file')
				.html($(this).val());
		});
	}

	initDatePicker() {
		jQuery('.input-group.date').datepicker({
			orientation: 'auto left',
			todayHighlight: true,
			autoclose: true,
			startDate: '0',
			defaultViewDate: '+1d',
			daysOfWeekDisabled: [0, 6],
		});
		jQuery('.input-group.date').on('click', function() {
			jQuery('#requested_date').focus();
		});
	}
}
window.app.add('common', new commonHelper());
