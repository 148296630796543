<template>
  <svg
    id="natorps_icons_plants"
    class="natorps_icons_plants fillable"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 43.81 43.81"
    width="45"
    height="45"
    :fill="fill"
  >
    <rect class="cls-1" x="-688.75" y="-1097.17" width="1440" height="2495" />
    <rect class="cls-1" x="-749.12" y="-1200.59" width="1440" height="2495" />
    <rect class="cls-1" x="-749.12" y="-1200.59" width="1440" height="2495" />
    <g>
      <path
        class="cls-2"
        d="m11.34,38.18h-5.71V5.63h32.55v3.04c-.2-.14-.39-.33-.61-.4-.99-.32-2.02-.46-3.06-.44-1.77.04-3.29.63-4.39,2.08-.84,1.1-1.33,2.35-1.58,3.7-.12.64-.33,1.23-.66,1.79-.25-.54-.53-1.05-.62-1.66-.13-.92-.45-1.8-.86-2.64-.85-1.72-2.15-2.86-4.08-3.17-1.39-.22-2.76-.06-4.1.33-.54.16-.69.36-.57.9.18.82.36,1.65.63,2.45.46,1.32,1.2,2.48,2.41,3.25,1.27.81,2.69.96,4.15.81.61-.06,1.22-.2,1.85-.3.47,1.22.74,2.49.63,3.84-.14,0-.27,0-.39,0-2.33,0-4.65,0-6.98,0-.89,0-1.35.52-1.23,1.4.15,1.12.29,2.24.44,3.36.1.75.35,1.05,1.04,1.2.02,0,.03.03.06.05.13,1.03.27,2.08.41,3.13.37,2.86.74,5.72,1.1,8.58.07.59.31,1.02.88,1.23h-10.66c.97-.27,1.63-.93,2.21-1.71.21-.28.4-.57.58-.87,1.24-2.12,1.99-4.42,2.48-6.81.3-1.48.51-2.98.26-4.5-.32-1.93-1.4-3.19-3.32-3.69-.29-.08-.59-.14-.9-.21,0-.71,0-1.41,0-2.1,0-.09.06-.2.13-.26.67-.59.95-1.35.95-2.23,0-2.41,0-4.82,0-7.23,0-.25-.02-.51-.07-.75-.31-1.34-1.53-2.23-2.88-2.11-1.38.13-2.45,1.27-2.46,2.69-.02,2.53-.01,5.05,0,7.58,0,.81.32,1.5.93,2.04.06.06.14.15.14.23.01.72,0,1.43,0,2.17-.25.05-.47.09-.7.14-1.67.39-2.87,1.32-3.34,3.02-.17.59-.25,1.22-.27,1.84-.05,1.65.27,3.26.69,4.85.54,2.03,1.25,3.99,2.4,5.76.63.97,1.36,1.84,2.54,2.17Z"
      />
      <path
        d="m22.63,38.18c-.57-.21-.8-.64-.88-1.23-.35-2.86-.73-5.72-1.1-8.58-.13-1.05-.27-2.09-.41-3.13-.03-.03-.04-.05-.06-.05-.69-.15-.95-.45-1.04-1.2-.14-1.12-.29-2.24-.44-3.36-.12-.88.34-1.4,1.23-1.4,2.33,0,4.65,0,6.98,0,.12,0,.25,0,.39,0,.11-1.36-.16-2.62-.63-3.84-.63.1-1.24.24-1.85.3-1.46.15-2.88,0-4.15-.81-1.21-.77-1.95-1.93-2.41-3.25-.28-.79-.45-1.62-.63-2.45-.12-.55.03-.74.57-.9,1.34-.4,2.71-.56,4.1-.33,1.93.31,3.23,1.46,4.08,3.17.41.84.73,1.71.86,2.64.09.61.37,1.12.62,1.66.33-.56.54-1.15.66-1.79.25-1.34.74-2.6,1.58-3.7,1.1-1.45,2.61-2.04,4.39-2.08,1.04-.02,2.07.12,3.06.44.22.07.41.26.61.4v.44c-.17.69-.3,1.38-.51,2.05-.4,1.28-1.01,2.44-2.06,3.32-1.36,1.13-2.97,1.35-4.66,1.19-.61-.06-1.22-.2-1.85-.3-.49,1.22-.72,2.49-.64,3.84.93,0,1.83,0,2.74,0,1.58,0,3.15,0,4.73,0,.76,0,1.25.54,1.16,1.26-.14,1.16-.32,2.32-.45,3.49-.08.68-.38,1.13-1.1,1.22-.2,1.52-.39,3.03-.59,4.54-.31,2.4-.63,4.8-.92,7.2-.07.59-.3,1.01-.86,1.24h-10.53Zm-1.29-12.98c.51,3.98,1.02,7.93,1.53,11.88h10.03c.51-3.97,1.02-7.92,1.53-11.88h-13.1Zm14.67-4.87h-16.24c.17,1.27.33,2.51.49,3.75h15.26c.16-1.25.32-2.5.49-3.75Zm-17.01-10.25s-.05.03-.07.04c.38,1.43.9,2.79,2.17,3.7,1.34.97,2.86.93,4.49.66-2.24-1.5-4.41-2.95-6.58-4.41Zm17.25-.95c-1.58-.32-3.11-.36-4.46.64-1.24.92-1.75,2.27-2.15,3.78,2.24-1.5,4.42-2.96,6.6-4.42Zm.58.91c-2.21,1.48-4.38,2.94-6.59,4.42,3.32.71,5.81-.58,6.59-4.42Zm-17.35-.94c2.21,1.48,4.39,2.94,6.61,4.43-.88-3.75-3.09-5.14-6.61-4.43Z"
      />
      <path
        class="cls-2"
        d="m33.17,38.18c.55-.23.79-.65.86-1.24.29-2.4.61-4.8.92-7.2.2-1.51.39-3.02.59-4.54.72-.09,1.02-.54,1.1-1.22.13-1.16.31-2.33.45-3.49.09-.72-.4-1.26-1.16-1.26-1.58,0-3.15,0-4.73,0-.91,0-1.82,0-2.74,0-.08-1.35.15-2.62.64-3.84.63.1,1.23.24,1.85.3,1.69.16,3.3-.05,4.66-1.19,1.05-.88,1.66-2.04,2.06-3.32.21-.67.34-1.37.51-2.05v29.06h-5.01Z"
      />
      <path
        d="m11.34,38.18c-1.18-.33-1.91-1.2-2.54-2.17-1.15-1.77-1.86-3.74-2.4-5.76-.42-1.59-.75-3.19-.69-4.85.02-.62.1-1.25.27-1.84.47-1.69,1.67-2.63,3.34-3.02.22-.05.45-.09.7-.14,0-.73,0-1.45,0-2.17,0-.08-.07-.17-.14-.23-.61-.54-.93-1.23-.93-2.04-.01-2.53-.02-5.05,0-7.58.01-1.42,1.08-2.56,2.46-2.69,1.34-.13,2.57.77,2.88,2.11.06.24.07.5.07.75,0,2.41,0,4.82,0,7.23,0,.88-.28,1.63-.95,2.23-.07.06-.13.17-.13.26-.01.7,0,1.39,0,2.1.31.07.61.13.9.21,1.92.5,3,1.75,3.32,3.69.25,1.52.05,3.02-.26,4.5-.49,2.39-1.24,4.69-2.48,6.81-.18.3-.37.59-.58.87-.57.78-1.24,1.44-2.21,1.71h-.63Zm-1.79-16.54c-1.09.14-2.05.9-2.45,1.97-.33.89-.37,1.82-.29,2.75.21,2.6.94,5.06,2.01,7.42.44.96.94,1.9,1.68,2.67.82.85,1.5.87,2.29,0,.44-.49.84-1.04,1.15-1.62,1.38-2.52,2.2-5.23,2.53-8.08.11-.97.11-1.94-.17-2.89-.36-1.21-1.47-2.16-2.57-2.21-.12.74-.19,1.49-.36,2.22-.18.76-.42,1.5-.7,2.23-.16.43-.5.73-1.02.73-.5,0-.8-.31-.99-.72-.2-.43-.39-.87-.5-1.33-.23-1.03-.4-2.07-.6-3.13Zm3.72-9.45c0-1.21,0-2.41,0-3.62,0-.21-.01-.43-.07-.63-.21-.77-.97-1.27-1.74-1.17-.82.11-1.42.77-1.44,1.63-.01.68,0,1.35,0,2.03,0,1.82,0,3.64,0,5.45,0,1.11.9,1.87,1.94,1.67.79-.15,1.3-.79,1.31-1.67,0-1.24,0-2.47,0-3.71Zm-2.6,9.23c.2,1.47.32,2.89.98,4.21.66-1.32.79-2.75.98-4.21h-1.96Zm.46-2.76v1.63h1.03v-1.63h-1.03Z"
      />
      <path
        class="cls-2"
        d="m21.34,25.2h13.1c-.51,3.97-1.02,7.92-1.53,11.88h-10.03c-.51-3.95-1.02-7.91-1.53-11.88Z"
      />
      <path
        class="cls-2"
        d="m36.01,20.33c-.16,1.26-.32,2.5-.49,3.75h-15.26c-.16-1.25-.32-2.49-.49-3.75h16.24Zm-9.47,2.15c.59,0,1.18,0,1.77,0,.42,0,.67-.22.66-.56,0-.33-.25-.53-.66-.53-1.18,0-2.36,0-3.55,0-.42,0-.67.22-.66.56,0,.32.25.52.66.53.59,0,1.18,0,1.77,0Zm-4.59-1.07s0,0,0,0c-.21,0-.42-.02-.63,0-.28.03-.45.26-.45.52,0,.27.18.5.45.52.41.03.82.03,1.23,0,.29-.02.48-.26.47-.54-.02-.29-.18-.48-.47-.51-.2-.02-.4,0-.6,0Z"
      />
      <path
        class="cls-2"
        d="m19,10.07c2.17,1.45,4.34,2.91,6.58,4.41-1.63.27-3.14.31-4.49-.66-1.26-.92-1.79-2.27-2.17-3.7.02-.01.05-.03.07-.04Z"
      />
      <path
        class="cls-2"
        d="m36.26,9.12c-2.18,1.46-4.36,2.92-6.6,4.42.39-1.5.91-2.86,2.15-3.78,1.35-1,2.88-.96,4.46-.64Z"
      />
      <path
        class="cls-2"
        d="m36.84,10.04c-.79,3.84-3.28,5.13-6.59,4.42,2.21-1.48,4.39-2.94,6.59-4.42Z"
      />
      <path
        class="cls-2"
        d="m19.49,9.1c3.53-.71,5.74.68,6.61,4.43-2.22-1.49-4.4-2.95-6.61-4.43Z"
      />
      <path
        class="cls-2"
        d="m9.55,21.63c.2,1.06.37,2.1.6,3.13.1.46.3.9.5,1.33.19.41.49.71.99.72.52,0,.85-.3,1.02-.73.28-.73.52-1.47.7-2.23.17-.73.24-1.48.36-2.22,1.11.05,2.21,1,2.57,2.21.28.95.29,1.92.17,2.89-.33,2.85-1.15,5.56-2.53,8.08-.32.58-.71,1.13-1.15,1.62-.79.87-1.47.85-2.29,0-.74-.77-1.24-1.71-1.68-2.67-1.07-2.36-1.8-4.83-2.01-7.42-.08-.93-.04-1.86.29-2.75.4-1.07,1.36-1.83,2.45-1.97Z"
      />
      <path
        class="cls-2"
        d="m13.27,12.19c0,1.24,0,2.47,0,3.71,0,.87-.52,1.51-1.31,1.67-1.04.2-1.93-.56-1.94-1.67,0-1.82,0-3.64,0-5.45,0-.68-.01-1.35,0-2.03.02-.86.61-1.52,1.44-1.63.77-.1,1.53.4,1.74,1.17.06.2.07.42.07.63,0,1.21,0,2.41,0,3.62Zm-1.09-3.78c0-.3-.23-.55-.53-.55-.29,0-.55.25-.54.55,0,.28.24.52.52.53.31,0,.54-.22.55-.52Z"
      />
      <path
        class="cls-2"
        d="m10.67,21.42h1.96c-.19,1.45-.32,2.88-.98,4.21-.66-1.31-.78-2.74-.98-4.21Z"
      />
      <path class="cls-2" d="m11.13,18.67h1.03v1.63h-1.03v-1.63Z" />
      <path
        d="m26.54,22.48c-.59,0-1.18,0-1.77,0-.41,0-.65-.2-.66-.53,0-.33.24-.55.66-.56,1.18,0,2.36,0,3.55,0,.41,0,.65.2.66.53,0,.34-.24.55-.66.56-.59,0-1.18,0-1.77,0Z"
      />
      <path
        d="m21.94,21.4c.2,0,.4-.02.6,0,.29.03.45.22.47.51.02.28-.17.52-.47.54-.41.03-.82.02-1.23,0-.28-.02-.45-.25-.45-.52,0-.27.18-.49.45-.52.21-.02.42,0,.63,0,0,0,0,0,0,0Z"
      />
      <path
        d="m12.18,8.4c0,.31-.24.53-.55.52-.29,0-.52-.24-.52-.53,0-.29.25-.55.54-.55.3,0,.53.25.53.55Z"
      />
    </g>
    <rect class="cls-1" x="5.63" y="5.63" width="32.55" height="32.55" />
    <rect class="cls-1" x="-709.61" y="-1222.24" width="1440" height="2495" />
    <rect class="cls-1" x="-709.61" y="-1222.24" width="1440" height="2495" />
    <rect class="cls-1" x="-691.37" y="-1242.2" width="1440" height="2495" />
    <rect class="cls-1" x="-691.37" y="-1242.2" width="1440" height="2495" />
  </svg>
</template>
    
    <script>
export default {
  name: "natorps_icons_plants_svg_component",
  props: ["fill"],
};
</script>
    
<style scoped>
#natorps_icons_plants {
  margin-bottom: 10px;
}
.natorps_icons_plants .cls-1 {
  fill: none;
}
.natorps_icons_plants .cls-2 {
  fill: #fff;
}
</style>