<template>
    <!-- Checkout-link - Handle Minimum Order Restrictions -->
    <div class="checkout" v-if="cart_total_exceeds_minimum">
        <a href="/setupCheckout" class="btn btn-primary btn-block" v-bind:class="[cart_count > 0 ? '' : 'disabled']">Continue to checkout</a>
    </div>
    <div class="checkout" v-else>
        <button class="btn btn-primary btn-block" disabled>Minimum Order Amount {{ min_order_amount }}</button>
    </div><!-- /checkout-link -->
</template>
<style lang="scss" scoped>
</style>
<script>
export default {
    props: {
        cart_total_exceeds_minimum: false,
        cart_count: 0,
        min_order_amount: 0
    },
    data() {
        return {}
    },
    computed: {},
    methods: {},
}
</script>