<template>
  <svg
    id="natorps_icons_truck"
    class="natorps_icons_truck fillable"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 43.81 43.81"
    width="45"
    height="45"
    :fill="fill"
  >
    <rect class="cls-1" x="-628.33" y="-1097.17" width="1440" height="2495" />
    <g>
      <path
        class="cls-2"
        d="m.59,10.02v-1.83h1.38v-1.41H.59V.63c14.21,0,28.42,0,42.63,0v42.63c-14.21,0-28.42,0-42.63,0,0-3.35,0-6.7,0-10.06.23.39.57.5,1.01.5,1.56-.02,3.13,0,4.69,0,.58,2.1,1.85,3.43,4.06,3.5,2.39.08,3.78-1.27,4.41-3.49.07,0,.13-.01.18-.01,5.05,0,10.11,0,15.16,0,.29,0,.36.12.42.37.48,1.83,2.06,3.08,3.96,3.16,1.83.08,3.52-1.06,4.15-2.8.08-.23.14-.47.22-.73,1.11,0,2.2,0,3.29,0,.77,0,1-.22,1-.98,0-2.42,0-4.85-.01-7.27,0-.3-.1-.63-.26-.88-2.01-3.15-4.04-6.29-6.04-9.44-.27-.42-.59-.6-1.09-.59-2.09.02-4.18,0-6.27,0-.16,0-.32-.02-.51-.03,0-1.35,0-2.65,0-3.94,0-.78-.2-.98-.98-.98-8.79,0-17.59,0-26.38,0-.42,0-.75.1-.99.44Zm12.77-3.24H3.45v1.38c.19,0,.36,0,.52,0,2.95,0,5.9,0,8.84,0,.1,0,.21.03.29,0,.1-.05.24-.15.25-.23.03-.37.01-.74.01-1.14Z"
      />
      <path
        d="m.59,10.02c.24-.35.57-.44.99-.44,8.79,0,17.59,0,26.38,0,.79,0,.98.19.98.98,0,1.3,0,2.6,0,3.94.19.01.35.03.51.03,2.09,0,4.18.01,6.27,0,.5,0,.82.17,1.09.59,2.01,3.15,4.04,6.29,6.04,9.44.16.25.26.59.26.88.02,2.42.01,4.85.01,7.27,0,.76-.23.98-1,.98-1.09,0-2.19,0-3.29,0-.08.26-.14.49-.22.73-.63,1.74-2.31,2.88-4.15,2.8-1.9-.08-3.48-1.34-3.96-3.16-.06-.25-.13-.37-.42-.37-5.05,0-10.11,0-15.16,0-.05,0-.11,0-.18.01-.63,2.22-2.02,3.57-4.41,3.49-2.21-.08-3.48-1.4-4.06-3.5-1.56,0-3.12-.01-4.69,0-.44,0-.88-.11-1.01-.5s0-15.46,0-23.19Zm14.15,22.22h12.75v-1.4h-11.99v-1.41h12v-15.6H3.44v-1.41h24.06v-1.38H2.04v18.42h3.49v1.41h-3.49v1.39h4.25c.82-2.37,2.21-3.53,4.22-3.53,2.01,0,3.38,1.14,4.23,3.52Zm15.66,0c.85-2.35,2.13-3.5,4.06-3.51,2.38-.01,3.78,1.29,4.4,3.51h2.83v-1.42h-2.08v-1.41h2.11c0-1.26,0-2.46,0-3.66,0-.57-.02-.58-.59-.58-3.27,0-6.54,0-9.8,0-.74,0-.96-.22-.96-.97,0-1.95,0-3.91,0-5.86,0-.74.22-.96.97-.96,1.56,0,3.13,0,4.69,0,.15,0,.29,0,.53,0-.27-.43-.51-.76-.7-1.12-.13-.24-.3-.31-.57-.31-1.97.01-3.93,0-5.9,0-.12,0-.24.01-.39.02v16.27h1.4Zm10.26-8.52c-1.05-1.63-2.04-3.19-3.06-4.74-.07-.11-.27-.19-.41-.19-1.67-.01-3.35,0-5.02,0-.12,0-.23.02-.36.03v4.9h8.84Zm-6.04,12.07c1.56,0,2.84-1.25,2.85-2.81.01-1.54-1.26-2.84-2.81-2.86-1.57-.02-2.87,1.27-2.86,2.84,0,1.56,1.26,2.82,2.82,2.83Zm-24.11,0c1.56,0,2.83-1.24,2.85-2.81.02-1.56-1.28-2.87-2.85-2.86-1.55,0-2.83,1.29-2.83,2.84,0,1.55,1.27,2.83,2.83,2.83Z"
      />
      <path d="m.59,6.78h1.38v1.41H.59v-1.41Z" />
      <path
        d="m13.36,6.78c0,.4,0,1.38,0,1.38,0,0-6.45,0-9.39,0-.16,0-.33,0-.52,0v-1.38h9.91Z"
      />
      <path
        class="cls-2"
        d="m14.74,32.24c-.84-2.38-2.22-3.52-4.23-3.52-2.01,0-3.4,1.16-4.22,3.53H2.04v-1.39h3.49v-1.41h-3.49V11.03h25.46v1.38H3.44v1.41h24.06v15.6h-12v1.41h11.99v1.4h-12.75Z"
      />
      <path
        class="cls-2"
        d="m30.4,32.25h-1.4V15.98c.15,0,.27-.02.39-.02,1.97,0,3.93,0,5.9,0,.27,0,.43.07.57.31.2.36.43.69.7,1.12-.24,0-.38,0-.53,0-1.56,0-3.13,0-4.69,0-.75,0-.97.22-.97.96,0,1.95,0,3.91,0,5.86,0,.75.22.97.96.97,3.27,0,6.54,0,9.8,0,.57,0,.59.02.59.58,0,1.2,0,2.41,0,3.66h-2.11v1.41h2.08v1.42h-2.83c-.62-2.22-2.02-3.52-4.4-3.51-1.93,0-3.21,1.16-4.06,3.51Z"
      />
      <path
        class="cls-2"
        d="m40.66,23.73h-8.84v-4.9c.12-.01.24-.03.36-.03,1.67,0,3.35,0,5.02,0,.14,0,.34.08.41.19,1.01,1.55,2.01,3.11,3.06,4.74Z"
      />
      <path
        class="cls-2"
        d="m34.62,35.8c-1.56,0-2.82-1.27-2.82-2.83,0-1.57,1.3-2.86,2.86-2.84,1.55.02,2.83,1.32,2.81,2.86-.02,1.56-1.29,2.82-2.85,2.81Zm-.68-2.14h1.37v-1.36h-1.37v1.36Z"
      />
      <path
        class="cls-2"
        d="m10.51,35.8c-1.56,0-2.83-1.28-2.83-2.83,0-1.55,1.28-2.83,2.83-2.84,1.57,0,2.87,1.3,2.85,2.86-.02,1.56-1.29,2.81-2.85,2.81Zm.7-2.13v-1.37h-1.36v1.37h1.36Z"
      />
      <path d="m33.94,33.67v-1.36h1.37v1.36h-1.37Z" />
      <path d="m11.2,33.67h-1.36v-1.37h1.36v1.37Z" />
    </g>
    <rect class="cls-1" x=".59" y="1.95" width="42.63" height="42.63" />
    <rect class="cls-1" x="-688.69" y="-1200.59" width="1440" height="2495" />
    <rect class="cls-1" x="-688.69" y="-1200.59" width="1440" height="2495" />
    <rect class="cls-1" x="-649.18" y="-1222.24" width="1440" height="2495" />
    <rect class="cls-1" x="-649.18" y="-1222.24" width="1440" height="2495" />
    <rect class="cls-1" x="-630.95" y="-1242.2" width="1440" height="2495" />
    <rect class="cls-1" x="-630.95" y="-1242.2" width="1440" height="2495" />
  </svg>
</template>
      
      <script>
export default {
  name: "natorps_icons_truck_svg_component",
  props: ["fill"],
};
</script>
      
<style scoped>
#natorps_icons_truck {
  margin-bottom: 10px;
}

.natorps_icons_truck .cls-1 {
  fill: none;
}
.natorps_icons_truck .cls-2 {
  fill: #fff;
}
</style>