class animateHelper {
	constructor() {
		this.end = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
	}

	init() {
	}

	transition(target, animation, phase, endClass) {
		$(target).addClass([animation, endClass]).one(this.end, () => {
			$(target).removeClass(phase == 'in' ? animation : [animation, endClass])
		})
	}
}

window.app.add('animate', new animateHelper());