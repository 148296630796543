
class displayHelper {
    constructor(){

	}
	
	toggle(el) {
		var toggles = $(el).find("[app-target]");
		var active = $(el).find("[app-active]").attr("app-target");
		hideAll();
		if (active !== null) {
			$(active).show();
		}

		toggles.each(function(){
			$(this).click(function(e){
				var show = $(this).attr("app-target");
				e.preventDefault();
				if ($(show).css('display') == 'none') {
					hideAll();
					$(show).show();
				} else {
					$(show).hide();
				}
			});
		});

		function hideAll() {
			toggles.each(function(){
				var tar = $(this).attr("app-target");
				$(tar).hide();
			});
		}
	}

	filter(el) {
		var button = $(el);
		var filter = $(el).attr("app-data");
		var targetContainer = $(el).attr("app-target");
		var filterables = $(targetContainer).find("[app-data]");

		button.click(function(e){
			e.preventDefault();
			if( filter == 'all') {
				$(this).parent('li').parent('ul').find('button').each(function(){
					$(this).removeClass('active');
				});
				filterables.each(function(){
					if( !$(this).hasClass("no-border") ){ $(this).show(); }
				});
			} else if($(this).hasClass('active')) {
				$(this).removeClass('active');
				filterables.each(function(){
					if( !$(this).hasClass("no-border") ){ $(this).show(); }
				});
			} else {
				$(this).parent('li').parent('ul').find('button').each(function(){
					$(this).removeClass('active');
				});
				$(this).addClass('active');
				filterables.each(function(){
					$(this).show();
					if( $(this).attr("app-data") != filter || $(this).hasClass('no-border') ) {
						$(this).hide();
					}
				});
			}
		});
	}
}

window.app.add('display', new displayHelper());