<template>
    <table class="table table-order">
        <thead>
            <tr>
                <th v-for="column in columns" v-bind:key="column">{{ column }}</th>
            </tr>
        </thead>
        <order-table-group-component 
            v-for="(productGroup, index) of productGroups"
            v-bind:key="productGroup.PlantClassID"
            :productGroup="productGroup"
            :index="index">
        </order-table-group-component>
        <tbody v-if="!productGroups.length">
            <tr>
                <td :colspan="columns.length" style="text-align:center">
                    <span class="text-muted">No products match your search</span>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<style lang="scss">
    $border-color: #dee2e6;
    $border: 2px solid $border-color;

    .table.table-order {
        // border-radius: 1rem;
        // overflow: hidden;
        box-shadow: 0px 2px 4px rgba(#000, .1);
        border-top: none;

        thead th {
            position: sticky;
            top: calc(69px + 104px);
            z-index: 2;
        }

        th, td {
            border-top: $border;
            vertical-align: middle;
        }

        tbody {
            border-left: $border;
            border-right: $border;   
            font-size: 16px;
        }
        
        thead {
            th {
                border-top: 2px solid #128851;
                background: #128851;
                color: #fff;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-weight: bold;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .table.table-order:not(.checkout-cart){
            thead {
                display: none;
            }

            tr{

                td {
                    display: flex;
                    font-size: 16px;
                    font-weight: 400;


                    &:before{
                        content: attr(data-col);
                        font-weight: 400;
                        width: 120px;
                        min-width: 120px;
                        text-transform: capitalize;
                        font-size: 16px;
                    }

                    &:first-child{
                        &:before{
                            display: none;
                        }
                    }
                }
            }

        } 
        .table.table-order.checkout-cart{
            thead {
                display: none;
            }

            tr{
                margin-bottom: 0;
                border-radius: 0;
                display: block;
                border: none;
                border-bottom: $border;

                td {
                    display: flex;
                    font-size: 16px;
                    font-weight: 400;
                    
                    &[data-col="title"]{
                        background: white;
                    }


                    &:before{
                        content: attr(data-col);
                        font-weight: 400;
                        width: 120px;
                        min-width: 120px;
                        text-transform: capitalize;
                        font-size: 16px;
                    }

                    &:first-child{
                        &:before{
                            display: none;
                        }
                    }
                }
            }

        } 
    }
</style>
<script>
export const ORDER_COLUMNS = [
    'variety', 'size', 'price 1-9', 'price 10-49', 'price 50+', 'avail', 'comments', 'qty', 'total'
];
export default {
    data() {
        return {
            columns: ORDER_COLUMNS.filter(
                item => ['total'].indexOf(item) < 0
            ),
        }
    },
    mounted() {
        // console.log(this.productGroups);
    },
    props: ['productGroups']
}

const sample = [{ 
    "PlantClassID": "00300",
    "BotanicalName": "Abies balsamea (Canaan Fir)",
    "CommonName": "Canaan Fir (Abies balsamea)",
    "PlantGroup": "Tree",
    "available_products": [] 
}];
</script>