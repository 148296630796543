<template>
    <div class="cart-empty">
        <div>
            <img src="/assets/images/missing_plant@2x.png" alt="Cart Empty Icon">
            <p class="cart-empty-text my-1" v-html="empty_text"></p>
            <a href="/categories" v-html="categories_text"></a>
        </div>
    </div>
</template>
<style lang="scss" scoped>
</style>
<script>
export default {
    props: {
        empty_text: String,
        categories_text: String,
    },
    data() {
        return {}
    },
    computed: {},
    methods: {},
}
</script>
