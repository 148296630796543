<template>
    <!--
        @Devnote this component represents a single Cart Item (SKU),
        as a row of the cart order table.
    -->
    <tr class="wholesale-cart-item">
        <td data-col="title">
            <product-title-component 
                :productName="cartItem.title_scientific"
                :productId="cartItem.id"
                :plantId="cartItem.plant_id"
                :isOnSale="isOnSale">
            </product-title-component>
        </td>
        <td 
            v-for="(column, i) of columns" 
            v-bind:key="i"
            v-bind:data-col="column"
        >
            {{ columnMap[column] }}
        </td>
        <td data-col="quantity">
            <product-quantity-component
                :productId="cartItem.id"
                :plantId="cartItem.plant_id"
                :inventory="cartItem.inventory">
            </product-quantity-component>
        </td>
        <td data-col="total" class="text-left text-md-right">
            {{ itemTotal|currency }}
        </td>
    </tr>
</template>
<style lang="scss">
    .wholesale-cart-item td {
        &[data-col="size"] {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;
        }
    }
    @media screen and (max-width: 600px) {
        tr {
            border: none !important;
            border-bottom: 1px solid #e1e1e1 !important;
        }
        .wholesale-cart-item td {
            border: none !important;
            &[data-col="title"],
            &[data-col="quantity"],
            &[data-col="total"] {
                padding: .5rem !important;
            }
        }
    }
</style>
<script>
import { ORDER_COLUMNS } from './order-table.component.vue';
export default {
    data() {
        return {
            columns: ORDER_COLUMNS.filter(
                item => ['variety', 'qty', 'total'].indexOf(item) < 0
            ),
        }
    },
    computed: {
        columnMap() {
            return {
                'variety': this.cartItem.title,
                'size': this.cartItem.size,
                'price 1-9': this.cartItem.wholesale_prices[0],
                'price 10-49': this.cartItem.wholesale_prices[1],
                'price 50+': this.cartItem.wholesale_prices[2],
                'avail': this.cartItem.inventory,
                'comments': this.cartItem.category,
                'qty': 0
            };
        },
        selectedQuantity() {
            return 0;
        },
        itemTotal(){
            return (this.cartItem.price * this.cartItem.quantity);
        },
        isOnSale(){
            return (this.cartItem.wholesale_prices[3] > 0);
        }
    },
    props: ['index', 'cartItem'],
}

// const sampleCartItem = {
//     category: "Shrub",
//     id: "803220225747",
//     image_url: "/assets/plants/Cephalotaxus%20prostrata%20nat%20tag.jpg",
//     inventory: 10,
//     plant_id: "00640",
//     price: 56.99,
//     quantity: 1,
//     size: "3gal",
//     soil_cond_qty: 0.1,
//     start_fert_qty: 0.05,
//     title: "Prostrate Japanese Plum Yew",
//     title_scientific: "Cephalotaxus h. 'Prostrata'",
//     url: "/plants/shrub/00640",
//     wholesale_prices: [
//         "56.99",
//         "53.99",
//         "47.99",
//         null,
//     ],
// };
</script>