class formHelper{
	constructor() {
		this.confirmationScroll();
	}
	
	confirmationScroll(){
		var top, buffer, scrollDest, url = document.createElement('a');
		url.href = window.location;
		if( url.hash.indexOf('gf_') > 0 ) {
			top = $(url.hash).offset().top;
			buffer = parseInt($('#main-nav').css('height'));
			scrollDest = parseInt( top - buffer - 200);
			$('body,html').animate({
				scrollTop: scrollDest,
			}, 200);
		}
	}
}
window.app.add('form', new formHelper());