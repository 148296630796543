class searchHelper {

	constructor() {
        const searchApp = this.searchApp();
        if ( searchApp ) {
            window.SEARCH_INPUT_NAV = searchApp;
            this.addClickHandlers( searchApp );
        }
    }
    
    searchApp() {
        const element = document.getElementById('search-input-nav');
        if ( ! element ) {
            // Bail if we can't mount
            return null;
        }
        return new Vue({
            el: element,
            data: {
                query: '',
                results: [],
                loading: false,
                // Indicates the user is interacting with the
                // input - we'll cue off of this to hide results
                // on _component_ blur
                inactive: true
            },
            computed: {
                haveQuery: function() {
                    return this.query.length >= 3;
                },
                haveResults: function() {
                    return this.results.length > 0;
                },
                isLoading: function() {
                    return this.loading;
                },
                getResults: function() {
                    return this.results;
                },
                emptyResults: function() {
                    return this.haveQuery && !this.haveResults && !this.isLoading;
                }
            },
            watch: {
                query: function(newQuery, oldQuery) {
                    if (newQuery.length == 0) this.loading = false;
                    this.submitQuery();
                }
            },
            methods: {
                submitQuery: _.debounce(function() {
                    var vm = this;
                    vm.results = [];
                    if (vm.query.length < 3) {
                        vm.results = [];
                        vm.loading = false;
                        return;
                    }
                    vm.loading = true;
                    this.$.get('/api/v1/search', {
                        query: vm.query
                    }, function(response) {
                        vm.results = response;
                        vm.loading = false;
                    });
                }, 500),
                activate: function() {
                    this.inactive = false;
                },
                reset: function() {
                    this.query = '';
                    this.results = [];
                    this.loading = false;   
                    
                    this.hideMobile();
                },
                hideMobile: function() {
                    document.getElementById('search-input-form').classList.remove('display');
                    document.getElementById('search-input-form-toggle').classList.remove('hide');
                }
            },
            created: function() {
                // console.log('Search Vue Created');
            }
        });
    }

    addClickHandlers( searchApp ) {
        const searchForm = document.getElementById('search-input-form');
        const searchFormInput = document.getElementById('searchBox');
        const searchFormToggle = document.getElementById('search-input-form-toggle');

        // Hide Search input results on _component_ blur
        document.addEventListener('click', function(e) {
            if ( ! searchApp.inactive && e.target.offsetParent && e.target.offsetParent.id !== 'search-input-nav' ) {
                // should hide
                searchApp.inactive = true;
                if ( window.matchMedia('(max-width: 768px)').matches ) {
                    // Hide mobile
                    searchForm.classList.remove('display');
                    searchFormToggle.classList.remove('hide');
                }
            }
        });

        searchFormToggle && searchFormToggle.addEventListener('click', function(e) {
            e.preventDefault();
            
            searchFormToggle.classList.add('hide');
            searchForm.classList.add('display');
            searchFormInput.focus();

            searchApp.inactive = false;
        });
        
    }
}
window.app.add('search', new searchHelper());