<template>
    <div class="cart-item">
        <div class="cart-item_wrapper">
            <div class="cart-item_img" :style="{ backgroundImage: 'url(' + item.image_url + ')' }"></div>
            <div class="cart-item_details">
                <div class="cart-item_title"><a :href="item.url">{{ item.title }}</a></div>
                <div class="cart-item_title-scientific" style="display: block;">{{ item.title_scientific }}</div>
            </div>
        </div>
        <button class="remove-item" aria-label="Remove Item" v-on:click="confirmWishListRemove = index"></button>
        <div class="remove-confirm" v-bind:class="[ confirmWishListRemove === index ? 'in' : '']">
            <p>Would you like to remove this item from your Wish List?</p>
            <div>
                <button class="btn btn-primary confirm" v-on:click="removeWishListItem(index)">Remove</button>
                <button class="btn btn-darker cancel" v-on:click="confirmWishListRemove = false">Cancel</button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
    props: {
        item: {},
        index: 0,
    },
    data() {
        return {
            confirmWishListRemove: false,
        }
    },
    computed: {},
    methods: {
        removeWishListItem(index) {
            this.$emit('remove-wish-list-item', index);
        }
    },
}
</script>