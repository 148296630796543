<template>
    <div class="order-form-wrapper">
        <div id="filter-container">
            <div class="container">
                <!-- <div class="filters-top text-center text-md-left d-block d-md-flex align-items-center justify-content-between"> -->
                <div class="filters-top text-center d-block">
                    <search-component class="search-container mx-auto" @searchInputChange="handleSearchInputChange"></search-component>
                    <a href="#" class="toggle-filters text-uppercase text-dark mt-1" v-on:click.stop.prevent="showFilters=!showFilters">
                        {{ showFilters ? 'Hide' : 'Show' }} Filters 
                        <span class="ml-1 d-inline-block fa" :class="{ 'fa-chevron-down': !showFilters, 'fa-chevron-up': showFilters }"></span>
                    </a>
                </div>
                <div class="filters-bottom pt-1" v-if="showFilters">
                    <filters-component @filtersChanged="handleFiltersChanged"></filters-component>
                </div>
            </div>
        </div>
        <div id="shop-container" class="container">
            <header class="d-block d-md-flex align-items-center justify-content-between">
                <h1 class="my-1 text-capitalize" v-if="!filterSelected">Current Product Availability</h1>
                <h1 class="my-1 text-capitalize" v-if="filterSelected">Current Availability for {{this.selectedFilters[0]}}</h1>
                <div class="pagination-info my-1">
                    Showing {{ paginationInfo.to }} of {{ paginationInfo.total }} Products
                </div>
            </header>
            <order-table-component :productGroups="productGroups"></order-table-component>
            <div id="reload-anchor"></div>
            <div id="loading-indicator" v-if="loading" class="loading text-center py-1" style="font-size:16px;">
                <p class="mb-0 text-muted">Loading more products <span class="fa fa-spinner fa-spin ml-1"></span></p>
            </div>
        </div>
        <div id="order-container">
            <cart-wholesale-component></cart-wholesale-component>
        </div>
        <div id="updating-overlay" v-if="updating">
            <!-- 
                @Devnote this component is hooked to the `updating` boolean
                on the state object, and can be used to indicate progress at
                any time during the order workflow...
            -->
            <span class="updating-text">
                Updating <span class="d-inline-block fa fa-spinner fa-spin"></span>
            </span>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .order-form-wrapper {
        position: relative;
        #updating-overlay {
            width: 100vw;
            position: fixed;
            top: 69.68px;
            height: calc(100vh - 69.68px);

            background-color: rgba(#fff, .25);
            color: #000;
            font-size: 1.5rem;

            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;

            > span {
                display: inline-block;
                padding: .75rem 2rem;
                border-radius: .5rem;
                background: #fff;
                box-shadow: 0px 2px 4px rgba(#000,.1);

                span {
                    margin-left: .5rem;
                }
            }
        }
    }
    #filter-container {
        position: sticky;
        top: 69.68px; // nav height
        display: block;
        width: 100%;
        box-shadow: 0px 2px 4px rgba(#000, .1);
        margin-bottom: 1rem;
        background-color: #fff;
        padding-top: 1rem;
        padding-bottom: 1rem;
        z-index: 3;

        a.toggle-filters {
            font-size: 16px;
            display: inline-block;
        }
        @media screen and (max-width: 600px) {
            top: 58px;
        }

        .search-container{
            width: 50%;

            @media screen and (max-width: 600px) {
                width: 100%;
            }
        }
    }
    #shop-container {
        min-height: calc(100vh - 104px - 76px - 69.86px);
    }
    #order-container {
        position: sticky;
        bottom: 0px;
        background: #fff;
        box-shadow: 0px -2px 4px rgba(#000, .1);
        z-index: 3;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            position: fixed;
            width: 100%;
        }
    }
    h1 {
        font-weight: bold;
        font-size: 2rem;
    }
</style>
<script>
import { API_BASE_URL, WholesaleApiService } from '../services/wholesale-api.service';
import { STORE_ACTIONS } from '../store';
const DEFAULT_PRODUCTS_URL = `${API_BASE_URL}/products?page=1`;
Vue.prototype.api = WholesaleApiService;
export default {
    data() {
        return {
            paginationInfo: {
                next_page_url: DEFAULT_PRODUCTS_URL // this is the initial products url
            },
            productGroups: [],
            selectedFilters: [],
            searchTerm: '',
            showFilters: false,
            loading: false,
        }
    },
    computed: {
        cart(){ 
            return this.$store.state.cart;
        },
        updating(){
            return this.$store.state.updating;
        },
        filterSelected(){
            return this.selectedFilters.length>0;
        }
    },
    methods: {
        handleSearchInputChange(term) {
            // console.log('[Parent] Search term received', term);
            // this.http.getProductsBySearchTerm(term).then(res => products = res);
            if (term !== this.searchTerm) {
                this.searchTerm = term;
                // we have a new url
                this.paginationInfo.next_page_url = null;
                this.selectedFilters = [];
                // console.log('NEW SEARCH');
                this.loadProducts();
            }
        },
        handleFiltersChanged(selectedFilters) {
            // console.log('[Parent] Filter received', selectedFilters);
            if(window.innerWidth < 600){
                this.showFilters = false;
            }
            window.scrollTo(0,0);
            if (selectedFilters.sort() !== this.selectedFilters.sort()) {
                this.selectedFilters = selectedFilters;
                this.searchTerm = '';
                // we have a new url
                this.paginationInfo.next_page_url = null;
                // console.log('NEW FILTERS');
                this.loadProducts();
            }
        },
        loadProductsOnScroll() {
            /**
             * Manually load next page...
             * 
             * @Devnote we're using an intersection observer which
             * is not supported in IE. We'll need to find a polyfill 
             * or a viable alternative.
             * 
             * @see https://caniuse.com/#feat=intersectionobserver
             * @author LWK<lkrehnrbink@usdigitalpartners.com>
             * 
             */
            const options = {
                rootMargin: '400px 0px 0px 0px',
                threshold: .1
            };
            const callback = function(entries, observer) {
                entries.forEach((entry) => {
                    if ( entry.isIntersecting ) {
                        // console.log('INTERSECT');
                        if (this.paginationInfo.next_page_url) {
                            this.loadProducts();
                        }
                    }
                });
            };
            try {
                const observer = new IntersectionObserver(callback.bind(this), options);
                const anchor = document.getElementById('reload-anchor');
                observer.observe(anchor);
            } catch (err) {
                console.error('IntersectionObserver support issue', err);
            }
        },
        /**
         * Load Paginated product list
         * 
         * @Devnote we're loading the products locally instead
         * of persisting in the store. The API is sending back
         * the standard Laravel pagination object which contains
         * all information we need to continue loading 
         * 
         * @todo it would be nice to persist pagination in the url
         * so that page state can be restored...
         * 
         * @author LWK
         */
        loadProducts() {
            let url = DEFAULT_PRODUCTS_URL, 
                loadingAdditional = !!this.paginationInfo.next_page_url;
            if (loadingAdditional) {
                // This prevents CORS issues in dev...
                url = this.paginationInfo.next_page_url.replace('http://natorps-store.loc', '');
            }
            if (this.searchTerm.length) {
                // Include search term if we have one...
                url += `&search=${encodeURIComponent(this.searchTerm)}`;
            }
            if (this.selectedFilters.length) {
                // Include filters if some are set...
                this.selectedFilters.forEach(filter => url += `&filter[]=${encodeURIComponent(filter)}`);
            }
            this.loading = true;
            return this.api
                .getProducts(url)
                .then((res) => {
                    if (loadingAdditional) {

                        this.productGroups = [ 
                            ...this.productGroups,
                            ...res.data
                        ]; 
                    } else {
                        this.productGroups = res.data;
                    }
                    this.paginationInfo = res.paginationInfo;
                    this.loading = false;
                });
        }
    },
    created() {
        /**
         * Need a way to initialize the filters when I pass a filter query parameter, this allows for filtering on page load.
         */
        const urlParams = new URLSearchParams(window.location.search);
        const $q = urlParams.get('q');
        if($q){
            this.showFilters=true;
        }

        this.loadProducts()
            .then(() => this.loadProductsOnScroll());
    },
}
</script>