<template>
    <!--
        @Devnote this component represents a single Plant Product (SKU),
        as a row of the order table.
    -->
    <tr v-bind:class="{ 'show-all': showMoreDetails==true }">
        <td data-col="title" v-bind:class="{ 'd-block hide-desktop': index > 0 }">
            <product-title-component 
                :productName="product.ProductName"
                :productId="product.ProductID"
                :plantId="plantDetails.PlantClassID"
                :isOnSale="isOnSale">
            </product-title-component>
        </td>
        <td 
            v-for="(column, i) of columns" 
            v-bind:key="i"
            v-bind:data-col="column"
        >
            {{ columnMap[column] }}
        </td>
        <td data-col="quantity">
            <product-quantity-component
                :productId="product.ProductID"
                :plantId="plantDetails.PlantClassID"
                :inventory="product.availability.AvailQty">
            </product-quantity-component>
        </td>
        <td data-col="showMore" v-on:click="showMoreDetails=!showMoreDetails"><span v-if="showMoreDetails">View Less Details <span class="fa ml-1 fa-minus"></span></span><span v-if="!showMoreDetails">View More Details <span class="fa ml-1 fa-plus"></span></span></span></td>
    </tr>
</template>
<style lang="scss">
    table.table-order{

        td {
    
            &[data-col="size"] {
                text-transform: uppercase;
                font-weight: 600;
                font-size: 14px;
            }
            &[data-col="title"]{
                @media screen and (max-width: 600px) {
                    background: #ddd;
                    font-weight: bold !important;
                    border: 2px solid #dee2e6;
                    border-radius: 8px 8px 0 0;
                }
    
                &.hide-desktop {
                    visibility: hidden;
        
                    @media screen and (max-width: 600px) {
                        visibility: visible;
                    }
        
                }
            }
            &[data-col="showMore"] {
                display: none;
    
                @media screen and (max-width: 600px) {
                    display: flex;
                    justify-content: center;
                    padding-top: 0.25rem !important;
                    
                    &:before{
                        display: none;
                    }
                }
            }
    
            @media screen and (max-width: 600px) {
                padding-top: 0.25rem !important;
                padding-bottom: 0.25rem !important;
            }
    
        }
    }
</style>
<script>
import { ORDER_COLUMNS } from './order-table.component.vue';
export default {
    data() {
        return {
            columns: ORDER_COLUMNS.filter(
                item => ['variety', 'qty', 'total'].indexOf(item) < 0
            ),
            showMoreDetails: false
        }
    },
    computed: {
        columnMap() {
            return {
                'variety': this.product.ProductName,
                'size': this.product.ProductSize,
                'price 1-9': this.product.NLSPrice1,
                'price 10-49': this.product.NLSPrice2,
                'price 50+': this.product.NLSPrice3,
                'avail': this.product.availability.AvailQty,
                'comments': this.plantDetails.PlantGroup,
                'qty': 0,
            };
        },
        selectedQuantity() {
            return 0;
        },
        isOnSale(){
            return (this.product.NLSSalePrice > 0);
        }
    },
    props: ['index', 'product', 'plantDetails'],
}

const sampleProduct =  { 
    "ProductID": "803220243994",
    "PlantClassID": "00530",
    "ProductName": "Abies koreana 'Cis'",
    "ProductName2": "Cis Dwarf Korean Fir",
    "ProductSize": "3gal",
    "RetailPrice": "69.99",
    "NLSPrice1": "65.99",
    "NLSPrice2": "61.99",
    "NLSPrice3": "55.99",
    "NLSSalePrice": "45.00",
    "ClubPrice": "55.00",
    "Active": 1,
    "LastEditDate": "2019-03-31 15:38:36",
    "StartFertQty": 0.05,
    "SoilCondQty": 0.1,
    "availability": { 
        "ProductID": "803220243994",
        "AvailQty": 5,
        "AvailDate": null,
        "AvailDateQty": 0,
        "NNOLocation": null,
        "NurseryLocations": null 
    }
};

const sampleCartItem = {
    category: "Shrub",
    id: "803220225747",
    image_url: "/assets/plants/Cephalotaxus%20prostrata%20nat%20tag.jpg",
    inventory: 10,
    plant_id: "00640",
    price: 56.99,
    quantity: 1,
    size: "3gal",
    soil_cond_qty: 0.1,
    start_fert_qty: 0.05,
    title: "Prostrate Japanese Plum Yew",
    title_scientific: "Cephalotaxus h. 'Prostrata'",
    url: "/plants/shrub/00640",
    wholesale_prices: [
        "56.99",
        "53.99",
        "47.99",
        null,
    ],
}
</script>