<template>
    <table class="table table-order table-order--cart"
        v-bind:class="{'checkout-cart': checkOutCart==true}">
        <thead>
            <tr>
                <th v-for="column in columns" v-bind:key="column">{{ column }}</th>
            </tr>
        </thead>

        <tbody class="plant-group cart-item" 
            v-for="(cartItem, index) of cartItems"
            v-bind:key="cartItem.id"
            v-bind:class="{ 'even': index%2===0, 'odd': index%2!==0 }"
        >
            <order-table-cart-item-component
                :index="index"
                :cartItem="cartItem">
            </order-table-cart-item-component>
        </tbody>

        <tbody v-if="!cartItems.length">
            <tr>
                <td :colspan="columns.length" style="text-align:center">
                    <span class="text-muted">Your cart is empty.</span>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<style lang="scss">
    $border-color: #dee2e6;
    $border: 2px solid $border-color;

    .table.table-order {
        // border-radius: 1rem;
        // overflow: hidden;
        box-shadow: 0px 2px 4px rgba(#000, .1);
        border-top: none;
            @media screen and (max-width: 600px) {
                box-shadow: none;
                border: none;
            }


        thead th {
            position: sticky;
            top: calc(69px + 104px);
            z-index: 2;
        }

        th, td {
            border-top: $border;
            vertical-align: middle;

            @media screen and (max-width: 600px) {
                border: none;
            }
        }

        tbody {
            border-left: $border;
            border-right: $border;   
            font-size: 16px;
            @media screen and (max-width: 600px) {
                border: none;
            }


            tr{
                @media screen and (max-width: 600px) {
                    margin-bottom: 16px;
                    border-radius: 16px;
                    display: block;
                    border: $border;

                }

            }

        }
        
        thead {
            th {
                border-top: 2px solid #128851;
                background: #128851;
                color: #fff;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-weight: bold;
            }
        }

        &.checkout-cart{
            thead{
                th{
                    position: relative;
                    top: 0;
                }
                th:nth-child(2),
                th:nth-child(3),
                th:nth-child(4),
                th:nth-child(5),
                th:nth-child(6),
                th:nth-child(7)
                {
                    display: none;
                }
            }
            tbody{
                td:nth-child(2),
                td:nth-child(3),
                td:nth-child(4),
                td:nth-child(5),
                td:nth-child(6),
                td:nth-child(7)
                {
                    display: none;
                }

                tr{
                    @media screen and (max-width: 600px) {
                        // margin-bottom: 0;
                        // border-radius: 0;
                        // display: block;
                        // border: $border;
                    }
                }
            }
        }
    }
</style>
<script>
import { ORDER_COLUMNS } from './order-table.component.vue';
export default {
    data() {
        return {
            columns: ORDER_COLUMNS
        }
    },
    mounted() {
        // console.log('CartItems', this.cartItems);
    },
    props: {
        cartItems: {
            default: null
        }, 
        checkOutCart:{
            default: false
        }
    }
}
</script>