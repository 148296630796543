<template>
    <div class="cart-pane-toggle">
        <div class="btn-group" role="group">
            <button type="button" class="btn btn-success" v-on:click="setActivePane('cart')" v-bind:class="[getActivePane == 'cart' ? 'active' : 'inactive']">Cart</button>
            <button type="button" class="btn btn-success" v-on:click="setActivePane('shoppingList')" v-bind:class="[getActivePane == 'shoppingList' ? 'active' : 'inactive']">Wish List ({{ wishlist_count }})</button>
        </div>
    </div>
</template>
<style lang="scss" scoped></style>
<script>
import { STORE_ACTIONS } from '../../store';

export default {
    props: {
        wishlist_count: Number,
    },
    data() {
        return {}
    },
    computed: {
        getActivePane: function () {
            return this.$store.state.cartActivePane;
        }
    },
    methods: {
        setActivePane(activePane) {
            this.$store.dispatch(STORE_ACTIONS.CART_ACTIVE_PANE_UPDATE, activePane);
        }
    },
}
</script>