<template>
    <div class="w-100 d-flex align-items-center">
        <!-- <span>Quantity in store: {{ quantityInStore }}</span> -->
        <div class="mpbtn minus d-inline-block d-md-none mr-1 color-brand-forest" v-on:click="mpminus()"><i class="fa fa-minus"></i></div>
        <input type="number"
            name="quantity"
            v-model="selectedQuantity"
            class="form-control"
            :class="{ 'has-error': !isValid, 'has-value': selectedQuantity > 0 }"
            min="0" 
            :max="inventory"
            v-on:blur="checkValue()"/>
        <div class="mpbtn plus d-inline-block d-md-none ml-1 color-brand-forest" v-on:click="mpplus()"><i class="fa fa-plus"></i></div>
    </div>
</template>
<style lang="scss">
    input.form-control {
        min-width: 60px;
        &.has-value {
            border: 2px solid #128851;
        }
        &.has-error {
            border: 2px solid red !important;
        }
    }
</style>
<script>
import * as _ from 'lodash';
import { STORE_ACTIONS } from '../store';
import { WholesaleApiService } from '../services/wholesale-api.service';
Vue.prototype.api = WholesaleApiService;
export default {
    data() {
        return {
            selectedQuantity: 0,
            quantityInStore: 0,
        }
    },
    watch: {
        /**
         * @Devnote we need to test this since latency could
         * cause some mismatching issues on cart update.
         * 
         */
        selectedQuantity: _.debounce(function(newValue, oldValue) {
            // console.log('Quantity Updated', this.selectedQuantity, this.productId);
            if (this.isValid) {
                if (this.selectedQuantity === this.quantityInStore) {
                    // Don't need to update
                    return;
                }
                // valid... submit
                // console.log('VALID');
                if (+this.selectedQuantity === 0) {
                    // console.log('REMOVE');
                    this.removeCartItem();
                } else if (this.quantityInStore === 0) {
                    // console.log('ADD');
                    this.addCartItem()
                } else {
                    // console.log('UPDATE');
                    this.updateCartItem();
                }
            } else {
                // console.log('INVALID');
            }
        }, 500)
    },
    computed: {
        isValid() {
            return (
                /^\d+$/.test(this.selectedQuantity) // only digits
                && this.selectedQuantity >= 0 
                && this.selectedQuantity <= this.inventory
            );
        },
    },
    methods: {
        /**
         * @Devnote this method being called on input blur
         * 
         */
        checkValue() {
            if (this.selectedQuantity === '') {
                this.selectedQuantity = 0;
            }
        },
        addCartItem() {
            this.$store.dispatch(STORE_ACTIONS.SET_UPDATING, true);
            this.api
                .addCartItemForAuthenticatedUser(
                    this.productId,
                    this.plantId,
                    this.selectedQuantity
                )
                .then(
                    (cartData) => {
                        this.$store
                            .dispatch(STORE_ACTIONS.CART_ITEM_ADD, cartData);
                        this.$store.dispatch(STORE_ACTIONS.SET_UPDATING, false);
                    }
                );
        },
        updateCartItem() {
            this.$store.dispatch(STORE_ACTIONS.SET_UPDATING, true);
            this.api
                .updateCartItemForAuthenticatedUser(
                    this.productId,
                    this.plantId,
                    this.selectedQuantity
                )
                .then(
                    (cartData) => {
                        this.$store
                            .dispatch(STORE_ACTIONS.CART_ITEM_UPDATE, cartData);
                        this.$store.dispatch(STORE_ACTIONS.SET_UPDATING, false);
                    }
                );
        },
        removeCartItem() {
            this.$store.dispatch(STORE_ACTIONS.SET_UPDATING, true);
            this.api
                .removeCartItemForAuthenticatedUser(
                    this.productId
                )
                .then(
                    (cartData) => {
                        this.$store
                            .dispatch(STORE_ACTIONS.CART_ITEM_REMOVE, cartData);
                        this.$store.dispatch(STORE_ACTIONS.SET_UPDATING, false);
                    }
                );
        },
        mpplus(){
            this.selectedQuantity ++;
            if (this.selectedQuantity === '') {
                this.selectedQuantity = 0;
            }
        },
        mpminus(){
            this.selectedQuantity --;
            if (this.selectedQuantity === '') {
                this.selectedQuantity = 0;
            }
        }
    },
    created() {
        this.selectedQuantity = this.quantityInStore = this.$store.getters.cartItemsQuantityMap[this.productId] || 0;
        // Reconcile local Quantity with store quantity
        this.$store
            .watch(
                (state, getters) => getters.cartItemsQuantityMap,
                (cartItemsQuantityMap) => {
                    this.quantityInStore = cartItemsQuantityMap[this.productId] || 0;
                    if (this.quantityInStore !== this.selectedQuantity) {
                        // console.warn(
                        //     'Quantity Disparity between Local and Store',
                        //     `Local is ${this.selectedQuantity} and 
                        //      Store is ${this.quantityInStore}`
                        // );
                        this.selectedQuantity = this.quantityInStore;
                    }
                }
            );
    },
    props: ['productId', 'plantId', 'inventory'],
}
</script>