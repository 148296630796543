class filtersHelper {
	constructor(el) {
		this.flyoutToggleFilters();
		this.toggleFilterSections();
		this.applyFilters();
		this.finalizeFilters();
		this.countFiltersSlideoutMenu();
	}

	init(el) {
		if (undefined !== el) {
			el.querySelectorAll('.filter').forEach(function(item) {
				$(item).on('click', function(e) {
					const items = el.querySelectorAll('.filter.active');
					items.length
						? el.classList.add('has-filters')
						: el.classList.remove('has-filters');
				});
			});
		}

		$('.collapse-link').on('click', function() {
			$(this).toggleClass('collapsed');
			$('#collapseFilters').collapse('toggle');
		});

		$('.cancel-filters, .refine-search').on('click', function() {
			$('#collapseFilters').collapse('toggle');
		});

		$('#grid-view-results').on('click', function() {
			let params = new URLSearchParams(window.location.search);
			params.set('view', 'grid');
			window.location =
				window.location.pathname + '?' + params.toString();
		});
		$('#list-view-results').on('click', function() {
			let params = new URLSearchParams(window.location.search);
			params.set('view', 'list');
			window.location =
				window.location.pathname + '?' + params.toString();
		});
		$('#alphasort').on('change', function() {
			let params = new URLSearchParams(window.location.search);

			if (this.checked) {
				params.set('sort', 'alpha');
			} else {
				params.set('sort', 'default');
			}
			window.location =
				window.location.pathname + '?' + params.toString();
		});
	}

	resetFilters(el) {
		$(el).click(function(e) {
			e.preventDefault();
			var container = $(el).data('container');
			var tars = $(container).find('.filter.active');
			$(tars).removeClass('active');
			$('#submitFilters').prop('disabled', true);
		});
	}

	countFiltersSlideoutMenu() {
		var section = $('.slide-out-menu .filters-container .filter-section');
		var setFilters = [];

		section.each(function() {
			var filter = $(this).find('.filter.active');
			setFilters.push({ cat: filter.data('cat'), filter: filter });
		});

		setFilters = $.grep(setFilters, function(n) {
			return n.cat === 0 || n.cat;
		});
		// console.log(setFilters);
		setFilters.forEach(function(item) {
			var cat = item.cat;
			var count = item.filter;
			count = count.length;
			$('#slideoutFilters p[data-filter-name="' + cat + '"]')
				.children('span.count')
				.text(count);
		});
	}

	flyoutToggleFilters() {
		var filtersCaret = $(
			'section.slide-out-menu .filters-container .fa'
		).parent();

		filtersCaret.each(function() {
			if (
				$(this)
					.children('.fa')
					.hasClass('fa-caret-up')
			) {
				$(this)
					.siblings('.filter-section')
					.hide();
			}

			$(this).on('click', function(e) {
				e.preventDefault();

				if (
					$(this)
						.siblings()
						.is(':hidden')
				) {
					$(this)
						.children('.fa')
						.toggleClass('fa-rotate-180');
					$(
						'section.slide-out-menu .filters-container .filter-section'
					).hide();
					$(this)
						.siblings()
						.slideToggle('fast');
				}
			});
		});
	}

	toggleFilterSections() {
		var caret = $(
			'.home-page-filters .filters-container .fa-caret-down'
		).parent();

		caret.each(function() {
			if (
				$(this)
					.children('.fa')
					.hasClass('fa-caret-up')
			) {
				$(this)
					.siblings()
					.hide();
			}

			$(this).on('click', function(e) {
				e.preventDefault();

				var target = $(this).siblings();
				$(this)
					.children('.fa')
					.toggleClass('fa-rotate-180');
				target.slideToggle('fast');
			});
		});
	}

	applyFilters() {
		function activateFilter(e, clickedFilter) {
			var selectedFilters = [];
			var finalFilters = [];

			e.preventDefault();

			var tar = $(this)
				.find('img')
				.attr('alt');
			var cat = $(this).data('cat');
			var count = $(this)
				.parent()
				.parent()
				.siblings('p')
				.find('.count')
				.text();
			var num = parseInt(count);

			$(clickedFilter).toggleClass('active');

			if ($(this).hasClass('active')) {
				$(this)
					.parent()
					.parent()
					.siblings('p')
					.find('.count')
					.text(++num);
				// $num =  + 1;
				selectedFilters.push({ cat: cat, filter: tar });
				// selectedFilters.push(tar);

				$(clickedFilter).attr('aria-checked', 'false');
			} else {
				$(this)
					.parent()
					.parent()
					.siblings('p')
					.find('.count')
					.text(--num);
				selectedFilters = $.grep(selectedFilters, function(value) {
					return value.filter != tar;
					// return value != tar;
				});
			}
		}

		function toggleAriaChecked(e, clickedFilter) {
			if ($(clickedFilter).attr('aria-checked') === 'false') {
				console.log('attr is false, change it to true!');
				$(clickedFilter).attr('aria-checked', 'true');
			} else if ($(clickedFilter).attr('aria-checked') === 'true') {
				console.log('attr is true, change it to false!');
				$(clickedFilter).attr('aria-checked', 'false');
			}
		}

		var filter = $('.filters-container .filter');

		filter.each(function() {
			$(this).on('click', function(e) {
				var clickedFilter = $(this);
				activateFilter(e, clickedFilter);
				toggleAriaChecked(e, clickedFilter);
			});
			$(this).keypress(function(e) {
				var clickedFilter = $(this);
				if (e.which == 13) {
					activateFilter(e, clickedFilter);
					toggleAriaChecked(e, clickedFilter);
				}
			});
		});
	}

	finalizeFilters() {
		var activeFilters;
		var params;

		$('a.submit-filters, button.submit-filters').on('click', function(e) {
			e.preventDefault();

			var categories = [];
			var sun_exposure = [];
			var flower_color = [];
			var bloom_months = [];
			var attributes = [];
			var availability = [];
			activeFilters =
				$(this).data('slideout') === true
					? $('#slideoutFilters .filter.active')
					: $('.home-page-filters .filters-container .filter.active');

			activeFilters.each(function() {
				var dataCat = $(this).data('cat');
				var tar = $(this)
					.find('img')
					.attr('alt');

				if (dataCat == 'Categories') {
					categories.push(tar);
				} else if (dataCat == 'Sun Exposure') {
					sun_exposure.push(tar);
				} else if (dataCat == 'Flower Color') {
					flower_color.push(tar);
				} else if (dataCat == 'Bloom Months') {
					bloom_months.push(tar);
				} else if (dataCat == 'Attributes') {
					attributes.push(tar);
				} else if (dataCat == 'Availability') {
					availability.push(tar);
				}
			});

			var q = $('#searchBox').val();

			if (activeFilters.length > 0 || q !== '') {
				params =
					'/plants?q=' +
					q +
					'&categories=' +
					categories +
					'&sun=' +
					sun_exposure +
					'&flower=' +
					flower_color +
					'&bloom=' +
					bloom_months +
					'&atts=' +
					attributes +
					'&availability=' +
					availability;
				params = params.replace(/&?[^&?]+=(?=(?:&|$))/g, '');
				params = params.replace(/(\?\&)/g, '?');
				window.location.href = params;
			} else {
				window.location.href = '/categories';
			}
		});
	}
}
window.app.add('filters', new filtersHelper());
