class navigationHelper{
	constructor() {

	}

	slideOut(){
		var $nav = $('.navbar-slideout');
		var $open = $nav.find('#navbar-toggle');
		var $close = $('#navbar-backdrop');
		var $menu = $nav.find('.collapse');
		$open.click(function(e){
			e.preventDefault();
			//var $mw = $menu.css('width');
			//Slide in nav links, then remove animation class
			$menu.addClass('animated slideInRight').one(animationEnd, function(){
				$menu.removeClass('animated slideInRight');
			});
			//Set nav display
			$nav.addClass('menu-open');
			//Animate backdrop fade in
			$close.css('opacity', '0').animate({
				opacity: '1'
			},200);
			//Freeze body scroll
			$('body, #mobile-wrap').css('overflow', 'hidden');
			//Kill mobile scroll
			$(document).bind('touchmove', function(e){
				if( e.target.id == 'navbar-backdrop' ) {
					return false;
				}
			});
			//kill bootstrap functionality
			return false;
		});
		$close.click(function(e){
			//Animate backdrop opacity
			$close.animate({
				opacity: '0'
			}, 200);
			//Unbind touchmove for mobile
			$(document).off('touchmove');
			//Slide out menu, remove animation, and hide links
			$menu.addClass('animated slideOutRight').one(animationEnd, function(){
				$menu.removeClass('animated slideOutRight');
				$nav.removeClass('menu-open');
			});
			$('body, #mobile-wrap').css('overflow', 'auto');
		});
	}
}
window.app.add('navigation', new navigationHelper());