class toggleHelper{
	constructor() {
	}
	
	filter(el) {
		$(el).click(function(){
			$($(this).data('target')).hide();
		});
	}
}
window.app.add('toggle', new toggleHelper());