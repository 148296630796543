window.$ = require('jquery');
window.popper = require('popper.js');
require('bootstrap');
window._ = require('lodash');
window.axios = require('axios');

/**
 * @Global - Vue inclusion
 *
 */
// import 'es6-promise/auto';

import 'custom-event-polyfill';
import 'url-search-params-polyfill';
import Vue from 'vue';
import { WholesaleApp } from './containers/wholesale.app';
window.Vue = Vue;

/**---- jQuery Configuration ----*/
const token = document.head.querySelector('meta[name="csrf-token"]');
$.ajaxSetup({
	beforeSend: function(xhr) {
		xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
		xhr.setRequestHeader('X-CSRF-TOKEN', token.content);
	},
});

/**---- @Vue Configuration ----*/

/**
 * @Vue - Extensions
 *
 */
Vue.prototype.$ = window.$ || window.jQuery;
Vue.prototype._ = window._;
Vue.prototype.axios = window.axios;
Vue.prototype.braintree = window.braintree || {};
/**
 * @Vue - Config
 *
 */
Vue.config.devtools = true;
Vue.config.productionTip = false;
/**
 * @Vue - Filters
 */
Vue.filter('currency', (value) => '$' + parseFloat(value).toFixed(2));
Vue.filter('json', (value) => JSON.stringify(value));
/**
 * Vue Components
 *
 * @Devnote we can probably find a more elegant way to autoload
 * the components directory and automate the vue component
 * registration line.
 *
 * @Devnote since Vue 2, the vue-loader exports the component as the
 * `default` key, which we need to explicitly use for registration.
 *
 */
Vue.component(
	'shop-wholesale-component',
	require('./components/shop-wholesale.component.vue').default
);
Vue.component(
	'checkout-wholesale-component',
	require('./components/checkout-wholesale.component.vue').default
);
Vue.component(
	'cart-wholesale-component',
	require('./components/cart-wholesale.component.vue').default
);
Vue.component(
	'search-component',
	require('./components/search.component.vue').default
);
Vue.component(
	'filters-component',
	require('./components/filters.component.vue').default
);
Vue.component(
	'order-table-component',
	require('./components/order-table.component.vue').default
);
Vue.component(
	'order-table-group-component',
	require('./components/order-table-group.component.vue').default
);
Vue.component(
	'order-table-item-component',
	require('./components/order-table-item.component.vue').default
);
Vue.component(
	'order-table-cart-component',
	require('./components/order-table-cart.component.vue').default
);
Vue.component(
	'order-table-cart-item-component',
	require('./components/order-table-cart-item.component.vue').default
);
Vue.component(
	'product-title-component',
	require('./components/product-title.component.vue').default
);
Vue.component(
	'product-quantity-component',
	require('./components/product-quantity.component.vue').default
);
Vue.component(
	'modal-plant-detail-component',
	require('./components/modal-plant-detail.component.vue').default
);
Vue.component(
	'promo-codes-component',
	require('./components/promo-codes.component.vue').default
);
Vue.component(
	'gift-cards-component',
	require('./components/gift-cards.component.vue').default
);

// Cart Components
Vue.component(
	'wish-list-item-component',
	require('./components/cart/wish-list-item.component.vue').default
);
Vue.component(
	'cart-empty-component',
	require('./components/cart/cart-empty.component.vue').default
);
Vue.component(
	'cart-pane-toggle-component',
	require('./components/cart/cart-pane-toggle.component.vue').default
);
Vue.component(
	'wish-list-footer-component',
	require('./components/cart/wish-list-footer.component.vue').default
);
Vue.component(
	'wish-list-component',
	require('./components/cart/wish-list.component.vue').default
);
Vue.component(
	'cart-sub-footer-component',
	require('./components/cart/cart-sub-footer.component.vue').default
);
Vue.component(
	'cart-footer-checkout-component',
	require('./components/cart/cart-footer-checkout.component.vue').default
);
Vue.component(
	'cart-footer-component',
	require('./components/cart/cart-footer.component.vue').default
);
Vue.component(
	'cart-header-component',
	require('./components/cart/cart-header.component.vue').default
);
Vue.component(
	'cart-contents-component',
	require('./components/cart/cart-contents.component.vue').default
);
Vue.component(
	'cart-item-component',
	require('./components/cart/cart-item.component.vue').default
);
Vue.component(
	'cart-slideout-component',
	require('./components/cart/cart-slideout.component.vue').default
);
Vue.component(
	'cart-nav-button-component',
	require('./components/cart/cart-nav-button.component.vue').default
);

Vue.component(
	'add-to-cart-component',
	require('./components/cart/add-to-cart.component.vue').default
);

Vue.component(
	'cart-checkout-item-component',
	require('./components/cart/checkout/cart-checkout-item.component.vue').default
);

//SVG Components
Vue.component(
	'natorps-icons-box-svg-component',
	require('./components/svgs/natorps_icons_box.svg.component.vue').default
);
Vue.component(
	'natorps-icons-greenhouse-svg-component',
	require('./components/svgs/natorps_icons_greenhouse.svg.component.vue').default
);
Vue.component(
	'natorps-icons-plants-svg-component',
	require('./components/svgs/natorps_icons_plants.svg.component.vue').default
);
Vue.component(
	'natorps-icons-truck-svg-component',
	require('./components/svgs/natorps_icons_truck.svg.component.vue').default
);



/**
 * @devnote we should only call this in context of the retail/wholesale
 * applications, right?
 */
new WholesaleApp();
