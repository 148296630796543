// Setup doesn't like this
// import { PROMO_CODE_TYPES } from '../data/promo-code-types';

import { store } from '../store';

/**
 * These need to match the PromoCodeTypes class from the API
 *
 * @see app\Models\PromoCodeTypes.php
 */
const PROMO_CODE_TYPES = {
	AMOUNT: 'amount',
	AMOUNT_OVER: 'amount_over',
	AMOUNT_PRODUCT: 'amount_product',
	PERCENT: 'percent',
	PERCENT_OVER: 'percent_over',
	PERCENT_PRODUCT: 'percent_product',
};
class cartHelper {
	constructor() { }

	init(config) {
		window.app.addToCartInstance = window.app.cart.bindAddToCartVue();
		if (config && config.hasOwnProperty('cartEl')) {
			window.app.cartInstance = window.app.cart.bindCartVue({
				el: config.cartEl,
				bindPayment: true,
				pickupText: config.pickupText,
				deliveryText: config.deliveryText,
				installationText: config.installationText,
			});
		} else {
			window.app.cartInstance = window.app.cart.bindCartVue(config);
		}

		// Backdrop Fix
		const backdrop = document.getElementById('cart-backdrop');
		if (backdrop) {
			backdrop.addEventListener('click', () => {
				var cart = document.getElementById('cart-slideout'),
					backdrop = document.getElementById('cart-backdrop');

				cart.classList.add('animated', 'slideOutRight');
				backdrop.classList.add('animated', 'fadeOut');
				var timeOut = setTimeout(function () {
					cart.classList.remove('in', 'animated', 'slideOutRight');
					backdrop.classList.remove('in', 'animated', 'fadeOut');
					window.app.cartInstance.$data.shown = false;
					clearTimeout(timeOut);
				}, 300);
			});
		}
	}

	bindAddToCartVue() {
		var addToCartData = window.addToCartData;
		var productImage = window.productImage;

		var dataLayer = window.dataLayer || [];

		if (!$('#add-to-cart')[0] || typeof addToCartData === 'undefined')
			return false;

		// add prop for binding
		addToCartData.forEach((item) => {
			item.showNotifyMessage = false;
		});

		return new Vue({
			store: store,
			delimiters: ['${', '}'],
			el: '#add-to-cart',
			data: {
				products: addToCartData,
				productImage: productImage,
				productId: null,
				ready: false,
				showAddedQuantity: false,
				addedToShoppingList: false,
			},
			computed: {
				totalItems: function () {
					return this.products.reduce((total, item) => {
						return total + item.quantity;
					}, 0);
				},
				disableAddToCart: function () {
					for (var item in this.products) {
						if (this.products[item].quantity > 0) {
							return false;
						}

					}
					return true;
				},
				anyProductAvailable: function () {
					for (var item in this.products) {
						if (this.products[item].available > 0 || this.products[item].allow_preorder) {
							return true;
						}
					}
					return false;
				},
			},
			methods: {
				initQuantities: function () {
					for (var item in this.products) {
						this.products[item].price = parseFloat(
							this.products[item].price
						);
					}
				},
				resetQuantities: function () {
					for (var item in this.products) {
						this.products[item].quantity = 0;
					}
				},
				decrementQuantity: function (index) {
					if (this.products[index].quantity == 0) return;
					this.showAddedQuantity = false;
					this.products[index].quantity--;
				},
				incrementQuantity: function (index) {
					if (
						this.products[index].quantity ==
						this.products[index].inventory
					)
						return;
					this.showAddedQuantity = false;
					this.products[index].quantity++;
				},
				addToCart: function () {
					// console.log('Will add ' + this.selectedProduct.name + ' to cart with quantity ' + this.selectedProduct.quantity);
					// @DEVNOTE: add event listener to reset quantity field on cart update
					var productCount = 0;
					var updatedProducts = [];
					var self = this;
					this.products.forEach((product) => {
						if (product.quantity > 0) {
							console.log('add product to cart', product);
							self.fireGTMaddToCart(product);
							setTimeout(function () {
								window.app.cartInstance.addItem(
									product.id,
									product.plant_id,
									product.quantity
								);
							}, productCount * 300);
							productCount++;
						}
					});
					var listener = window.addEventListener(
						'cartUpdated',
						function (e) {
							console.log('cartUpdated', e.detail.productId);
							updatedProducts.push({
								productId: e.detail.productId,
								plantId: e.detail.plantId,
								quantity: e.detail.quantity,
								status: 'success',
							});
							if (updatedProducts.length == productCount) {
								self.showCartStatus(updatedProducts);
							}
						}
					);
					var listener = window.addEventListener(
						'cartError',
						function (e) {
							console.log('cartError', e);
							updatedProducts.push({
								productId: e.detail.productId,
								plantId: e.detail.plantId,
								status: 'error',
							});
							if (updatedProducts.length == productCount) {
								self.showCartStatus(updatedProducts);
							}
						}
					);
				},
				showCartStatus: function (updatedProducts) {
					var html = '<div class="products-added">';
					var actualAdded = updatedProducts.reduce((total, item) => {
						return total + item.quantity;
					}, 0);
					html +=
						'<div class="total-added">' +
						actualAdded +
						' item' +
						(actualAdded > 1 ? 's' : '') +
						' Added to Cart</div>';

					updatedProducts.forEach((updatedProduct) => {
						var product = this.products.find(
							(product) => product.id == updatedProduct.productId
						);
						html += '<div class="product">';
						html +=
							'<div class="product-image" style="background-image: url(\'/assets/plants/' +
							this.productImage +
							'\');"></div>';
						html +=
							'<div class="product-info"><div class="product-name">' +
							product.name +
							'</div><div class="product-size">' +
							product.size +
							' x' +
							updatedProduct.quantity +
							'</div></div>';
						html += '</div>';
					});
					window.app.cart.showStatus(html);
					this.showAddedQuantity = this.totalItems;
					this.resetQuantities();
				},
				notifyMe: function (product) {
					self = this;
					// console.log("Setting up notification");
					return $.ajax({
						url: '/cart/notify',
						method: 'POST',
						data: {
							product: product,
						},
						success: function (data) {
							self.products.find(
								(item) => item.id == product.id
							).showNotifyMessage = true;
						},
						error: function (error) {
							// console.log(error);
						},
					}).then(function () {
						// console.log('finished');
					});
				},
				fireGTMaddToCart: function (product) {
					//universal tag datalayer push
					var data = {
						event: 'addToCart',
						ecommerce: {
							currencyCode: 'USD',
							add: {
								products: [
									{
										name: product.name,
										id: product.id,
										price: product.price.toString(),
										category: product.category,
										variant: product.size,
										quantity: parseInt(product.quantity),
									},
								],
							},
						},
					};
					dataLayer.push(data);

					//ga4 tag datalayer push
					var g4data = {
						event: 'add_to_cart',
						ecommerce: {
							currencyCode: 'USD',
							add: {
								products: [
									{
										name: product.name,
										id: product.id,
										price: product.price.toString(),
										category: product.category,
										variant: product.size,
										quantity: parseInt(product.quantity),
									},
								],
							},
						},
					};
					dataLayer.push(g4data);
				},
				// Shopping List Functionality
				addToShoppingList: function () {
					var self = this;
					window.app.cartInstance.addItemToShoppingList(
						self.productId
					);
					var listener = window.addEventListener(
						'shoppingListUpdated',
						function (e) {
							self.addedToShoppingList = true;
						}
					);
				},
			},
			mounted: function () {
				this.initQuantities();
				this.selectedProduct = this.products[0];
				this.productId = this.products[0].plant_id;
			},
		});
	}

	showStatus(message, shoppingList) {
		var shoppingListText =
			typeof shoppingList !== 'undefined' && shoppingList == true;
		var statusEl = document.getElementById('cart-status'),
			statusText = document.getElementById('cart-status-text');
		statusText.innerHTML = message;
		statusEl.classList.add('in', 'animated', 'fadeIn');
		var timeOut = setTimeout(function () {
			statusEl.classList.remove('animated', 'fadeIn');
			statusEl.classList.add('animated', 'fadeOut');
			var timeEnd = setTimeout(function () {
				statusEl.classList.remove('in', 'animated', 'fadeOut');
				clearTimeout(timeEnd);
			}, 1000);
			clearTimeout(timeOut);
		}, 3500);
	}

	bindCartVue(config) {
		var upsellItems = window.upsellItems;
		var oldFormValues = window.oldFormValues;
		console.log(oldFormValues);
		var user_zipcode = window.user_zipcode;
		var PickupText = config.pickupText
			? config.pickupText
			: 'I understand that my purchase is for in store pick up only and it may take up to 4 days for my order to be processed and ready for pick up.';
		var DeliveryText = config.deliveryText
			? config.deliveryText
			: 'I understand that my purchase may take two to three weeks to be delivered. Natorp’s will contact me to schedule a delivery date within the next 48 hours.';
		var InstallationText = config.installationText
			? config.installationText
			: 'I understand that the lead time for installation is 4 weeks. Natorp’s will contact me to schedule an installation date within the next 48 hours';
		if (!$(config && config.el ? config.el : '#cart-slideout')[0]) {
			// Prevent Vue initialization if we don't have the element
			return false;
		}

		// Navbar Vue (needed for cart button)
		window.app.navbar = new Vue({
			store: store,
			delimiters: ['${', '}'],
			el: '.navbar-nav',
			mounted() {
				$(document).trigger('vue-loaded');
			},
		});

		return new Vue({
			store: store,
			delimiters: ['${', '}'],
			el: config && config.el ? config.el : '#cart-slideout',
			data: {
				shown: false,
				ready: false,
				shoppingListAvailable: false,
				updating: false,
				submitting: false,
				upsellItems: upsellItems,
				zipCode:
					oldFormValues && oldFormValues.zipcode
						? oldFormValues.zipcode
						: user_zipcode,
				min_delivery_amount: window._NATORPS.general.DeliveryMinimum,
				min_install_fee: window._NATORPS.general.minimum_install_fee,
				multiple_install_quantity:
					window._NATORPS.general.multiple_install_quantity,
				multiple_install_discount:
					window._NATORPS.general.multiple_install_discount,
				min_order_amount: (
					window._NATORPS.general.minimum_order_price || 0
				).toFixed(2),
				shippingRate: 0,
				shippingTaxRate: null,
				variants: [],
				fertilizerAmount: 0,
				conditionerAmount: 0,
				selectedProduct: [],
				taxRate: null,
				confirmRemove: false,

				shoppingListState: 'action',
				orderNotes:
					oldFormValues && oldFormValues.order_notes
						? oldFormValues.order_notes
						: '',
				loading: true,
				events: {
					cartUpdateEvent: new window.CustomEvent('cartUpdated'),
					cartErrorEvent: new window.CustomEvent('cartError'),
					shoppingListUpdateEvent: new window.CustomEvent(
						'shoppingListUpdated'
					),
				},

				disclaimerTextInStore: PickupText,
				disclaimerTextDelivery: DeliveryText,
				disclaimerTextInstallationDelivery: InstallationText,
				promoCode: null,
				giftCards: [],
				//REFACTORED DATA ITEMS
				selectedFulfillmentOption: '',
				fulfillmentInStoreSelected: false,
				fulfillmentDeliverySelected: false,
				fulfillmentDeliveryInstallationSelected: false,
				deliveryAvailable: false,
				zipCodeConfirmed: false,
				hasConfirmedDisclaimer: false,
				paymentInfoEntered: false,
				//****OLD DATA****
				// cart: [], // Removing this breaks everything for some reason. TODO: solve this.

				// confirmShoppingListRemove: false,
				// confirmEmptyShoppingList: false,
				// confirmEmailShoppingList: false,
				// shoppingList: [], // Removing this breaks stuff. TODO: Solve this.
			},
			computed: {
				// **** OLD COMPUTED FUNCTIONS ******
				// cartShippingHandling: function () {
				// 	var shipping = 0;
				// 	if (this.itemsCountInstallation > 0) {
				// 		var count = 0;
				// 		const multipleItemDiscount =
				// 			this.$store.state.slideoutCart.length >=
				// 			this.multiple_install_quantity ||
				// 			this.$store.state.slideoutCart.some(
				// 				(i) =>
				// 					i.quantity >= this.multiple_install_quantity
				// 			);
				// 		for (var item in this.$store.state.slideoutCart) {
				// 			if (
				// 				this.shouldShowProduct(
				// 					this.$store.state.slideoutCart[item]
				// 				)
				// 			) {
				// 				count += this.$store.state.slideoutCart[item]
				// 					.quantity;
				// 				let itemInstallFee =
				// 					this.$store.state.slideoutCart[item]
				// 						.install_price *
				// 					this.$store.state.slideoutCart[item]
				// 						.quantity;
				// 				if (multipleItemDiscount) {
				// 					itemInstallFee =
				// 						itemInstallFee *
				// 						((100 -
				// 							this.multiple_install_discount) /
				// 							100);
				// 				}
				// 				shipping += itemInstallFee;
				// 			}
				// 		}
				// 		if (shipping <= this.min_install_fee) {
				// 			shipping = this.min_install_fee;
				// 		}
				// 	}
				// 	return parseFloat(shipping).toFixed(2);
				// },
				// isShippingAllowed: function () {
				// 	var total = this.cartSubtotalWithoutShipping;
				// 	// handle delivery status check
				// 	if (total < this.min_delivery_amount) {
				// 		// this.enableInStore();
				// 		return false;
				// 	} else {
				// 		return true;
				// 	}
				// },

				cart: function () {
					return this.$store.state.slideoutCart.sort((a, b) => b.allow_preorder - a.allow_preorder);
				},
				cartItemsStorePickup: function () {
					return this.cart.filter(cart => cart.fulfillment_type === 'store-pickup');
				},
				cartItemsDelivery: function () {
					return this.cart.filter(cart => cart.fulfillment_type === 'delivery');
				},
				cartItemsDeliveryInstallation: function () {
					return this.cart.filter(cart => cart.fulfillment_type === 'delivery-installation');
				},
				shoppingList: function () {
					return this.$store.state.wishlistItems;
				},
				cartTotalIsAboveMinimumOrderAmount: function () {
					return parseFloat(this.cartSubtotalWithoutShipping) >= parseFloat(this.min_order_amount);
				},
				cartTotalIsAboveMinimumDeliveryAmount: function () {
					return parseFloat(this.cartSubtotalWithoutShipping) >= parseFloat(this.min_delivery_amount);
				},
				disableAddToCart: function () {
					for (
						var i = 0, len = this.selectedProduct.length;
						i < len;
						i++
					) {
						if (
							this.selectedProduct[i] &&
							this.selectedProduct[i].quantity > 0
						) {
							return null;
						}
					}
					return true;
					// return null;
				},
				cartEl: function () {
					return document.getElementById(
						config && config.el
							? config.el.replace('#', '')
							: 'cart-slideout'
					);
				},
				cartCount: function () {
					// Proxy to the Vuex getter (this is needed by other stuff but isn't used by the slideout cart)
					return this.$store.getters.cartCount;
				},
				cartLength: function () {
					return this.$store.state.slideoutCart.length;
				},
				shoppingListCount: function () {
					// Removing this causes errors, however, it is not used by the slideout cart which has a similar function in Vuex.
					var count = 0;
					for (var item in this.$store.state.wishlistItems) {
						count++;
					}
					return count;
				},
				backdropEl: function () {
					return document.getElementById('cart-backdrop');
				},
				cartClass: function () {
					//DEVNOTE DEBUG CLASS BINDING
					var str = '';
					if (this.$store.state.slideoutCart.length == 0)
						str += ' empty';
					// if (this.updating) str += ' updating';
					return str;
				},
				promoCodeDescription: function () {
					if (this.promoCode) {
						// PromoCode discount description
						switch (this.promoCode.type) {
							case PROMO_CODE_TYPES.AMOUNT:
							case PROMO_CODE_TYPES.AMOUNT_OVER:
							case PROMO_CODE_TYPES.AMOUNT_PRODUCT:
								// FIXED AMOUNT
								return `$${this.promoCode.amount} off your before-tax order total`;
							case PROMO_CODE_TYPES.PERCENT:
							case PROMO_CODE_TYPES.PERCENT_OVER:
								// FIXED PERCENT
								// Percent values are integers between 1 and 100
								return `${this.promoCode.percent}% off your before-tax order total`;
							default:
								// FIXED PERCENT SINGLE PRODUCT
								return `${this.promoCode.percent}% off ${this.promoCode.required_product.ProductName2} (${this.promoCode.required_product.ProductSize})`;
						}
					}
					return '';
				},
				// DOLLAR AMOUNT of DISCOUNT
				promoCodeDiscountToApply: function () {
					if (this.promoCode) {
						/**
						 * Calculate PromoCode discount
						 *
						 * @Devnote percents, order minimums, and required products
						 * can be inferred from the promoCode data. The only additional
						 * DISCOUNT_TYPE that needs calculation is a PERCENT DISCOUNT on
						 * a CERTAIN PRODUCT in the cart.
						 *
						 * @author LWK
						 */
						let discount;
						switch (this.promoCode.type) {
							case PROMO_CODE_TYPES.AMOUNT:
							case PROMO_CODE_TYPES.AMOUNT_OVER:
							case PROMO_CODE_TYPES.AMOUNT_PRODUCT:
								// FIXED AMOUNT
								return parseFloat(
									this.promoCode.amount
								).toFixed(2);
							case PROMO_CODE_TYPES.PERCENT:
							case PROMO_CODE_TYPES.PERCENT_OVER:
								// FIXED PERCENT
								// Percent values are integers between 1 and 100
								discount = (
									parseFloat(
										this.cartSubtotalWithoutShipping
									) *
									(parseFloat(this.promoCode.percent) / 100)
								).toFixed(2);
								return parseFloat(discount).toFixed(2);
							case PROMO_CODE_TYPES.PERCENT_PRODUCT:
								// FIXED PERCENT OFF SINGLE PRODUCT IN CART
								const product = this.$store.state.slideoutCart.find(
									(item) =>
										item.id ===
										this.promoCode.required_product_id
								);
								discount = (
									parseInt(product.quantity) *
									parseFloat(product.price) *
									(parseFloat(this.promoCode.percent) / 100)
								).toFixed(2);
								return parseFloat(discount).toFixed(2);
							default:
								return 0;
						}
					}
					return 0;
				},
				cartSubtotalWithoutShipping: function () {
					let total = 0;
					for (var item in this.cart) {
						total += this.cart[item].price * this.cart[item].quantity;
					}
					this.calculateUpsell();
					return total.toFixed(2);
				},
				// COST of combined total items in cart AND shipping, before TAX
				cartSubtotal: function () {
					var total = 0;
					for (var item in this.cart) {
						total += this.cart[item].price * this.cart[item].quantity;
					}

					if (this.itemsCountDelivery > 0) {
						total += parseFloat(this.cartDeliveryCost);
					}

					if (this.itemsCountInstallation > 0) {
						total += parseFloat(this.cartInstallationCost);
					}

					this.calculateUpsell();
					return total.toFixed(2);
				},
				// TAX calculated on ORDER TOTAL + SHIPPING - DISCOUNT
				cartTax: function () {
					var subtotal = parseFloat(
						this.cartTotalAfterPromoCodeDiscount
					);
					if (this.shippingTaxRate && (this.itemsCountDelivery > 0 || this.itemsCountInstallation > 0)) {
						this.tax = subtotal * parseFloat(this.shippingTaxRate);
					} else {
						this.tax = subtotal * this.taxRate;
					}
					return this.tax.toFixed(2);
				},
				cartDeliveryCost: function () {
					let cost = 0;
					if (!this.cartTotalIsAboveMinimumDeliveryAmount) return cost;
					if (this.itemsCountDelivery > 0) {
						return parseFloat(this.shippingRate).toFixed(2);
					}
					return cost;
				},
				cartInstallationCost: function () {
					var cost = 0;
					if (this.itemsCountInstallation > 0) {
						var count = 0;
						const multipleItemDiscount = this.cartItemsDeliveryInstallation.length >= this.multiple_install_quantity || this.cartItemsDeliveryInstallation.some((i) => i.quantity >= this.multiple_install_quantity);
						//revisit fix this so it's only on installation items
						for (var item in this.cartItemsDeliveryInstallation) {
							count += this.cartItemsDeliveryInstallation[item].quantity;
							let itemInstallFee = this.cartItemsDeliveryInstallation[item].install_price * this.cartItemsDeliveryInstallation[item].quantity;
							if (multipleItemDiscount) {
								itemInstallFee = itemInstallFee * ((100 - this.multiple_install_discount) / 100);
							}
							cost += itemInstallFee;
						}
						if (cost <= this.min_install_fee) {
							cost = this.min_install_fee;
						}
					}
					return parseFloat(cost).toFixed(2);
				},
				cartTotalAfterPromoCodeDiscount: function () {
					if (this.promoCode) {
						return (
							this.cartSubtotal -
							parseFloat(this.promoCodeDiscountToApply)
						).toFixed(2);
					}
					return this.cartSubtotal;
				},
				cartTotalPreGiftCards: function () {
					var total = 0;
					total =
						parseFloat(this.cartTotalAfterPromoCodeDiscount) +
						parseFloat(this.cartTax);
					return total.toFixed(2);
				},
				cartTotal: function () {
					var total = parseFloat(this.cartTotalPreGiftCards);
					for (var card in this.giftCards) {
						total -= this.giftCards[card].appliedValue;
					}
					return total.toFixed(2);
				},
				showFertilizer: function () {
					if (this.fertilizerAmount > 0) {
						return true;
					}
					return false;
				},
				showConditioner: function () {
					if (this.conditionerAmount > 0) {
						return true;
					}
					return false;
				},
				countPreorder: function () {
					return this.cart.filter((item) => item.allow_preorder).length;
				},
				countInStock: function () {
					return this.cart.filter((item) => !item.allow_preorder).length;
				},
				countSupplies: function() {
					return this.cart.filter((item) => item.category === "Supplies").length;
				},
				itemsCountStorePickup: function () {
					return this.cart.filter((item) => item.fulfillment_type === 'store-pickup').length;
				},
				itemsCountDelivery: function () {
					return this.cart.filter((item) => item.fulfillment_type === 'delivery').length;
				},
				itemsCountInstallation: function () {
					return this.cart.filter((item) => item.fulfillment_type === 'delivery-installation').length;
				},
				itemsCountTotal: function () {
					return this.cart.length;
				},
				isDeliveryAvailable: function () {
					if (!this.cartTotalIsAboveMinimumDeliveryAmount) {
						return false;
					}
					return this.deliveryAvailable;
				},
				isZipCodeConfirmed: function () {
					return this.zipCodeConfirmed;
				},
				deliveryButtonMsg: function () {
					if (!this.cartTotalIsAboveMinimumDeliveryAmount) {
						return "Delivery is not available";
					}
					if (!this.zipCodeConfirmed) {
						return "Enter ZIP code to determine availability";
					}
					if (!this.deliveryAvailable) {
						return "Not available in your area";
					}
					return this.itemsCountTotal + "/" + this.itemsCountTotal + " items are available for delivery.";
				},
				deliveryInstallationButtonMsg: function () {
					if (!this.cartTotalIsAboveMinimumDeliveryAmount) {
						return "Delivery is not available";
					}
					if (!this.zipCodeConfirmed) {
						return "Enter ZIP code to determine availability";
					}
					if (!this.deliveryAvailable) {
						return "Not available in your area";
					}
					return (this.itemsCountTotal - this.countSupplies) + "/" + this.itemsCountTotal + " items are available for delivery & installation.";
				},
				enableSubmitOrderButton: function () {
					return this.paymentInfoEntered && this.hasConfirmedDisclaimer;
				}
			},
			methods: {
				fireGTMremoveFromCart: function (product) {
					//universal tag datalayer push
					var data = {
						event: 'removeFromCart',
						ecommerce: {
							currencyCode: 'USD',
							add: {
								products: [
									{
										name: product.title,
										id: product.id,
										price: product.price.toString(),
										category: product.category,
										variant: product.size,
										quantity: parseInt(product.quantity),
									},
								],
							},
						},
					};
					// console.log('GTM remove from cart', data);
					dataLayer.push(data);
					//universal tag datalayer push
					var g4data = {
						event: 'remove_from_cart',
						ecommerce: {
							currencyCode: 'USD',
							add: {
								products: [
									{
										name: product.title,
										id: product.id,
										price: product.price.toString(),
										category: product.category,
										variant: product.size,
										quantity: parseInt(product.quantity),
									},
								],
							},
						},
					};
					dataLayer.push(g4data);
				},
				fireGTMcheckoutAction: function (step) {
					var productArray = [];
					for (
						var i = 0;
						i < this.$store.state.slideoutCart.length;
						i++
					) {
						var product = this.$store.state.slideoutCart[i];
						productArray.push({
							name: product.title,
							id: product.id,
							price: product.price.toString(),
							category: product.category,
							variant: product.size,
							quantity: parseInt(product.quantity),
						});
					}
					//universal tag datalayer push
					var data = {
						event: 'productCheckout',
						ecommerce: {
							detail: {
								actionField: { step: step },
								products: productArray,
							},
						},
					};
					// console.log('GTM checkout step 2', data);
					dataLayer.push(data);

					//ga4 tag datalayer push
					var g4data = {
						event: 'begin_checkout',
						ecommerce: {
							detail: {
								actionField: { step: step },
								products: productArray,
							},
						},
					};
					// console.log('GTM checkout step 2', data);
					dataLayer.push(g4data);
				},
				fireGTMaddToCart: function (product) {
					//universal tag datalayer push
					var data = {
						event: 'addToCart',
						ecommerce: {
							currencyCode: 'USD',
							add: {
								products: [
									{
										name: product.name,
										id: product.id,
										price: product.price.toString(),
										category: product.category,
										variant: product.size,
										quantity: parseInt(product.quantity),
									},
								],
							},
						},
					};
					// console.log('GTM add to cart', data);
					dataLayer.push(data);

					//universal tag datalayer push
					var g4data = {
						event: 'add_to_cart',
						ecommerce: {
							currencyCode: 'USD',
							add: {
								products: [
									{
										name: product.name,
										id: product.id,
										price: product.price.toString(),
										category: product.category,
										variant: product.size,
										quantity: parseInt(product.quantity),
									},
								],
							},
						},
					};
					dataLayer.push(g4data);
				},
				handleSelectedPromoCode: function (promoCode) {
					this.promoCode = promoCode;
				},
				handleGiftCards: function (giftCards) {
					this.giftCards = giftCards;
				},
				zipcodeChanged: function (event) {
					if (this.zipCode === "") {
						this.deliveryAvailable = false;
						this.zipCodeConfirmed = false;
						return;
					}
					this.checkZipCode();
				},

				calculateUpsell: function () {
					let upsell_fertilizer_total = 0;
					let upsell_conditioner_total = 0;

					for (var item in this.cart) {
						upsell_fertilizer_total += this.cart[item].start_fert_qty * this.cart[item].quantity;
						upsell_conditioner_total += this.cart[item].soil_cond_qty * this.cart[item].quantity;
					}

					this.fertilizerAmount = upsell_fertilizer_total.toFixed(1);
					this.conditionerAmount = upsell_conditioner_total.toFixed(1);
				},

				// ****OLD FUNCTIONS****
				// enableInStore: function (event) {
				// 	this.disclaimerText = PickupText;
				// 	this.hasConfirmedDisclaimer = false;
				// },
				// enableDelivery: function (event) {
				// 	this.disclaimerText = DeliveryText;
				// 	this.hasConfirmedDisclaimer = false;
				// },
				// enableInstallation: function (event) {
				// 	this.disclaimerText = InstallationText;
				// 	this.hasConfirmedDisclaimer = false;
				// },
				// removeShoppingListItem: function (index) {
				// 	// console.log('Will remove shopping list item with id', this.shoppingList[index].id);
				// 	this.updating = true;
				// 	this.confirmShoppingListRemove = false;
				// 	var self = this,
				// 		id = this.$store.state.wishlistItems[index].id;
				// 	$.ajax({
				// 		url: '/shoppingList/remove',
				// 		method: 'POST',
				// 		data: {
				// 			id: id,
				// 		},
				// 		success: function (data) {
				// 			// console.log(data);
				// 			//this.$store.state.wishlistItems = data.shoppingList;
				// 			self.$store.dispatch(
				// 				'wishlistReplace',
				// 				data.shoppingList
				// 			);
				// 		},
				// 		error: function (error) {
				// 			// console.log(error);
				// 		},
				// 	}).then(function () {
				// 		self.updating = false;
				// 	});
				// },
				// emptyShoppingList: function () {

				//  },
				// emailShoppingList: function () {
				// 	// console.log('Will email shopping list');
				// 	this.updating = true;
				// 	var self = this;
				// 	$.ajax({
				// 		method: 'POST',
				// 		url: '/shoppingList/email',
				// 		success: function (data) {
				// 			// console.log('Success:', data);
				// 		},
				// 		error: function (error) {
				// 			// console.log('Error:', error);
				// 		},
				// 	}).then(function () {
				// 		self.updating = false;
				// 		self.shoppingListState = 'notify';
				// 		setTimeout(function () {
				// 			self.shoppingListState = 'action';
				// 			self.confirmEmailShoppingList = false;
				// 		}, 2000);
				// 	});
				// },

				checkZipCode: function () {
					var self = this;
					if (this.zipCode) {
						return $.ajax({
							url: '/cart/check-zipcode',
							method: 'POST',
							data: {
								zipcode: this.zipCode,
							},
							success: function (data) {
								console.log(data);
								self.zipCodeConfirmed = true;
								if (data.available === false) {
									self.shippingRate = 0;
									self.shippingTaxRate = null;
									self.deliveryAvailable = false;
									//reset cart items to instore pickup
									self.updateAllItemsFufillmentType('store-pickup');
									// self.enableInStore();

								} else {
									self.deliveryAvailable = true;
									self.shippingRate = data.DeliveryFee;
									self.shippingTaxRate = data.TaxRate;
								}
							},
							error: function (error) {
								self.zipCodeConfirmed = false;
								self.deliveryAvailable = false;
								console.log(error);
							},
						}).then(function () {
							console.log('finished zipcode check');
						});
					}
				},
				decrementUpsellQuantity: function (upsellIndex, index) {
					if (this.upsellItems[upsellIndex][index].quantity == 0)
						return;
					this.showAddedQuantity = false;
					this.upsellItems[upsellIndex][index].quantity--;
				},
				incrementUpsellQuantity: function (upsellIndex, index) {
					if (
						this.upsellItems[upsellIndex][index].quantity ==
						this.upsellItems[upsellIndex][index].inventory
					)
						return;
					this.showAddedQuantity = false;
					this.upsellItems[upsellIndex][index].quantity++;
				},
				addUpsellToCart: function () {
					var productCount = 0;
					var self = this;
					this.upsellItems.forEach((upsellItem) => {
						upsellItem.forEach((product) => {
							if (product.quantity > 0) {
								console.log('add product to cart', product);
								self.fireGTMaddToCart(product);
								setTimeout(function () {
									window.app.cartInstance.addItem(
										product.id,
										product.plant_id,
										product.quantity
									);
								}, productCount * 300);
								productCount++;
							}
						});
					});

					setTimeout(function () {
						// @After a bit of time we reset the quantities
						self.resetQuantities();
					}, 800);
				},
				resetQuantities: function () {
					for (var i = 0, len = this.selectedProduct.length; i < len; i++) {
						this.selectedProduct[i].quantity = 0;
					}
					for (var item in this.products) {
						this.products[item].quantity = 0;
					}
				},
				activeUpsellProductTotal: function (index) {
					return (
						this.selectedProduct[index].price *
						this.selectedProduct[index].quantity
					);
				},
				hideCart: function () {
					return window.app.cart.hideCart();
				},
				decrementQuantity: function (id) {
					let product = this.$store.state.slideoutCart.find(item => item.id === id);
					if (!product) return;
					if (product.quantity == 1) return;
					let quantity = parseInt(product.quantity) - 1;
					this.updateItemQuanity(
						id,
						quantity
					);

				},
				incrementQuantity: function (id) {
					let product = this.$store.state.slideoutCart.find(item => item.id === id);
					if (!product) return;
					if (product.quantity === product.inventory && !product.allow_preorder) return;
					if (product.quantity === product.preorder_inventory && product.allow_preorder) return;

					let self = this;
					let quantity = parseInt(product.quantity) + 1;

					this.updateItemQuanity(id, quantity);
				},
				hasItem: function (id, quantity) {
					for (var item in this.$store.state.slideoutCart) {
						if (this.$store.state.slideoutCart[item].id == id) {
							console.log('Cart has item ' + id + '. Will update quantity.');
							return this.$store.state.slideoutCart[item];
						}
					}
					return false;
				},
				updateItemQuanity: function (id, quantity) {
					var self = this;
					self.updating = true;
					return $.ajax({
						url: '/cart/updateItemQuantity',
						method: 'POST',
						data: {
							id: id,
							quantity: quantity,
						},
						success: function (data) {
							self.$store.state.slideoutCart = data.cart;
						},
						error: function (error) {
							// console.log(error);
						},
					}).then(function () {

						self.updating = false;
					});
				},
				addItem: function (productId, plantId, quantity) {
					console.log('Will add to cart', 'Item ID' + productId, 'Plant ID' + plantId, 'Quantity ' + quantity);

					let self = this;
					let message = '';

					/// Check if item exists, increment quantity if possible
					let item = this.hasItem(productId);

					if (item !== false) {
						let addQuantity;
						if (!item.allow_preorder) {
							//checks quantity cart against current inventory
							if (item.quantity + parseInt(quantity) <= item.inventory) {
								addQuantity = parseInt(item.quantity) + parseInt(quantity);
							} else {
								addQuantity = parseInt(item.inventory);
							}

						} else {
							//checks quantity cart against available preorder inventory
							if (item.quantity + parseInt(quantity) <= item.preorder_inventory) {
								addQuantity = parseInt(item.quantity) + parseInt(quantity);
							} else {
								addQuantity = parseInt(item.preorder_inventory);
							}
						}
						this.updateItemQuanity(productId, addQuantity).then(
							function () {
								window.dispatchEvent(
									new window.CustomEvent('cartUpdated', {
										detail: {
											productId: productId,
											plantId: plantId,
											quantity: quantity,
										},
									})
								);
								// CHANGE: Handling this in the add to cart module
								// window.app.cart.showStatus(
								// 	'Cart item updated.'
								// );
							}
						);
					} else {
						/// Cart Addition logic to implement
						// console.log({
						//     id: productId,
						//     plant_id: plantId,
						//     quantity: quantity
						// });

						self.updating = true;
						$.ajax({
							method: 'POST',
							url: '/cart/add',
							data: {
								id: productId,
								plant_id: plantId,
								quantity: quantity,
							},
							success: function (data) {
								//console.log(data.cart);
								self.$store.dispatch(
									'slideoutCartReplace',
									data.cart
								);
								message = 'Item added to cart.';
								// el.innerHTML = 'Added to cart <i class="fa fa-check"></i>';
							},
							error: function (error) {
								console.error(error);
								//message = 'Error adding item to cart.';
								window.dispatchEvent(
									new window.CustomEvent('cartError', {
										detail: {
											productId: productId,
											plantId: plantId,
										},
									})
								);
							},
						}).then(function () {

							self.updating = false;
							// @DEVNOTE: convert to vue event
							window.dispatchEvent(
								new window.CustomEvent('cartUpdated', {
									detail: {
										productId: productId,
										plantId: plantId,
										quantity: quantity,
									},
								})
							);
							// CHANGE: Handling this in the add to cart module
							//window.app.cart.showStatus(message);
						});
					}
				},
				displayConfirmRemove() {
					this.confirmRemove = !this.confirmRemove;
				},
				removeItem: function (id) {
					this.updating = true;
					this.confirmRemove = false;
					let product = this.$store.state.slideoutCart.find(item => item.id === id);
					let self = this;
					self.fireGTMremoveFromCart(
						product
					);

					$.ajax({
						url: '/cart/remove',
						method: 'POST',
						data: {
							id: id,
						},
						success: function (data) {
							// console.log(data);
							self.$store.dispatch(
								'slideoutCartReplace',
								data.cart
							);
						},
						error: function (error) {
							// console.log(error);
						},
					}).then(function () {
						self.updating = false;
					});
				},
				bindPayment: function () {
					/**
					 * Braintree Integration Details
					 *
					 * @see https://developers.braintreepayments.com/guides/drop-in/setup-and-integration/javascript/v3
					 */
					let client_token = document.head.querySelector(
						'meta[name="bt_client_token"]'
					).content;
					let _this = this;
					self.braintree.dropin.create({
						authorization: client_token,
						selector: '#bt-dropin',
						paypal: {
							flow: 'vault', // Clarify difference between 'vault' and 'checkout' workflows
						},
						venmo: {
							allowNewBrowserTab: false, // review this functionality...
						},
					}, function (createErr, instance) {
						if (createErr) {
							console.log('Create Error', createErr);
							return;
						}
						instance.on('noPaymentMethodRequestable', function () {
							_this.paymentInfoEntered = false;
						});
						instance.on('paymentMethodRequestable', function () {
							_this.paymentInfoEntered = true;
						});

						var paymentForm = $('#payment-form'),
							orderButton = $('#order-submit'),
							brainTree = $('.bt-dropin-wrapper');

						orderButton.on('click', function (event) {
							_this.submitting = true;
							event.preventDefault();
							// if order has value needed to be charged
							if (_this.cartTotal > 0) {
								instance.requestPaymentMethod(function (
									err,
									payload
								) {
									if (err) {
										console.log('Request Payment Method Error', err);
										_this.submitting = false;
										return;
									}
									document.querySelector('#nonce').value =
										payload.nonce;

									brainTree.hide();
									orderButton.attr('disabled', 'disabled');

									_this.fireGTMcheckoutAction(2);

									paymentForm.submit();
								});
							} else {
								// order has been paid in full with gift cards
								paymentForm.submit();
							}
						});
					});
				},
				// Shopping List
				hasShoppingListItem: function (productId) {
					for (var item in this.$store.state.wishlistItems) {
						if (
							this.$store.state.wishlistItems[item].id ==
							productId
						) {
							// console.log('Shopping List has item ' + productId);
							return true;
						}
					}
					return false;
				},
				addItemToShoppingList: function (productId) {
					// console.log('Will add product to shopping list. ID:', productId);
					var self = this,
						message = '';
					if (this.hasShoppingListItem(productId)) {
						window.app.cart.showStatus(
							'<div class="shopping-list-item-added">Item already in Shopping List</div>',
							true
						);
						window.dispatchEvent(
							self.events.shoppingListUpdateEvent
						);
					} else {
						$.ajax({
							method: 'POST',
							url: '/shoppingList/add',
							data: {
								id: productId,
							},
							success: function (data) {
								// console.log(data);
								//this.$store.state.wishlistItems = data.shoppingList;
								self.$store.dispatch(
									'wishlistReplace',
									data.shoppingList
								);
								message =
									'<div class="shopping-list-item-added">Item added to Wish List</div>';
							},
							error: function (error) {
								// console.log(error);
								message =
									'<div class="shopping-list-item-added">Error adding item to Wish List</div>';
							},
						}).then(function () {
							window.dispatchEvent(
								self.events.shoppingListUpdateEvent
							);
							window.app.cart.showStatus(message, true);
							self.updating = false;
						});
					}
				},

				//REFACTORED METHODS

				scrollIntoViewWithOffset: function(selector, offset) {
					window.scrollTo({
						behavior: 'smooth',
						top: selector.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset
					  })
				},
				updateAllItemsFufillmentType: function (fulfillment_type) {
					var self = this;
					self.updating = true;
					return $.ajax({
						url: '/cart/updateAllItemsFulfillmentType',
						method: 'POST',
						data: {
							fulfillment_type: fulfillment_type,
						},
						success: function (data) {
							self.$store.state.slideoutCart = data.cart;
						},
						error: function (error) {
							// console.log(error);
						},
					}).then(function () {

						self.updating = false;
					});
				},

				updateItemFulfillmentType: function (id, fulfillment_type) {
					var self = this;
					self.updating = true;
					return $.ajax({
						url: '/cart/updateItemFulfillmentType',
						method: 'POST',
						data: {
							id: id,
							fulfillment_type: fulfillment_type,
						},
						success: function (data) {
							self.$store.state.slideoutCart = data.cart;
							//scroll to section
							setTimeout(() => {
								self.scrollIntoViewWithOffset(self.$refs[fulfillment_type], 200);
							}, 250);
							
						},
						error: function (error) {
							// console.log(error);
						},
					}).then(function () {

						self.updating = false;
					});
				},

				setSelectedFulfillmentOption: function (newFulfillmentOption) {
					console.log('new', newFulfillmentOption);
					this.selectedFulfillmentOption = newFulfillmentOption;

					if (newFulfillmentOption === null) {
						this.fulfillmentInStoreSelected = false;
						this.fulfillmentDeliverySelected = false;
						this.fulfillmentDeliveryInstallationSelected = false;
						this.this.selectedFulfillmentOption = null;
						return;
					}

					let fulfillment_type = this.selectedFulfillmentOption.replace('fulfillment-option-', '');
					this.updateAllItemsFufillmentType(fulfillment_type);

					switch (this.selectedFulfillmentOption) {
						case 'fulfillment-option-store-pickup':
							this.fulfillmentInStoreSelected = true;
							this.fulfillmentDeliverySelected = false;
							this.fulfillmentDeliveryInstallationSelected = false;
							break;
						case 'fulfillment-option-delivery':
							this.fulfillmentInStoreSelected = false;
							this.fulfillmentDeliverySelected = true;
							this.fulfillmentDeliveryInstallationSelected = false;
							break;
						case 'fulfillment-option-delivery-installation':
							this.fulfillmentInStoreSelected = false;
							this.fulfillmentDeliverySelected = false;
							this.fulfillmentDeliveryInstallationSelected = true;
							break;
						default:
							this.fulfillmentInStoreSelected = false;
							this.fulfillmentDeliverySelected = false;
							this.fulfillmentDeliveryInstallationSelected = false;
							break;
					}
				},

				determineIfSelectedFulfillmentOption: function (FulfillmentOption) {
					if (this.selectedFulfillmentOption === FulfillmentOption) {
						return true;
					} else {
						return false;
					}
				},

				itemDeliveryOptionChanged(id, fulfillment_type) {
					let product = this.$store.state.slideoutCart.find((prod) => prod.id === id);
					if (product.fulfillment_type === fulfillment_type) return;
					this.updateItemFulfillmentType(id, fulfillment_type);
				},

				updateSelectedFulfillmentOption() {
					if (this.cartLength <= 0) return;

					if (this.itemsCountStorePickup === this.cartLength) {
						this.selectedFulfillmentOption = 'fulfillment-option-store-pickup';
					} else if (this.itemsCountDelivery === this.cartLength) {
						this.selectedFulfillmentOption = 'fulfillment-option-delivery';
					} else if (this.itemsCountInstallation === this.cartLength) {
						this.selectedFulfillmentOption = 'fulfillment-option-delivery-installation';
					} else {
						this.selectedFulfillmentOption = '';
					}
				}

			},
			watch: {
				cartTotalIsAboveMinimumDeliveryAmount: function(newValue, oldValue) {
					if (!newValue) {
						this.updateAllItemsFufillmentType('store-pickup');
					}
				}
			},
			created: function () {
				//console.log('Vue created', this);
				var self = this;
				this.checkZipCode();
				if (config.hasOwnProperty('authorized')) {
					if (!config.authorized) {
						// console.log('Unauthorized. Cart will not initialize');
						self.ready = true;
						return;
					} else {
						// console.log('Authorized. Cart will initialize');
					}
				}
				$.ajax({
					method: 'GET',
					url: '/cart',
					success: function (data) {
						self.$store.dispatch('slideoutCartReplace', data.cart);
						self.taxRate = data.taxRate;
						self.ready = true;
						if (config && config.hasOwnProperty('bindPayment') && config.bindPayment) {
							self.bindPayment();
						}
						// self.loading = false;
					},
					error: function (err) {
						// console.log(error);
					},
				}).then(function () {
					// console.log('Cart retrieved, hide loading indicator.');
					self.loading = false;
					//self.broadcastCartCount();
				});
				$.ajax({
					method: 'GET',
					url: '/shoppingList',
					success: function (data) {
						//self.$store.state.wishlistItems = data.shoppingList;
						self.$store.dispatch(
							'wishlistReplace',
							data.shoppingList
						);
						self.shoppingListAvailable = true;
						// console.log(data);
					},
					error: function (err) {
						// console.log(error);
					},
				}).then(function () {
					//self.broadcastShoppingListCount();
				});
			},
			mounted: function () {
				if (this.upsellItems) {
					for (
						var i = 0, len = this.upsellItems.length;
						i < len;
						i++
					) {
						this.selectedProduct[i] = this.upsellItems[i][0];
					}
				}
				this.checkZipCode();
				this.calculateUpsell();
			},
			updated: function () {
				this.updateSelectedFulfillmentOption();
			}
		});
	}
}

window.app.add('cart', new cartHelper());
