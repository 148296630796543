<template>
  <div class="cart-item" v-if="item">
    <div class="cart-item_wrapper">
      <div class="cart-item_img_wrapper">
        <div class="cart-item_img" :style="{ backgroundImage: 'url(' + item.image_url + ')' }"></div>
        <div class="cart-item_status plant-status ">
        <div :class="'badge-inventory ' + itemStatusColor">
          <span :class="itemStatusColor">{{ itemStatusText }}</span>
        </div>
      </div>
      </div>
      <div class="cart-item_details">
        <div class="cart-item_title">
          <a :href="item.url">{{ item.title }}</a>
        </div>
        <div class="cart-totals">
          <div class="cart-item_size">{{ item.size }} </div>
          <div class="cart-item_inventory">({{ item.allow_preorder ? item.preorder_inventory : item.inventory }} left in stock )</div>
        </div>
        <div class="cart-item_price">
          {{ "$" + parseFloat(item.price).toFixed(2) }}
        </div>
        <div class="cart-item_subtotal">
          <div class="cart-item_quantity">
            <button
              class="remove trash"
              aria-label="Remove Item"
              v-if="item.quantity == 1"
              v-on:click="displayConfirmRemove()"
            >
              <i class="fa fa-trash"></i>
            </button>
            <button
              aria-label="Decrease Quantity"
              class="remove"
              v-if="item.quantity > 1"
              v-on:click="decrementQuantity(item.id)"
              v-bind:class="[item.quantity == 1 ? 'disabled' : '']"
            >
              -
            </button>
            <span class="current">{{ item.quantity }}</span>
            <button
              aria-label="Increase Quantity"
              class="add"
              v-on:click="incrementQuantity(item.id)"
              v-bind:class="[disableIncrementButton ? 'disabled' : '']"
            >
              +
            </button>
          </div>
          <div class="cart-item_price-total">
            ${{ +(parseFloat(item.price) * item.quantity).toFixed(2) }}
          </div>
        </div>
      </div>
      <div class="cart-item_status plant-status ">
        <div :class="'badge-inventory ' + itemStatusColor">
          <span :class="itemStatusColor">{{ itemStatusText }}</span>
        </div>
      </div>
    </div>
    <button
      class="remove-item"
      aria-label="Remove Item"
      v-on:click="displayConfirmRemove()"
    ></button>
    <div
      class="remove-confirm"
      v-bind:class="[confirmRemove ? 'in' : '']"
    >
      <p>Would you like to remove this item from your cart?</p>
      <div>
        <button
          class="btn btn-primary confirm"
          v-on:click="removeItem(item.id)"
        >
          Remove
        </button>
        <button
          class="btn btn-darker cancel"
          v-on:click="displayConfirmRemove()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
</style>
<script>
export default {
  props: {
    item: {},
  },
  data() {
    return {
      confirmRemove: false,
    };
  },
  computed: {
    itemStatusText() {
      return this.item.allow_preorder ? "Pre-order" : "In Stock";
    },

    itemStatusColor() {
      return this.item.allow_preorder
        ? "badge-allow-preorder"
        : "badge-in-stock";
    },
    disableIncrementButton() {
      if (this.item.allow_preorder) {
        return this.item.preorder_inventory == this.item.quantity ? true : false;
      } else {
        return this.item.inventory == this.item.quantity ? true : false;
      }
    }
  },
  methods: {
    displayConfirmRemove() {
      this.confirmRemove = !this.confirmRemove;
    },
    decrementQuantity(id) {
      this.$emit("decrement-quantity", id);
    },
    incrementQuantity(id) {
      this.$emit("increment-quantity", id);
    },
    removeItem(id) {
      this.$emit("remove-item", id);
    },
  },
};
</script>