<template>
    <div class="shopping-list-footer">
        <div class="shopping-list-email">
            <button id="email-shopping-list" class="btn btn-primary btn-block" v-on:click="confirm_email_wish_list_val = true" v-bind:class="[wish_list_count > 0 ? '' : 'disabled']">Email Wish List</button>
        </div>
        <div class="shopping-list-links">
            <a href="#" id="clear-shopping-list" v-on:click.stop.prevent="confirm_empty_wish_list = wish_list_count > 0 ? true : false">Empty Wish List</a>
        </div>
        <div class="shopping-list-confirm-empty" v-if="confirm_empty_wish_list == true">
            <p>Are you sure you'd like to empty your wish list?</p>
            <div>
                <button class="btn btn-primary" v-on:click="emptyWishList()">Yes, Empty</button>
                <button class="btn btn-success" v-on:click="confirm_empty_wish_list = false">Cancel</button>
            </div>
        </div>
        <div class="shopping-list-confirm-empty" v-if="getEmailWishListActive">
            <p>Click send to have your wish list emailed to you.</p>
            <div v-if="wish_list_state == 'action'">
                <button class="btn btn-primary" v-on:click="emailWishList()">SEND</button>
                <button class="btn btn-success" v-on:click="confirm_email_wish_list_val = false">Cancel</button>
            </div>
            <div v-if="wish_list_state == 'notify'">
                <p class="text-uppercase lead text-success"><strong>EMAIL SENT!</strong></p>
            </div>    
        </div>
    </div>
</template>
<style lang="scss" scoped></style>
<script>
import { STORE_ACTIONS } from '../../store';

export default {
    props: {
        wish_list: [],
        wish_list_count: 0,
        wish_list_state: '',
        confirm_email_wish_list: false,
    },
    data() {
        return {
            confirm_email_wish_list_val: false,
            confirm_empty_wish_list: false,
        }
    },
    computed: {
        getEmailWishListActive() {
            return this.confirm_email_wish_list || this.confirm_email_wish_list_val;
        }
    },
    methods: {
        emptyWishList() {
            this.$emit('empty-wish-list');
            this.confirm_empty_wish_list = false;
        },
        emailWishList() {
            this.$emit('email-wish-list');
            this.confirm_email_wish_list_val = false;
        }
    },
}
</script>